import { ReactNode } from "react";
import "./Table.scss";
import Tbody from "./Tbody/Tbody";
import Thead from "./Thead/Thead";
import TPaginate from "./TPaginate/TPaginate";

interface TableProps {
  tableClass?: string;
  head: Array<ReactNode>;
  body: Array<object>;
  showCheckbox?: boolean;
  hasMenu?: boolean;
  hidePagination?: boolean;
  isDeletable?: boolean;
  handleDelete?: Function;
  handleSelect?: Function;
  selectKey?: string;
  showNA?: boolean;
  menuList?: Array<{ text: string; handleAction?: Function; action?: any }>;
  current?: number;
  next?: any;
  previous?: any;
  handlePagination?: Function;
  length?: number;
  page?: number;
  noPerPage?: number
}

const Table = (props: TableProps) => {
  const {
    tableClass,
    head,
    body,
    showCheckbox,
    hasMenu,
    hidePagination,
    isDeletable,
    handleDelete,
    selectKey,
    handleSelect,
    showNA,
    menuList,
    next,
    current,
    previous,
    handlePagination,
    length,
    page,
  } = props;

  return (
    <div className="custom-table">
      <table className={`${tableClass}`}>
        <Thead head={head} showCheckbox={showCheckbox} />
        <Tbody
          body={body}
          showCheckbox={showCheckbox}
          hasMenu={hasMenu}
          isDeletable={isDeletable}
          handleDelete={handleDelete}
          selectKey={selectKey}
          handleSelect={handleSelect}
          showNA={showNA}
          menuList={menuList}
        />
      </table>

      {!hidePagination && (
        <TPaginate {...{ next, current, handlePagination, previous, length, page }} />
      )}
    </div>
  );
};

export default Table;
