import { Search } from "components/ui";
import "./HelpSupport.scss";
import { ReactSVG } from "react-svg";
import assets from "assets";

import { useEffect, useMemo, useState } from "react";
import API from "utils/api/API";

import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import { getErrorMessage } from "utils/helper";

import debounce from "lodash.debounce";
import Loader from "components/ui/Loader";
import ErrorView from "components/ui/ErrorView";
import EmptyView from "components/ui/EmptyView";
import HelpSupportItem from "./HelpSupportItem/HelpSupportItem";

const { LOADING, NULLMODE, DATAMODE, ERROR, IDLE } = dataQueryStatuses;

const HelpSupport = () => {
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(IDLE);
  const [error, setError] = useState("");
  const [faqs, updateFaqs] = useState<any>([]);

  const searchFAQs = () => {
    setStatus(LOADING);
    setError("");
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.faq,
      params: {
        q: search,
      },
    })
      .then((resp) => {
        updateFaqs(resp.data.data);
        resp.data.data?.length === 0
          ? setStatus(NULLMODE)
          : setStatus(DATAMODE);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);

        setStatus(ERROR);
        setError(errMsg);
      });
  };

  const handleSearch = (data: any) => {
    setSearch(data);
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 300);
  }, []);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return <Loader loaderType="PROGRESS" />;
      case DATAMODE:
        return (
          <div style={{ height: '400px', overflow: 'scroll' }}>
            {faqs?.map(({ question, answer }: any, key: any) => (
              <HelpSupportItem key={key} question={question} answer={answer} />
            ))}
          </div>
        );
      case ERROR:
        return <ErrorView message={error} />;
      case NULLMODE:
        return <EmptyView />;
      default:
        return "";
    }
  };

  useEffect(() => {
    searchFAQs();
    return () => {
      debouncedResults.cancel();
    };
    // eslint-disable-next-line
  }, [search]);

  return (
    <div className="help-support">
      {/* <h3>Search for anything</h3> */}
      <div className="help-support__container">
        <div>
          <p>Hello there, How can we help you?</p>
          <Search
            placeholder="Search"
            onChange={handleSearch}
            loading={loading}
          />
          <div className="help-support__questions">{renderBasedOnStatus()}</div>
        </div>
        <div className="help-support__image">
          <ReactSVG src={assets.images.helpSupport} />
        </div>
      </div>

      <div className="help-support__socials">
        <a
          href="https://www.linkedin.com/company/100060383/admin/feed/posts/?feedType=following"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ReactSVG src={assets.icons.linkedin} />
        </a>

        <a
          href="https://twitter.com/sprintstartech?s=21"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ReactSVG src={assets.icons.twitter} />
        </a>

        <a
          href="https://www.facebook.com/profile.php?id=61551783842671&mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
        >
          <ReactSVG src={assets.icons.facebook} />
        </a>

        <a
          href="https://instagram.com/sprintstartech?igshid=MzRlODBiNWFlZA=="
          target="_blank"
          rel="noopener noreferrer"
        >
          <ReactSVG src={assets.icons.instagram} />
        </a>
      </div>
    </div>
  );
};

export default HelpSupport;
