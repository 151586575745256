import React from 'react';
import { Box, Typography } from '@mui/material';

const TableEndDivider = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                flex: 1,
            }}
        >
            <Box
                sx={{
                    flex: 1,
                    borderTop: '1px solid',
                    borderColor: 'gray',
                }}
            />
        </Box>
    );
};

export default TableEndDivider;
