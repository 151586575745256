import StatsCard from "./StatsCard/StatsCard";
import "./StatsCardList.scss";

const colNums: any = {
  2: "two",
  3: "three",
  4: "four",
  5: "five",
};

const StatsCardsList = (props: { stats: Array<any>; colNum?: number }) => {
  const { stats, colNum = 4 } = props;
  return (
    <section className={`stats-cards-list ${colNums?.[colNum]}`}>
      {stats.map((stat, key) => (
        <StatsCard {...stat} key={key} />
      ))}
    </section>
  );
};

export default StatsCardsList;
