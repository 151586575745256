import { Table } from "components/ui";
import "./AdminViewKpiTable.scss";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import { useEffect, useState } from "react";
import ErrorView from "components/ui/ErrorView";
import Loader from "components/ui/Loader";
import EmptyView from "components/ui/EmptyView";
import { getErrorMessage } from "utils/helper";
import API from "utils/api/API";

// const tableData = [
//   {
//     task: "Onboarding",
//     approvedBy: "Team Lead",
//     projectStatus: <span className="high">Done</span>,
//     ratings: <span className="low">Low</span>,
//   },
//   {
//     task: "Onboarding",
//     approvedBy: "Team Lead",
//     projectStatus: <span className="high">Done</span>,
//     ratings: <span className="low">Low</span>,
//   },
//   {
//     task: "Onboarding",
//     approvedBy: "Team Lead",
//     projectStatus: <span className="high">Done</span>,
//     ratings: <span className="low">Low</span>,
//   },
//   {
//     task: "Onboarding",
//     approvedBy: "Team Lead",
//     projectStatus: <span className="high">Done</span>,
//     ratings: <span className="low">Low</span>,
//   },
// ];

const { LOADING, IDLE, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;

const AdminViewKpiTable = (props: any) => {
  const { selectedKpi } = props;
  const [result, setResult] = useState([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(IDLE);

  const getTeamMemberTasks = () => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.projectTasks,
      params: {
        assigned: selectedKpi?.id,
        state: "in-progress",
      },
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const { results } = resp.data?.data || {};
        setResult(results);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };

  const tableData = result
    ?.slice?.(0, 5)
    ?.map?.(({ task_name, priority, state, label }: any) => {
      return {
        task_name,
        label,
        state,
        priority: <span className={priority}>{priority}</span>,
      };
    });

  useEffect(() => {
    if (selectedKpi?.id) {
      getTeamMemberTasks();
    }
    // eslint-disable-next-line
  }, [selectedKpi]);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return <Loader />;
      case ERROR:
        return <ErrorView message={errorMessage} />;
      case NULLMODE:
        return <EmptyView message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            <Table
              body={tableData}
              head={["Task", "Label", "Project Status", "Priority"]}
              hidePagination
              showNA
            />
          </>
        );

      default:
        return "";
    }
  };

  return (
    <section className="admin-view-kpi-table">
      <div className="admin-view-kpi-table__header">
        {/* <span>Users</span> */}
        {/* <span>Teams</span> */}
        {/* <span>KPIs</span> */}
        <span>Tasks</span>
      </div>
      <div className="admin-view-kpi-table__table">{renderBasedOnStatus()}</div>
    </section>
  );
};

export default AdminViewKpiTable;
