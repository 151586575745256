import { MODAL_ACTIONS } from "components/Main/enums";
import CreatePOTask from "./CreatePOTask/CreatePOTask";
import CreateBlocker from "./CreateBlocker/CreateBlocker";
import AllBlockers from "./AllBlockers/AllBlockers";
import ViewBlocker from "./ViewBlocker/ViewBlocker";
import DeletePOTask from "./DeletePOTask/DeletePOTask";
import ViewPOTask from "./ViewPOTask/ViewPOTask";
import ConnectGit from "./Connectgit/ConnectGit";

const {
  EDIT_ACTION,
  CREATE_ACTION,
  DELETE_ACTION,
  VIEW_ACTION,
  CREATE_BLOCKER,
  ALL_BLOCKERS,
  VIEW_BLOCKER,
  EDIT_BLOCKER_ACTION,
  CONNECT_GIT,
} = MODAL_ACTIONS;

const POTasksModals = (props: {
  modalAction: any;
  onClose: Function;
  handleSuccess: Function;
  selectedProject: any;
  selectedTask?: any;
  selectedBlocker?: any
  selectBlocker?: any
  isOpen: boolean;
  dataList: any;
  setModalAction: any;
  toggleModal: any;
}) => {
  const {
    modalAction,
    onClose,
    handleSuccess,
    selectedProject,
    selectedTask,
    selectedBlocker,
    selectBlocker,
    isOpen,
    dataList,
    setModalAction,
    toggleModal,
  } = props;

  const renderBasedOnAction = () => {
    switch (modalAction) {
      case EDIT_ACTION:
      case CREATE_ACTION:
        return (
          <CreatePOTask
            onClose={onClose}
            handleSuccess={handleSuccess}
            selectedProject={selectedProject}
            isEdit={modalAction === EDIT_ACTION}
            selectedTask={selectedTask}
            isOpen={isOpen}
          />
        );
      case CREATE_BLOCKER:
      case EDIT_BLOCKER_ACTION:
        return (
          <CreateBlocker
            onClose={onClose}
            handleSuccess={handleSuccess}
            selectedBlocker={selectedBlocker}
            isEdit={modalAction === EDIT_BLOCKER_ACTION}
            selectedTask={selectedTask}
            isOpen={isOpen}
            dataList={dataList}
          />
        );

      case ALL_BLOCKERS:
        return (
          <AllBlockers
            onClose={onClose}
            selectedTask={selectedTask}
            isOpen={isOpen}
            setModalAction={setModalAction}
            toggleModal={toggleModal}
            selectBlocker={selectBlocker}
          />
        );
      case VIEW_BLOCKER:
        return (
          <ViewBlocker
            onClose={onClose}
            selectedTask={selectedTask}
            isOpen={isOpen}
          />
        );
        case CONNECT_GIT:
          return (
            <ConnectGit
              onClose={onClose}
              isOpen={isOpen}
            />
          );
      case DELETE_ACTION:
        return (
          <DeletePOTask
            onClose={onClose}
            isOpen={isOpen}
            handleSuccess={handleSuccess}
            selectedProject={selectedProject}
            selectedTask={selectedTask}
          />
        );
      case VIEW_ACTION:
        return (
          <ViewPOTask
            onClose={onClose}
            handleSuccess={handleSuccess}
            selectedProject={selectedProject}
            isEdit={modalAction === EDIT_ACTION}
            selectedTask={selectedTask}
            isOpen={isOpen}
          />
        );
      default:
        return (
          <CreatePOTask
            onClose={onClose}
            handleSuccess={handleSuccess}
            selectedProject={selectedProject}
            isEdit={modalAction === EDIT_ACTION}
            selectedTask={selectedTask?.id}
            isOpen={isOpen}
          />
        );
    }
  };

  return <>{renderBasedOnAction()}</>;
};

export default POTasksModals;
