import { Button } from "components/ui";
import Input from "components/ui/Input";
import { formatDate } from "utils/helper";
import "./TicketStyle.scss";

const UpdateIssue = (props: {
  register: Function;
  errors: any;
  selectedIssues: any;
  loading: any
}) => {
  const { register, errors, selectedIssues, loading } = props;

  const start_date = new Date(selectedIssues?.created_at);
  const get_next_day = new Date(start_date);
  get_next_day.setDate(start_date?.getDate() + 1);
  const minValue = get_next_day
    ? formatDate(get_next_day)
    : formatDate(new Date());

  return (
    <div className="create-pc-modal-form">
      <div className="create-pc-modal-form__inputs">
        <div className="p-y-1">
          <Input
            label="Title"
            placeholder="Enter Title"
            name="title"
            register={register}
            hasError={errors?.title}
            required
            isDisabled
          />
        </div>

        <div className="d-flex p-y-1 p-r-1">
          <Input
            type="date"
            label="Expected Date"
            name="expected_date"
            minValue={minValue}
            register={register}
            hasError={errors?.expected_date}
            required
          />
        </div>
      </div>
      <div className="admin-project_btn">
        <Button
          text="Submit"
          type="submit"
          isLoading={loading}
        />
      </div>
    </div>
  );
};

export default UpdateIssue;
