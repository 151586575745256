import assets from "assets";
import DropdownMenu from "components/ui/Dropdown";
import { ReactSVG } from "react-svg";

interface TableRowProps {
  item?: any;
  rowClass?: string;
  dataClass?: string;
  showCheckbox?: boolean;
  hasMenu?: boolean;
  isDeletable?: boolean;
  handleDelete?: Function;
  handleSelect?: Function;
  selectKey?: string;
  isSelected?: boolean;
  showNA?: boolean;
  menuList?: Array<{ text: string; handleAction?: Function; action?: any }>;
}

const TableRow = (props: TableRowProps) => {
  const {
    item,
    showCheckbox,
    hasMenu,
    isDeletable,
    handleDelete,
    selectKey,
    handleSelect,
    isSelected,
    showNA,
    menuList,
    ...rest
  } = props;

  const getKeyId = item?.id;

  const arrayItems = Object.values(item)?.filter((x) => x !== getKeyId);

  const firstElement = arrayItems[0];

  const lastElement = arrayItems[arrayItems.length - 1];

  const notAvailable = showNA ? "N/A" : "";
  return (
    <tr>
      <td className={`${showCheckbox ? "actions" : ""}`}>
        {showCheckbox && (
          <input
            type="checkbox"
            onClick={() => handleSelect?.(selectKey)}
            checked={isSelected}
          />
        )}
        <>{firstElement || notAvailable}</>
      </td>

      {arrayItems?.slice(1, arrayItems.length - 1).map((subitem: any, key) => (
        <td key={key}>{subitem || notAvailable}</td>
      ))}

      <td className="last-element">
        <>{lastElement || notAvailable}</>
      </td>

      {(hasMenu || isDeletable) && (
        <td className="actions">
          {hasMenu && (
            <DropdownMenu
              items={menuList || []}
              id={item?.id}
              data={item}
              menuTitle={""}
              handleSelect={(d: any) => handleSelect?.({ ...d, ...item })}
              customButton={<ReactSVG src={assets.icons.menu} />}
            />
          )}

          {isDeletable && (
            <ReactSVG
              src={assets.icons.thrash}
              onClick={() =>
                handleDelete?.({
                  item,
                  showCheckbox,
                  hasMenu,
                  isDeletable,
                  handleDelete,
                  ...rest,
                  key: getKeyId,
                })
              }
            />
          )}
        </td>
      )}
    </tr>
  );
};

export default TableRow;
