import { ReactSVG } from "react-svg";
import "./Search.scss";
import assets from "assets";
import { useEffect, useMemo, useState } from "react";
import debounce from "lodash.debounce";

interface SearchProps {
  className?: string;
  placeholder?: string;
  isIconRight?: boolean;
  onChange?: Function;
  value?: string;
  id?: string;
  name?: string;
  loading?: boolean;
  handleSearch?: Function;
}

const Search = (props: SearchProps) => {
  const {
    isIconRight,
    placeholder,
    className,
    onChange,
    value,
    id,
    name,
    loading,
    handleSearch,
  } = props;

  const [search, setSearch] = useState("");

  const searchHandler = (data: any) => {
    setSearch(data);
  };

  const debouncedResults = useMemo(() => {
    return debounce(searchHandler, 300);
  }, []);

  useEffect(() => {
    handleSearch?.(search);
    return () => {
      debouncedResults.cancel();
    };
    // eslint-disable-next-line
  }, [search]);

  return (
    <>
      <div className={`search-input ${className}`}>
        {!isIconRight && (
          <ReactSVG src={assets.icons.search} className="icon" />
        )}

        <input
          type="search"
          placeholder={placeholder ? placeholder : "Search..."}
          className={isIconRight ? "input-shift" : ""}
          onChange={(e) => {
            onChange?.(e.target.value);
            searchHandler(e?.target?.value);
          }}
          // onChange={(e)=>onChange?.(e)}
          value={value}
          id={id}
          name={name}
        />
        {isIconRight && <ReactSVG src={assets.icons.search} className="icon" />}
        {loading && <ReactSVG src={assets.icons.cancel} className="icon" />}
      </div>
    </>
  );
};

export default Search;
