import assets from "assets";
import { ReactSVG } from "react-svg";

const TPaginate = (props: any) => {
  const { next, previous, current = 1, handlePagination } = props;

  return (
    <div className="custom-table__pagination">
      <ReactSVG
        className={`${previous ? "" : "non-active"}`}
        src={assets.icons.arrowLeft}
        onClick={() => previous && handlePagination?.(current - 1)}
      />

      {current > 1 && <span>{current - 1}</span>}
      <span className={"active"}>{current}</span>
      <span>{current + 1}</span>
      {current === 1 && <span>{current + 2}</span>}

      <ReactSVG
        src={assets.icons.arrowRight}
        className={`${next ? "" : "non-active"}`}
        onClick={() => next && handlePagination?.(current + 1)}
      />
    </div>
  );
};

export default TPaginate;
