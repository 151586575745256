import React from "react";
import { TableRow } from "@mui/material";

// core components
import AppTableCell from "./AppTableCell";

const AppTableRow = (props: any) => {
  const { columns, row, clickableRow, onRowClick } = props;
 
  return (
    <TableRow
      sx={{
        my: 3,
        boxShadow:
          "-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)",
        backgroundColor: "#ffffff",
        borderRadius: 1,
        padding: 10,
        cursor: clickableRow && clickableRow() ? "pointer" : "default",
      }}
      onClick={clickableRow && clickableRow() ? onRowClick : null}
    >
      {columns.map((column: any, index: number) => {
        return (
          <AppTableCell
            key={column?.title + row?.id + index}
            sx={{
              fontWeight: 500,
              color: "gray",
              borderBottom: "1px solid #e0e6eb",
              // ...(rowSpacing ? spacingBorderStyles : {}),
            }}
            align={column.align}
            width={column.width}
            padding={column.padding}
          >
            {column.render(row)}
          </AppTableCell>
        );
      })}
    </TableRow>
  );
};

export default AppTableRow;
