import React, { useEffect, useState } from "react";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import IntegrationSection from "./components/IntegrationSection";
import Loader from "components/ui/Loader";
import { ReactSVG } from "react-svg";
import ChevronDown from "../../../../assets/general/ChevronDown.svg";
import "./integration.scss";

const Integrations = () => {
  const [loading, setLoading] = useState(false);
  const [connectedIntegrations, setConnectedIntegration] = useState(true);
  const [connectIntegrations, setConnectIntegration] = useState(true);
  const [results, setResults] = useState([]);

  const getAllPlatforms = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.get_integrations_platforms,
    })
      .then((resp) => {
        setResults(resp?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    getAllPlatforms();
  }, []);

  const handleConnectedToggle = () => {
    setConnectedIntegration(!connectedIntegrations);
  };

  const handleConnectToggle = () => {
    setConnectIntegration(!connectIntegrations);
  };

  const configured_integrations = results?.filter(
    (result: any) => result?.is_configured
  );
  const not_configured_integrations = results?.filter(
    (result: any) => !result?.is_configured
  );
  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          {configured_integrations?.length > 0 && (
            <div className="integration_section">
              <div
                className="integration_section_header"
                onClick={handleConnectedToggle}
              >
                <h6>Connected Integrations</h6>
                <ReactSVG src={ChevronDown} />
              </div>
              {connectedIntegrations && (
                <IntegrationSection
                  results={configured_integrations}
                  handleSuccess={getAllPlatforms}
                  connected
                />
              )}
            </div>
          )}
          {not_configured_integrations?.length > 0 && (
            <div className="integration_section">
              <div
                className="integration_section_header"
                onClick={handleConnectToggle}
              >
                <h6>Available Integrations</h6>
                <ReactSVG src={ChevronDown} />
              </div>
              {connectIntegrations && (
                <IntegrationSection
                  results={not_configured_integrations}
                  handleSuccess={getAllPlatforms}
                />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Integrations;
