import { AppTable } from "components/ui";
import { MODAL_ACTIONS } from "components/Main/enums";
import { Box, Fade, Tooltip, Typography } from "@mui/material";
import EmptyView from "components/ui/EmptyView";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setTeamSelected } from "store/team/actions";

const { DELETE_ACTION, EDIT_ACTION } = MODAL_ACTIONS;

const AdminTeamsTable = (props: {
  data: any;
  setModalAction: Function;
  selectTeam: Function;
  toggleModal: Function;
  page: number;
  setPage: Function;
  count: any;
  per_page: number;
}) => {
  const {
    data,
    page,
    setPage,
    setModalAction,
    selectTeam,
    toggleModal,
    count,
    per_page,
  } = props;
  const options = { maximumFractionDigits: 2 };
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const columns = [
    {
      title: "Team",
      render: (row: any) => (
        <Typography
          sx={{
            fontSize: "12px",
            textTransform: "capitalize",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch<any>(setTeamSelected(row));
            navigate(`/admin/team-details`);
          }}
        >
          {row?.title} {""}
        </Typography>
      ),
    },
    {
      title: "Lead",
      key: "lead",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
            cursor: "pointer",
          }}
          onClick={() => {
            dispatch<any>(setTeamSelected(row));
            navigate(`/admin/team-details`);
          }}
        >
          {row?.lead_details?.name || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Company",
      key: "company",
      render: (row: any) => (
        <Typography
          sx={{ color: "#5E5E62", fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            dispatch<any>(setTeamSelected(row));
            navigate(`/admin/team-details`);
          }}
        >
          {row?.company_details?.name || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Members Count",
      key: "member_count",
      render: (row: any) => (
        <Typography
          sx={{ color: "#5E5E62", fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            dispatch<any>(setTeamSelected(row));
            navigate(`/admin/team-details`);
          }}
        >
          {row?.member_count || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Performance Score",
      key: "performance_score",
      render: (row: any) => (
        <Typography
          sx={{ color: "#5E5E62", fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            dispatch<any>(setTeamSelected(row));
            navigate(`/admin/team-details`);
          }}
        >
          {row?.performance_score || "N/A"}
        </Typography>
      ),
    },
    {
      title: "KPI Score",
      key: "kpi_scoree",
      render: (row: any) => (
        <Typography
          sx={{ color: "#5E5E62", fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            dispatch<any>(setTeamSelected(row));
            navigate(`/admin/team-details`);
          }}
        >
          {Intl.NumberFormat("en-US", options).format(row?.kpi_score) || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Average Wage",
      key: "average_wage",
      render: (row: any) => (
        <Typography
          sx={{ color: "#5E5E62", fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            dispatch<any>(setTeamSelected(row));
            navigate(`/admin/team-details`);
          }}
        >
          {Intl.NumberFormat("en-US", options).format(row?.average_wage) ||
            "N/A"}
        </Typography>
      ),
    },
    {
      title: "Action",
      render: (row: any) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Tooltip
            title="Update Team"
            placement="top-end"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <BorderColorIcon
              sx={{ width: 18, height: 18, marginRight: "1rem" }}
              onClick={() => {
                selectTeam(row);
                setModalAction(EDIT_ACTION);
                toggleModal(true);
              }}
            />
          </Tooltip>
          <Tooltip
            title="Delete Team"
            placement="top-start"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <DeleteIcon
              onClick={() => {
                selectTeam(row);
                setModalAction(DELETE_ACTION);
                toggleModal(true);
              }}
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <div className="admin-user-table">
      {data?.length > 0 ? (
        <AppTable
          columns={columns}
          data={data}
          sorter={(a: any, b: any) => b?.id - a?.id}
          dataLength={count}
          noPerPage={per_page}
          page={page}
          onPageChange={handlePageChange}
        />
      ) : (
        <EmptyView message="No Team Found" />
      )}
    </div>
  );
};
export default AdminTeamsTable;
