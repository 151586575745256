export const ONBAORDING_STAGES = {
  USER_ROLE: "USER_ROLE",
  TEAM_INFO: "TEAM_INFO",
  GIT_SETUP: "GIT_SETUP",
};

export const ONBAORDING_HEADER: any = {
  USER_ROLE: {
    title: "Let's get to know you",
    text: "Please fill in your details to help us get you  started.",
  },

  TEAM_INFO: {
    title: "Let's get to know about your team",
    text: "Please fill in your details to help us get you  started.",
  },

  GIT_SETUP: {
    title: "Let's get you set up",
    text: "Please fill in your details to help us get you  started.",
  },
};

export const GIT_SETUP_OPTIONS = {
  GITHUB: "GITHUB",
  GITLAB: "GITLAB",
};
