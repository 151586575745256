import { createContext, useContext } from "react";

export const AppContext = createContext({
  search: "",
  isModalOpen: false,
  closeModal: () => {},
  filterList: [],
  updateFilterList: (filters: any) => {},
  isFilterLoading: false,
  setIsFilterLoading: (filter: any) => {},
  closeFilter: () => {},
  isFilterOpen: false,
});

export const useAppContext = () => {
  const {
    search,
    isModalOpen,
    closeModal,
    filterList,
    updateFilterList,
    isFilterLoading,
    setIsFilterLoading,
    closeFilter,
    isFilterOpen,
  } = useContext(AppContext);

  return {
    search,
    isModalOpen,
    closeModal,
    filterList,
    updateFilterList,
    isFilterLoading,
    setIsFilterLoading,
    closeFilter,
    isFilterOpen,
  };
};
