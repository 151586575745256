import { Button } from "components/ui";

import assets from "assets";
import { getErrorMessage, getGitHubUrl } from "utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import toastMessage from "utils/toast";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import "../Settings.scss";

const GithubSettings = (props: {
  redirectUrl?: string;
}) => {
  const { redirectUrl } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const isRequestMade = useRef(false);

  const [loading, setLoading] = useState(false);

  const from = ((location.state as any)?.from.pathname as string) || "/profile";
  const { user } = useSelector((state: any) => state.auth);
  const {
    employee: { company, role },
  } = user || {};

  const { installation_id, setup_action }: any = queryString.parse(
    window.location.search
  );

  const completeGithubInstallation = () => {
    setLoading(true);
    isRequestMade.current = true;
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.setup_github,
      data: {
        installation_id,
        installation_type: "github",
        company: company?.id,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Successfully installed Github");
        navigate(redirectUrl ? redirectUrl : "/settings/github/");
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const updateGithubInstallation = () => {
    setLoading(true);
    isRequestMade.current = true;
    API({
      method: httpVerbs?.PATCH,
      url: apiUrls?.update_github(installation_id),
      data: {
        installation_id,
        installation_type: "github",
        company: company?.id,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Successfully updated");
        navigate(redirectUrl ? redirectUrl : "/settings/github/");
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const renderBasedOnRole = () => {
    switch (role) {
      case "admin":
        return (
          // <ErrorView
          //   message="Only a user with 'Engineer' role has this permission"
          //   noRetryAction
          // />

          <form className="settings__form">
            <a href={getGitHubUrl(from)} aria-disabled>
              <Button
                text="Install/update your organization Github account"
                icon={assets.icons.github}
                className="settings__github--button"
                isLoadingText="Please wait..."
                isLoading={loading}
              />
            </a>
          </form>
        );
      case "engineer":
        return (
          <form className="settings__form">
            <a href={getGitHubUrl(from)} aria-disabled>
              <Button
                text="Install/update your organization Github account"
                icon={assets.icons.github}
                className="settings__github--button"
                isLoadingText="Please wait..."
                isLoading={loading}
              />
            </a>
          </form>
        );

      default:
        return "";
    }
  };

  useEffect(() => {
    if (installation_id && !isRequestMade?.current) {
      setup_action === "install"
        ? completeGithubInstallation()
        : updateGithubInstallation();
    }
    // eslint-disable-next-line
  }, [installation_id]);

  return (
    <div className="settings">
     <h3>Update your information</h3>
      {renderBasedOnRole()}
    </div>
  );
};

export default GithubSettings;
