import Button from "../Button";
import Icon from "../Icon";
import "./EmptyView.scss";

interface Props {
  message?: string;
  iconClass?: string;
  messageClass?: string;
  hasActionBtn?: boolean;
  handleAction?: Function;
  actionBtnText?: string;
}

const EmptyView = (props: Props) => {
  const {
    iconClass,
    messageClass,
    message,
    handleAction,
    hasActionBtn,
    actionBtnText,
  } = props;
  return (
    <>
      <div className="empty-view">
        <Icon icon="empty" className={`empty-view__icon ${iconClass}`} />
        <p className={`${messageClass}`}>
          {message ? message : "No Results Found"}
        </p>
        {hasActionBtn && (
          <Button text={actionBtnText || "Go to"} onClick={handleAction} />
        )}
      </div>
    </>
  );
};

export default EmptyView;
