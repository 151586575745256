import StatsCardsList from "components/common/StatsCardsList/StatsCardsList";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStatsData } from "store/stats/actions";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";

const AdminTeamsCards = () => {
  const { total_team, total_member, total_salary, average_total_wages } =
    useSelector((state: any) => state.stats);
  const { user } = useSelector((state: any) => state.auth);

  const stats = [
    {
      title: "Total Employees",
      value: total_member || "-",
      // report: `NGN ${average_total_wage?.toFixed?.(2) || 0} (Total Avg, Wages)`,
    },
    {
      title: "Total Teams",
      value: total_team || "0",
      // report: `NGN ${average_total_wage?.toFixed?.(2) || 0} (Total Avg, Wages)`,
    },
    {
      title: "Total Average Wages",
      value: average_total_wages?.toLocaleString() || "0",
      // report: `${average_employee_per_team || 0} (Avg. Member per team)`,
      background: "#7DCFB6",
    },
    {
      title: "Total Salaries",
      value: total_salary ? total_salary?.toLocaleString() : "0",
      // report: `NGN${total_salary?.toFixed?.(2) || 0} (Total, Salary)`,
      background: "#7DCFB6",
    },
  ];

  const dispatch = useDispatch();

  const getAdminStats = () => {
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.adminStats,
    })
      .then((resp) => {
        const { data } = resp.data || {};
        dispatch<any>(updateStatsData(data));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (user?.employee?.role !== "employee" || user?.employee?.role !== 'team_lead') {
      getAdminStats();
    }
    // eslint-disable-next-line
  }, [user?.employee?.role]);

  return <StatsCardsList stats={stats} />;
};

export default AdminTeamsCards;
