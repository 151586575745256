import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";

import { useForm } from "react-hook-form";
import SideModal from "components/ui/Modal/SideModal/SideModal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toastMessage from "utils/toast";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { formatDatePickerDate, getErrorMessage } from "utils/helper";
import AddNewUserForm from "./AddNewUserForm/AddNewUserForm";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";

const AddNewUserModal = (props: { onClose: Function; isOpen: boolean }) => {
  const { onClose, isOpen } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    watch,
    reset,
  } = useForm();
  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const { company } = employee || {};

  const { id: companyId } = company || {};

  const [loading, setLoading] = useState(false);
  const [showGitInput, toggleGitInput] = useState(false);

  const onSubmit = (data: any) => {
    setLoading(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.employee,
      data: {
        ...data,
        company: companyId,
        end_date: data?.end_date || undefined,
        git_username: data?.git_username || undefined,
        team: data?.team || undefined,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("User created successfully.");
        reset();
        onClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    if (getValues("role") === "engineer") {
      toggleGitInput(true);
    } else {
      toggleGitInput(false);
    }
    // eslint-disable-next-line
  }, [watch("role")]);

  const handleClose = () => {
    reset();
    onClose();
  };
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      title={"Add New User"}
      // useCustomHeader
      sx={{ width: 500 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <CustomModalHeader
          onClose={handleClose}
          title={"Add New User"}
          saveBtnIsLoading={loading}
        /> */}
        <AddNewUserForm
          register={register}
          errors={errors}
          showGitInput={showGitInput}
          loading={loading}
        />
      </form>
    </DefaultModal>
  );
};

export default AddNewUserModal;
