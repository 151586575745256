import { Button } from "components/ui";

import { useEffect, useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import OtpInput from "react-otp-input";

import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";

import "./Twofa.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const LoginForm = () => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const { user } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  const onSubmit = () => {
    if (code?.length < 6) {
      setError(true);
    } else {
      setLoading(true);
      API({
        method: httpVerbs?.POST,
        url: apiUrls?.mfaCheck,
        data: {
          code,
          email: user?.email,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Login Successful!");
          if (user?.employee?.role === "admin") {
            navigate("/dashboard");
          } else {
            navigate("/project/overview");
          }
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  return (
    <section className="twofa-form">
      <div className="login-form__inputs">
        <div className="twofa-form-container">
          <OtpInput
            value={code}
            onChange={setCode}
            numInputs={6}
            renderSeparator={<span style={{ padding: "0 1rem" }}></span>}
            renderInput={(props) => <input {...props} className="input" />}
            containerStyle={{
              width: "100%",
            }}
            inputStyle={{
              width: "50px",
              height: "50px",
            }}
          />
          {error && code?.length < 6 && (
            <span style={{ color: "#FF0000", fontSize: '10px', marginTop: '1rem' }}>Code must be 6 numbers long</span>
          )}
        </div>
        <Button
          onClick={onSubmit}
          text="Verify Account"
          isLoading={loading}
          type="button"
          isLoadingText="Please wait..."
          className="verify-button"
        />
      </div>
    </section>
  );
};

export default LoginForm;
