import { ModalProps } from "../modal.d";
import "./DefaultModal.scss";
// import Icon from "../../Icon";
import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  DialogActions,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
// import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const DefaultModal = (props: ModalProps) => {
  const {
    children,
    isOpen,
    onClose,
    sx,
    title,
    actions,
    maxWidth,
    minWidth,
    height,
    maxHeight,
    width,
    no_close,
    hasBackground,
  } = props;
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => onClose()}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "center",
          },
          overflow: "hidden",
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.16)",
            minWidth: minWidth ? minWidth : 370,
            position: "relative",
            top: 0,
            left: { md: "16%", lg: "0%" },
            width,
            maxWidth: maxWidth ? maxWidth : "unset",
            height: height ? height : "unset",
            maxHeight: maxHeight ? maxHeight : 700,
            m: 0,
            zIndex: 1002,
            overflow: "hidden !important",
            ...sx,
          },
        }}
        scroll="paper"
      >
        <DialogTitle
          sx={{
            fontSize: 24,
            fontWeight: 700,
            color: "black",
            marginBottom: 0,
            paddingBottom: hasBackground ? "1rem" : "8px",
            backgroundColor: hasBackground ? "#EDF7FD" : "",
          }}
        >
          {!no_close ? (
            <Stack
              direction="row"
              alignItems="center"
              sx={{
                justifyContent: title ? "space-between" : "right",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {/* {hasBackground && (
                  <KeyboardBackspaceIcon
                    onClick={() => onClose()}
                    sx={{ mr: 1, cursor: "pointer" }}
                  />
                )} */}
                {title}
              </Box>
              <CloseIcon onClick={() => onClose()} sx={{ cursor: "pointer" }} />
            </Stack>
          ) : (
            title
          )}
        </DialogTitle>
        <DialogContent sx={{ padding: "0 28px", overflow: "auto" }}>
          {children}
        </DialogContent>
        <DialogActions sx={{ display: "block", px: 3, width: "100%" }}>
          {actions}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DefaultModal;
