import { Button } from "components/ui";
import Input from "components/ui/Input";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";

const ForgotPasswordForm = (props: { handleContinue?: Function }) => {
  const [loading, setLoading] = useState(false);
  const { handleContinue } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const sendOTP = (data: any) => {
    setLoading(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.sendPasswordOtp,
      data,
    })
      .then((resp) => {
        setLoading(false);
        handleContinue?.(data);
        reset();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const onSubmit = (data: any) => {
    sendOTP(data);
  };

  return (
    <section className="login-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="login-form__inputs">
          <Input
            label="Email address"
            name="email"
            hasError={errors?.email ? true : false}
            register={register}
            required={true}
          />
        </div>
        <Button
          text="Request OTP"
          isLoading={loading}
          type="submit"
          isLoadingText="Please wait..."
        />
      </form>
    </section>
  );
};

export default ForgotPasswordForm;
