import { Typography } from "@mui/material";
import AuthLayout from "../AuthLayout/AuthLayout";
// import AuthButtonActions from "../common/AuthButtonActions/AuthButtonActions";
import AuthHeader from "../common/AuthHeader/AuthHeader";
import VeirfyForm from "./VerifyEmail/TwoFaForm";
import { useSelector } from "react-redux";
import AuthFooter from "../common/AuthFooter/AuthFooter";

const Login = () => {
  const { user } = useSelector((state: any) => state.auth);

  return (
    <AuthLayout>
      <AuthHeader
        title={
          <>
            <Typography
              sx={{
                fontSize: "36px",
                fontWeight: "700",
                textAlign: "center",
                color: "#008ee2",
              }}
            >
              2-Factor Authentication
            </Typography>
          </>
        }
        subTitle={
          <>
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "400",
                textAlign: "center",
                color: "#666e80",
              }}
            >
              {user?.mfa_type === "email"
                ? `A verification code has been sent to your email ${user?.email}.`
                : "Check your authenticator app for your otp code."}
            </Typography>
          </>
        }
      />
      <VeirfyForm />

      <AuthFooter
        text={"Back to login?"}
        linkText={"Login"}
        link={"/auth/login"}
        hideLastText
      />
    </AuthLayout>
  );
};

export default Login;
