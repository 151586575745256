import assets from "assets";
import FileInput from "../FileInput";
import Icon from "../Icon";
import { useState } from "react";
import { getBase64 } from "utils/helper";

interface InputTypes {
  inputClass?: string;
  labelClass?: string;
  label?: string;
  placeholder?: string;
  onChange?: Function;
  value?: string;
  id?: string;
  name?: string;
  invertStyle?: boolean;
  hasError?: boolean;
  errorMsg?: string;
  className?: string;
  register?: Function;
  required?: boolean;
  type?: string;
  hideLabel?: boolean;
  isDisabled?: boolean;
  setFile?:Function
}

const AvatarUpload = (props: InputTypes) => {
  const { value, onChange,setFile } = props;
  const [image, setImage] = useState<any>("");

  const handleChange = async(file: any) => {
    const imgSrc =await getBase64(file?.[0]);
    setImage(imgSrc );
    onChange?.(file);
    setFile?.(file?.[0])
  };

  return (
    <div className="avatar__upload">
      <Icon src={image || value || assets.icons.uploadAvatar} useImg />
      <FileInput labelText="Upload Avatar" {...props} onChange={handleChange} />
    </div>
  );
};

export default AvatarUpload;
