export const auth_stages:any = {
  EMAIL: "EMAIL",
  PROFILE_INFO: "PROFILE_INFO",
  SUCCESS: "SUCCESS",
};

export const authHeaders:any = {
  EMAIL: {
    title: (
      <>
        Power up your <span className="primary-color">Sprintstar</span> Account
      </>
    ),
    text: "",
  },
  PROFILE_INFO: {
    title: (
      <>
        Welcome to <span className="primary-color">Sprintstar!</span>
      </>
    ),
    text: "Please fill in your details to help us get you  started.",
  },
  SUCCESS: {
    title: "You've got a mail!",
    text: (
      <>
        Please check your email. Activation link expires in{" "}
        <span className="primary-color">30minutes</span>
      </>
    ),
  },
};
