import { Box, Button, Stack, Typography } from "@mui/material";
import Loader from "components/ui/Loader";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { useEffect, useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import "./BlockerStyles.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { MODAL_ACTIONS } from "components/Main/enums";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useSelector } from "react-redux";

const AllBlocker = (props: {
  onClose: Function;
  selectedTask?: any;
  isOpen: boolean;
  setModalAction: any;
  toggleModal: any;
  selectBlocker?: any;
}) => {
  const { EDIT_BLOCKER_ACTION } = MODAL_ACTIONS;
  const {
    onClose,
    selectedTask,
    isOpen,
    setModalAction,
    toggleModal,
    selectBlocker,
  } = props;

  const [allBlockers, setAllBlockers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [oneBlocker, setOneblocker] = useState<any>(null);
  const { user } = useSelector((state: any) => state.auth);

  const handleClose = () => {
    setOneblocker(null);
    onClose?.();
  };

  const getTaskBlockers = async () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.taskBlockers,
      params: {
        task: selectedTask?.id,
      },
    })
      .then((resp) => {
        setAllBlockers(resp?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    getTaskBlockers();
  }, []);

  const deleteTaskBlocker = async () => {
    setLoading(true);
    API({
      method: httpVerbs?.DELETE,
      url: apiUrls?.deleteTaskBlocker(oneBlocker?.id),
    })
      .then((resp) => {
        toastMessage(`Blocker deleted successfully!`);
        handleClose();
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      // title={oneBlocker ? "Blocker Details" : "Blockers"}
      no_close
      title={
        <Stack
          direction="row"
          alignItems="center"
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {oneBlocker && (
              <KeyboardBackspaceIcon
                sx={{ cursor: "pointer" }}
                onClick={() => setOneblocker(null)}
              />
            )}
            <Typography sx={{ ml: 1, fontSize: '24px', fontWeight: 700 }}>
              {oneBlocker ? "Blocker Details" : "Blockers"}
            </Typography>
          </Box>
          {oneBlocker ? (
            <Box>
              <Button
                variant="outlined"
                sx={{ borderRadius: 2, textTransform: "inherit" }}
                onClick={() => {
                  setModalAction(EDIT_BLOCKER_ACTION);
                  toggleModal?.(true);
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#F91B0D",
                  ml: 4,
                  textTransform: "inherit",
                  borderRadius: 2,
                  "&:hover": {
                    backgroundColor: "#F91B0D",
                  },
                }}
                onClick={deleteTaskBlocker}
              >
                {loading ? "Please wait..." : "Delete"}
              </Button>
            </Box>
          ) : (
            <CloseIcon onClick={() => onClose()} sx={{ cursor: "pointer" }} />
          )}
        </Stack>
      }
      sx={{ width: 500, height: oneBlocker ? 400 : 700 }}
    >
      {oneBlocker ? (
        <div className="one_blocker">
          <div>
            <h4>Blocker</h4>
            <p>{oneBlocker?.blocked_by}</p>
          </div>
          <div>
            <h4>Description</h4>
            <p>{oneBlocker?.description}</p>
          </div>
          <div>
            <h4>Task Blocked</h4>
            <p>{oneBlocker?.task}</p>
          </div>
        </div>
      ) : (
        <>
          {loading ? (
            <Loader loaderType="PROGRESS" />
          ) : (
            <div>
              <h2>{selectedTask?.task_name}</h2>
              <div className="blocker_container">
                {allBlockers?.map((blocker, index) => (
                  <div key={index} className="blocker">
                    <p>Blocker {index + 1}</p>
                    <KeyboardArrowRightIcon
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        setOneblocker(blocker);
                        selectBlocker(blocker);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      <Button
        variant="contained"
        onClick={handleClose}
        sx={{ width: "90%", position: "absolute", bottom: "8%" }}
      >
        Ok
      </Button>
    </DefaultModal>
  );
};

export default AllBlocker;
