import { Button } from "components/ui";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import Select from "components/ui/Select";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import "../../POTasks.scss";

const DeletePOTask = (props: {
  onClose: Function;
  handleSuccess?: Function;
  selectedTask: any;
  isOpen: boolean;
  selectedProject: any;
}) => {
  const { onClose, handleSuccess, selectedTask, isOpen, selectedProject } =
    props;
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const { company } = employee || {};

  const { id: companyId } = company || {};
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const updateTask = (data: any) => {
    setLoading(true);
    API({
      method: httpVerbs?.PATCH,
      url: `${apiUrls?.projectTasks}${selectedTask?.id}/`,
      data: {
        project: selectedProject?.id,
        ...data,
        company: companyId,
        closed_at: data?.state === "done" ? new Date() : null,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Task added successfully.");
        handleSuccess?.();
        onClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    if (selectedTask) {
      const { state } = selectedTask;
      setValue("state", state);
    }
    // eslint-disable-next-line
  }, [selectedTask]);
  return (
    <>
      <DefaultModal
        isOpen={isOpen}
        onClose={onClose}
        showTitle={true}
        title="Update Task Status"
        hideButton={true}
        sx={{ width: 500 }}
      >
        <form onSubmit={handleSubmit(updateTask)}>
          <div className="update_modal">
            <div className="p-y-1">
              <Select
                label="Task Status"
                register={register}
                name="state"
                isDisabled={loading}
                required
                options={[
                  {
                    label: "Select Status",
                    value: "",
                  },
                  {
                    label: "Not Started",
                    value: "not_started",
                  },
                  {
                    label: "In Progress",
                    value: "in_progress",
                  },
                  {
                    label: "Completed",
                    value: "done",
                  },
                ]}
              />
            </div>
            <Button text={"Update Status"} isLoading={loading} type="submit" />
          </div>
        </form>
      </DefaultModal>
    </>
  );
};

export default DeletePOTask;
