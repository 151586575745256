import "./SideModal.scss";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { ReactNode } from "react";
import { ReactSVG } from "react-svg";
import assets from "assets";

const statusTypes = {
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

export interface ModalProps {
  children?: ReactNode;
  isOpen: boolean;
  onClose: Function;
  title?: string;
  subTitle?: string;
  titleClass?: string;
  message?: string;
  status?: string | boolean;
  hasError?: boolean;
  hasActions?: boolean;
  useCustomHeader?: boolean;
  swipeFromLeft?: boolean;
}

const SideModal = (props: ModalProps) => {
  const {
    children,
    isOpen,
    onClose,
    title,
    subTitle,
    titleClass,
    message,
    status,
    hasError,
    useCustomHeader = false,
    swipeFromLeft,
  } = props;

  const isError =
    status === statusTypes.ERROR ||
    hasError ||
    (status !== undefined && status === false);

  return (
    <>
      <Drawer
        open={isOpen}
        onClose={() => onClose()}
        direction={swipeFromLeft ? "left" : "right"}
        className={`side-modal ${titleClass}`}
      >
        {!useCustomHeader && (
          <div className="side-modal__heading">
            <div className="side-modal__heading__content">
              <h3>{title}</h3>
              <span onClick={() => onClose()}>
                <ReactSVG src={assets.icons.cancel} />
              </span>
            </div>
            <p>{subTitle}</p>
          </div>
        )}

        {message && (
          <div
            className={`side-modal__alert ${
              isError ? "side-modal__alert-error" : ""
            }`}
          >
            <div>{message}</div>
          </div>
        )}
        {isOpen && <div className="side-modal__content">{children}</div>}
      </Drawer>
    </>
  );
};

export default SideModal;
