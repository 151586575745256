import { Box, Button, Typography } from "@mui/material";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import "./ConnectStyles.scss";

const AllBlocker = (props: { onClose: Function; isOpen: boolean }) => {
  const { onClose, isOpen } = props;

  const handleClose = () => {
    onClose?.();
  };

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      title={"Connecting (PR) to Task"}
      hasBackground
      sx={{ width: 500, height: 600 }}
    >
      <Box sx={{ mt: 2 }}>
        <Typography sx={{ fontSize: "16px", fontWeight: 700, mb: 2 }}>
          Step-by-Step Guide
        </Typography>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
            Step 1: Create a Task or Issue
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
            Before creating a PR, ensure that there's a corresponding task or
            issue in your project management system that represents the work
            being done. If the task or issue doesn't exist, create it first.
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
            Step 2: Reference the Task in the PR
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
            hen creating the PR in GitHub, include a reference to the task or
            issue in the PR title or description. You can do this by mentioning
            the task ID or issue number using a hashtag followed by the ID.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
            Step 3: Link to Task in PR Description
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
            Provide additional context by linking to the task or issue in the PR
            description. You can use markdown to create a hyperlink to the task
            or issue.
          </Typography>
        </Box>
        <Box sx={{ mb: 2 }}>
          <Typography sx={{ fontSize: "12px", fontWeight: 700 }}>
            tep 4: Track PRs in Task Management System
          </Typography>
          <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
            In your task management system, ensure that PRs are tracked and
            linked back to the corresponding tasks or issues. This allows team
            members to easily see which tasks are associated with each PR and
            track progress.
          </Typography>
        </Box>
        <Button
          variant="contained"
          sx={{
            width: "100%",
            textTransform: "inherit",
            mt: 4,
            backgroundColor: "#008EE2",
            py: 1.2,
            "&:hover": {
              backgroundColor: "#008EE2",
            },
          }}
          onClick={handleClose}
        >
          Ok
        </Button>
      </Box>
    </DefaultModal>
  );
};

export default AllBlocker;
