import { Button } from "components/ui";
import Input from "components/ui/Input";
import "./SignupForm.scss";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import { useState } from "react";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";

const SignupForm = (props: {
  handleContinue?: Function;
  register?: Function;
  errors?: any;
  getValues: Function;
}) => {
  const { handleContinue, register, errors, getValues } = props;
  const [loading, setLoading] = useState(false);

  const checkIfEmailIsAvailable = () => {
    const email = getValues("email");
    setLoading(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.confirmEmail,
      data: {
        email,
      },
    })
      .then((resp) => {
        setLoading(false);
        handleContinue?.();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  return (
    <div className="signup-form">
      <Input
        label="Email Address"
        name="email"
        hasError={errors?.email ? true : false}
        register={register}
        required={true}
      />
      <Button
        text="Sign Up"
        isLoading={loading}
        onClick={checkIfEmailIsAvailable}
        className="signup-button"
      />
    </div>
  );
};

export default SignupForm;
