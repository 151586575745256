import React from "react";
import AppViewModal from "./AppViewModal";
import { Box, Typography } from "@mui/material";

const TeamSetupGuideModal = (props: any) => {
  const { open, handleClose } = props;
  return (
    <AppViewModal
      open={open}
      handleClose={handleClose}
      title="Team Setup Guide"
    >
      <Box sx={{ mt: 3, mx: 3 }}>
        <Typography
          sx={{ color: "#64748B", fontSize: "15px", fontWeight: 400 }}
        >
          The Team Setup Guide provides comprehensive data about every member of
          your organization:
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#3498db", fontSize: "14px", fontWeight: 700 }}
          >
            Employees:
          </Typography>
          <Typography
            sx={{ color: "#64748B", fontSize: "13px", fontWeight: 400, mt: 1 }}
          >
            With this feature, you can add your employees, measure their
            productivity, and monitor task delivery based on the tasks assigned
            to them
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#3498db", fontSize: "14px", fontWeight: 700 }}
          >
            Teams:
          </Typography>
          <Typography
            sx={{ color: "#64748B", fontSize: "13px", fontWeight: 400, mt: 1 }}
          >
            This feature is particularly useful for projects as it allows you to
            break down your projects into smaller sections. To create a team,
            simply add employees to form your team
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#3498db", fontSize: "14px", fontWeight: 700 }}
          >
            Payroll:
          </Typography>
          <Typography
            sx={{ color: "#64748B", fontSize: "13px", fontWeight: 400, mt: 1 }}
          >
            Here, you can access comprehensive information about the wages of
            all your employees.
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#3498db", fontSize: "14px", fontWeight: 700 }}
          >
            Clock-In:
          </Typography>
          <Typography
            sx={{ color: "#64748B", fontSize: "13px", fontWeight: 400, mt: 1 }}
          >
            This feature helps you keep track of your employees' attendance at
            work.
          </Typography>
        </Box>
      </Box>
    </AppViewModal>
  );
};

export default TeamSetupGuideModal;
