import React from "react";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import "../LayoutHeader/LayoutHeader.scss";
import "components/ui/Modal/DefaultModal/DefaultModal.scss";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SetupMfa = (props: { setMfaModal: Function; isOpen: boolean }) => {
  const { setMfaModal, isOpen } = props;
  const navigate = useNavigate();

  // Asybc request
  const handleSetup = () => {
    setMfaModal(false);
    navigate("/settings/mfa");
  };

  return (
    <>
      <DefaultModal
        isOpen={isOpen}
        onClose={() => setMfaModal(true)}
        sx={{ width: 500 }}
      >
        <div className="add_card">
          <h4>Two Factor Authentication</h4>
          <p>
            Due to security concerns, we strongly advise you to enable
            two-factor authentication before performing any actions on the
            application.
          </p>
          <Button
            sx={{
              mt: 5,
              textTransform: "inherit",
              backgroundColor: "#008EE2",
              py: 1.5,
              "&:hover": {
                backgroundColor: "#008EE2",
              },
            }}
            variant="contained"
            onClick={handleSetup}
          >
            Setup Two Factor Authentication
          </Button>
        </div>
      </DefaultModal>
    </>
  );
};

export default SetupMfa;
