import HelpSupport from "./HelpSupport";

const HelpSupportRoutes = [
  {
    title: "Help & Support",
    component: <HelpSupport />,
    route: "/help-support/",
  },
];

export default HelpSupportRoutes;
