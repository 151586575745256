import { useState } from "react";
import AuthLayout from "../AuthLayout/AuthLayout";
import AuthHeader from "../common/AuthHeader/AuthHeader";
import ForgotPasswordForm from "./ForgotPasswordForm/ForgotPasswordForm";
import { FORGOT_PASSWORD_STAGES } from "./enums";
import SetNewPasswordForm from "./SetNewPasswordForm/SetNewPasswordForm";
import VerifyOtpForm from "./VerifyOtpForm/VerifyOtpForm";
import AuthFooter from "../common/AuthFooter/AuthFooter";

const { EMAIL_FOFM, SET_NEW_PASSWORD, VERIFY_OTP } = FORGOT_PASSWORD_STAGES;

const ForgotPassword = () => {
  const [stage, setStage] = useState(EMAIL_FOFM);

  const [request, setRequest] = useState({});

  const handleContinue = (data?: Object) => {
    setRequest({
      ...request,
      ...data,
    });
    if (stage === EMAIL_FOFM) {
      return setStage(SET_NEW_PASSWORD);
    }
  };

  const renderBasedOnStage = () => {
    switch (stage) {
      case EMAIL_FOFM:
        return (
          <>
            <AuthHeader
              title={"Forgot password?"}
              subTitle={
                "Please provide your email address so that we can send you a link to reset your password"
              }
            />
            <ForgotPasswordForm handleContinue={handleContinue} />
            <AuthFooter
              text={'Remember password?'}
              linkText={"Login"}
              link={"/"}
              hideLastText
            />
          </>
        );
      case VERIFY_OTP:
        return (
          <>
            <AuthHeader
              title={"Validate  Recovery Code"}
              subTitle={
                "Enter the code sent to your email in order to recover your account"
              }
            />
            <VerifyOtpForm handleContinue={handleContinue} request={request} />
            <AuthFooter
              text={'Remember password?'}
              linkText={"Login"}
              link={"/"}
              hideLastText
            />
          </>
        );
      case SET_NEW_PASSWORD:
        return (
          <>
            <AuthHeader
              title={
                <p className="text_align">
                  Let’s help you re-power up your{" "}
                  <span className="primary-color">Sprintstar</span> Account
                </p>
              }
              subTitle={<p>Please enter the code sent your email address.</p>}
            />
            <SetNewPasswordForm
              handleContinue={handleContinue}
              request={request}
            />
            <AuthFooter
              text={
                <span className="">
                  Please note that you will redirected to log in again with your
                  newly created password.
                </span>
              }
              linkText={"Login"}
              link={"/auth/login"}
              hideLastText
            />
          </>
        );
      default:
        return "";
    }
  };

  return <AuthLayout>{renderBasedOnStage()}</AuthLayout>;
};
export default ForgotPassword;
