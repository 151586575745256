import { useState } from "react";
import IntegrationCard from "./IntegrationCard";
import EmptyView from "components/ui/EmptyView";
import "../integration.scss";

const IntegrationSection = (props: {
  results: any;
  handleSuccess?: Function;
  connected?: boolean;
}) => {
  // Props
  const { results, handleSuccess, connected } = props;
  //   States
  const [currentTab, setCurrentTab] = useState("All");

  const tabs = ["All", " Payments & Billing", "Code Repository"];

  const handleCurrentTab = (tab: string) => {
    setCurrentTab(tab);
  };
  const payment_group_integrations = results?.filter(
    (item: any) => item?.group?.toLowerCase() === "payment"
  );
  const vcs_group_integrations = results?.filter(
    (item: any) => item?.group?.toLowerCase() === "code repository"
  );

  const filteredIntegrations =
    currentTab === " Payments & Billing"
      ? payment_group_integrations
      : currentTab === "Code Repository"
      ? vcs_group_integrations
      : results;

  return (
    <div>
      <div className="integration_items">
        {!connected && (
          <div className="integration_items_header">
            {tabs?.map((tab: string, index: number) => (
              <p
                className={currentTab === tab ? "active_p" : "inactive_p"}
                onClick={() => handleCurrentTab(tab)}
                key={index}
              >
                {tab}
              </p>
            ))}
          </div>
        )}
        {filteredIntegrations?.length > 0 ? (
          <div className="integration_items_body">
            {filteredIntegrations?.map((integration: any, index: number) => (
              <IntegrationCard
                key={index}
                integration={integration}
                handleSuccess={handleSuccess}
              />
            ))}
          </div>
        ) : (
          <EmptyView />
        )}
      </div>
    </div>
  );
};

export default IntegrationSection;
