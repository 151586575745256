import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";
import SideModal from "components/ui/Modal/SideModal/SideModal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { formatDatePickerDate, getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import CreatePOTaskForm from "./CreatePOTaskForm/CreatePOTaskForm";

const CreatePOTask = (props: {
  onClose: Function;
  handleSuccess?: Function;
  selectedProject: any;
  isEdit?: boolean;
  selectedTask?: any;
  isOpen: boolean;
}) => {
  const {
    onClose,
    selectedProject,
    handleSuccess,
    isEdit,
    selectedTask,
    isOpen,
  } = props;

  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const { company } = employee || {};

  const { id: companyId } = company || {};

  const [loading, setLoading] = useState(false);
  const [loading_, setLoading_] = useState(false);
  const [milestone, setMilestone] = useState<any>(null);
  const [state_, setState_] = useState<any>(null);
  const [assigned, setAssigned] = useState<any>(null);
  const [taskPoint, setTaskPoint] = useState();
  const [projectMilestones, setProjectMilestone] = useState([]);
  const [users, setUsers] = useState([]);
  const [error, setError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitSuccessful },
    setValue,
    reset,
    getValues,
    watch,
  } = useForm();

  const handleClose = () => {
   reset({room: null}, {keepValues: false});
    onClose?.();
  };

  const getProjectMilestone = () => {
    setLoading_(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.projectMilestone,
      params: {
        project: selectedProject?.id || undefined,
      },
    })
      .then((resp) => {
        setLoading_(false);
        const { results } = resp?.data?.data;
        setProjectMilestone(results);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (user?.employee?.role !== "employee") {
      let httpsRequest = new AbortController();
      getProjectMilestone();
      return () => {
        httpsRequest?.abort();
      };
    }
    // eslint-disable-next-line
  }, [user?.employee?.role, selectedProject?.id]);

  const createProjectTask = async (data: any) => {
    if (milestone === null) {
      setError(true);
    } else if (state_ === null) {
      setError(true);
    } else {
      setLoading(true);
      API({
        method: httpVerbs?.POST,
        url: apiUrls?.projectTasks,
        data: {
          project: selectedProject?.id,
          ...data,
          milestone: milestone?.value,
          state: state_?.value,
          company: companyId,
          closed_at: data?.state === "done" ? new Date() : null,
          assigned: assigned?.value,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Task added successfully.");
          handleSuccess?.();
          reset({})
          handleClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const updateTask = (data: any) => {
    if (milestone === null) {
      setError(true);
    } else if (state_ === null) {
      setError(true);
    } else {
      setLoading(true);
      API({
        method: httpVerbs?.PATCH,
        url: `${apiUrls?.projectTasks}${data?.id}/`,
        data: {
          project: selectedProject?.id,
          ...data,
          milestone: milestone?.value,
          company: companyId,
          state: state_?.value,
          closed_at: data?.state === "done" ? new Date() : null,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Task added successfully.");
          handleSuccess?.();
          handleClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  useEffect(() => {
    if (isSubmitSuccessful) {
      console.log('hey')
      reset({})
    }
  }, [reset])

  const onSubmit = (data: any) => {
    isEdit ? updateTask(data) : createProjectTask(data);
  };

  const all_state = [
    {
      label: "Not Started",
      value: "not_started",
    },
    {
      label: "In Progress",
      value: "in_progress",
    },
    {
      label: "Completed",
      value: "done",
    },
  ];

  useEffect(() => {
    if (selectedTask) {
      Object.keys(selectedTask)?.map((tk: any) => {
        return setValue(tk, selectedTask[tk]);
      });
      const { start_date, state, expected_date, milestone, assigned } =
        selectedTask;
      const find_milestone = projectMilestones?.find(
        (data: any) => data.id === milestone
      ) as any;
      const find_assigned = users?.find(
        (data: any) => data?.id === assigned
      ) as any;

      const find_state = all_state?.find(
        (data: any) => data?.value === state
      ) as any;
      // setValue("milestone", milestone);
      setMilestone({
        label: find_milestone?.indicator,
        value: find_milestone?.id,
      });
      setAssigned({
        label: find_assigned?.name,
        value: find_assigned?.id,
      });
      setState_({
        label: find_state?.label,
        value: find_assigned?.value,
      });
      setValue("start_date", formatDatePickerDate(start_date));
      setValue("expected_date", formatDatePickerDate(expected_date));
    }
    // eslint-disable-next-line
  }, [selectedTask, loading_]);

  useEffect(() => {
    if (getValues("points")) {
      setTaskPoint(getValues("points"));
    }
    // eslint-disable-next-line
  }, [watch("points")]);

  const getUsers = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employee_names,
    })
      .then((resp) => {
        setUsers(resp.data.data);
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      user?.employee?.role === "project_manager" ||
      user?.employee?.role === "admin"
    ) {
      getUsers();
    }
  }, [user?.employee?.role]);

  const check_Points = (taskPoint as any) < selectedTask?.points;
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      title={isEdit ? "Update Task" : "Add New Task"}
      // useCustomHeader
      sx={{ width: 500 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CreatePOTaskForm
          register={register}
          errors={errors}
          milestone={milestone}
          setMilestone={setMilestone}
          state_={state_}
          setState_={setState_}
          assigned={assigned}
          setAssigned={setAssigned}
          selectedTask={selectedTask}
          isEdit={isEdit}
          loading={loading_}
          getValues={getValues}
          watch={watch}
          projectMilestones={projectMilestones}
          check_Points={check_Points}
          selectedProject={selectedProject}
          users={users}
          error={error}
        />
      </form>
    </DefaultModal>
  );
};

export default CreatePOTask;
