import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import DashboardCards from "./DashboardCards/DashboardCards";
import DashboardCodeAnalysis from "./DashboardCodeAnalysis/DashboardCodeAnalysis";
import DashboardLastRow from "./DashboardLastRow/DashboardLastRow";
import { useEffect, useState } from "react";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import Loader from "components/ui/Loader";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import { MetricDashboardPropsTypes } from "./DashboardD.types";
import { useAppContext } from "AppContext";
import { useNavigate } from "react-router-dom";
import SelectTeamModal from "../common/SelectTeamModal/SelectTeamModal";
import { useSelector } from "react-redux";

const { IDLE, ERROR, NULLMODE, LOADING, DATAMODE } = dataQueryStatuses;

const DashboardD = () => {
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const [result, setResult] = useState<MetricDashboardPropsTypes>({});
  const { isFilterLoading } = useAppContext();

  const { selectedTeam } = useSelector((state: any) => state.filter);

  const getDashboardData = (httpsRequest: any) => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: `${apiUrls?.dashboardMetrics}`,
      params: {},

      headers: {
        // signal: httpsRequest?.signal,
      },
    })
      .then((resp) => {
        const { data } = resp?.data || {};
        setStatus(DATAMODE);
        setResult(data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return (
          <ErrorView handleRetry={getDashboardData} message={errorMessage} />
        );
      case DATAMODE:
        return (
          <>
            <DashboardCards {...result} selectedFilter={selectedTeam} />
            <DashboardCodeAnalysis {...result} />
            <DashboardLastRow {...result} />
          </>
        );
      default:
        return "";
    }
  };

  const navigate = useNavigate();

  const renderBasedOnTeamAvailability = () => {
    if (!isFilterLoading ) {
      return (
        <EmptyView
          message="No team to view metric data for"
          hasActionBtn
          actionBtnText="Create A Team"
          handleAction={() => navigate("/admin/teams/")}
        />
      );
    } else {
      return renderBasedOnStage();
    }
  };

  useEffect(() => {
    let httpsRequest = new AbortController();
    if (selectedTeam?.id) {
      getDashboardData(httpsRequest);
    }
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, [selectedTeam?.id]);

  return (
    <>
      {renderBasedOnTeamAvailability()}
      <SelectTeamModal />
    </>
  );
};

export default DashboardD;
