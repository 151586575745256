import { useAppContext } from "AppContext";
import "./FilterDropdown.scss";
import assets from "assets";
import Button from "components/ui/Button";
import { useEffect, useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedRole,
  setSelectedTeam,
  setTeamList,
} from "store/filter/actions";
import { FILTER_OPTIONS } from "components/ui/layout/enums";
import { ROLES_NAMES } from "../enums";

const FilterDropdown = (props: {
  filterList?: Array<any>;
  selectFilter?: Function;
  toggleFilter: Function;
  preventSelectFirstItem?: boolean;
  filterName?: string;
  filterOption?: string;
}) => {
  const { toggleFilter, preventSelectFirstItem, filterName, filterOption } =
    props;
  const [loading, setLoading] = useState(false);
  const { setIsFilterLoading } = useAppContext();

  const { selectedTeam, selectedRole } = useSelector(
    (state: any) => state.filter
  );

  const dispatch = useDispatch();

  const getTeamList = () => {
    setLoading(true);
    setIsFilterLoading?.(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.teams,
      params: {},
      headers: {
        // signal: httpsRequest?.signal,
      },
    })
      .then((resp) => {
        setLoading(false);
        setIsFilterLoading?.(false);
        const { results } = resp?.data?.data || {};
        if (results.length > 0) {
          const firstTeam = results?.[0];
          if (!preventSelectFirstItem) {
            dispatch<any>(setSelectedTeam(firstTeam));
          } else {
            dispatch<any>(setSelectedTeam({}));
          }
          dispatch<any>(setTeamList(results));
        }
      })
      .catch((err) => {
        setLoading(false);
        setIsFilterLoading?.(false);
      });
  };

  useEffect(() => {
    if (preventSelectFirstItem) {
      dispatch<any>(setSelectedTeam({}));
      dispatch<any>(setSelectedRole(""));
    }
    // eslint-disable-next-line
  }, [preventSelectFirstItem]);

  useEffect(() => {
    getTeamList();
    // eslint-disable-next-line
  }, [preventSelectFirstItem]);

  const { title: selectedTeamName } = selectedTeam || {};

  const selectedFilterName =
    filterOption === FILTER_OPTIONS?.TEAM
      ? selectedTeamName
      : filterOption === FILTER_OPTIONS?.ROLE
      ? ROLES_NAMES?.[selectedRole]
      : "";

  return (
    <Button
      text={selectedFilterName || filterName || "Filter"}
      className="filter__download"
      icon={assets.icons.filter}
      isLoading={loading}
      isLoadingText="Fetching"
      onClick={() => toggleFilter(true)}
    />
  );
};

export default FilterDropdown;
