import { Button, CardHeader, Stack, Typography } from "@mui/material";
import Input from "../Input";

const TableTopbar = (props: any) => {
  const { title, actions, rowSpacing, search, onSearch, tabs, toggleTabs } =
    props;

  //translation

  return (
    <CardHeader
      title={
        <Stack direction="row" alignItems="center" sx={{ mt: "-0.3rem" }}>
          {tabs && (
            <>
              <Button
                sx={{
                  textTransform: "capitalize",
                  py: 0.9,
                  borderRadius: "8px",
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                  color: "#ffffff",
                  background: "#ffffff",
                  "&:hover": {
                    background: "#ffffff",
                    border: "blue",
                  },
                }}
                variant="outlined"
                onClick={() => toggleTabs("requested")}
              >
                {/* {t('requested')} */}
              </Button>
              <Button
                sx={{
                  textTransform: "capitalize",
                  py: 0.9,
                  borderRadius: "8px",
                  borderTopLeftRadius: 0,
                  borderBottomLeftRadius: 0,
                  mr: 3,
                  color: "blue",
                  background: "#ffffff",
                  border: "`1px solid blue`",
                  "&:hover": {
                    background: "#ffffff",
                    border: `0.3px solid blue`,
                  },
                }}
                variant="outlined"
                onClick={() => toggleTabs("uploaded")}
              >
                {/* {t('uploaded')} */}
              </Button>
            </>
          )}

          {search ? (
            <Input placeholder="search" type="text" onChange={onSearch} />
          ) : (
            <Typography sx={{ fontSize: "24px", fontWeight: 700 }}>
              {title}
            </Typography>
          )}
        </Stack>
      }
      action={
        <Stack direction="row" spacing={1}>
          {/* {search && (
                        <AppInput
                            placeholder="Search..."
                            sx={{
                                maxWidth: 200,
                                borderColor: theme.palette.gray[30],
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            controlStyle={{ mt: 0, width: 'fit-content' }}
                            onChange={onSearch}
                        />
                    )} */}
          {actions}
        </Stack>
      }
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        pr: 4,
        pb: rowSpacing ? 0 : 2.5,
      }}
    />
  );
};

export default TableTopbar;
