import ForgotPassword from "./ForgotPassword/ForgotPassword";
import VerifyEmail from "./VerifyEmail/Verify";
import TwoFa from "./TwoFA/TwoFa";
import Login from "./Login/Login";
import Onboarding from "./Onboarding/Onboarding";
import Signup from "./Signup/Signup";

const AuthRoutes = [
  {
    title: "SignUp",
    component: <Signup />,
    route: "/auth/signup",
  },
  {
    title: "Login",
    component: <Login />,
    route: "/auth/login",
  },
  {
    title: "Forgot Password",
    component: <ForgotPassword />,
    route: "/auth/forgot-password",
  },
  {
    title: "Verify Email",
    component: <VerifyEmail />,
    route: "/auth/verify-email",
  },
  {
    title: "Two FA",
    component: <TwoFa />,
    route: "/auth/two-fa",
  },
  {
    title: "Onboarding",
    component: <Onboarding />,
    route: "/auth/onboarding",
  },
];

export default AuthRoutes;
