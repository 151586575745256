import { TableCell, TableRow } from '@mui/material';
import Loader from "../Loader";

const LoadingTable = () => {
    return (
        <TableRow sx={{ height: '100%' }}>
            <TableCell colSpan={'100%' as any} sx={{ border: 0, height: '100%' }}>
                <Loader />
            </TableCell>
        </TableRow>
    );
};

export default LoadingTable;
