import * as React from 'react';
import AdminTeams from "./AdminTeams/AdminTeams";
import AdminUsers from "./AdminUsers/AdminUsers";
import TeamDetails from "../Metric/TeamDetails/TeamDetails";
import Payroll from "./Payroll/Index";
import Clocking from './Clocking/Index';

const AdminRoutes = [
  {
    title: "Employee Overview",
    component: <AdminUsers />,
    route: "/admin/users",
  },
  {
    title: "Teams",
    component: <AdminTeams />,
    route: "/admin/teams/",
  },
  {
    title: "Payroll",
    component: <Payroll />,
    route: "/admin/payroll/",
  },
  {
    title: "Team Details",
    component: <TeamDetails />,
    route: "/admin/team-details",
  },
  {
    title: "KPIs",
    component: <Clocking />,
    route: "/admin/clockin",
  },
];

export default AdminRoutes;
