import "./Input.scss";
import assets from "assets";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface InputTypes {
  inputClass?: string;
  labelClass?: string;
  label?: string;
  placeholder?: string;
  onChange?: Function;
  value?: string;
  defaultValue?: any;
  id?: string;
  name?: string;
  invertStyle?: boolean;
  hasError?: boolean;
  errorMsg?: string;
  className?: string;
  register?: Function;
  required?: boolean;
  type?: string;
  hideLabel?: boolean;
  isDisabled?: boolean;
  hideError?: boolean;
  minValue?: any;
  maxValue?: any;
  maxLength?: any;
  toggle?: boolean;
  passwordIcon?: any;
  handleToggle?: Function;
}

const Input = (props: InputTypes) => {
  const {
    inputClass,
    labelClass,
    placeholder,
    label,
    onChange,
    value,
    id,
    name,
    invertStyle,
    errorMsg,
    hasError,
    className,
    register,
    required = false,
    hideLabel,
    hideError,
    type = "text",
    isDisabled,
    defaultValue,
    minValue,
    maxValue,
    maxLength,
    toggle,
    handleToggle,
    passwordIcon,
  } = props;
  return (
    <>
      <div className={`text-input ${className}`}>
        {!hideLabel && <label className={`label ${labelClass}`}>{label}</label>}
        <div className="input_div">
          <input
            className={`input ${
              invertStyle ? "input-invert" : ""
            } ${inputClass} ${hasError ? "input-error-input" : ""}`}
            placeholder={placeholder}
            type={type}
            onChange={(e) => onChange?.(e.target.value)}
            value={value}
            defaultValue={defaultValue}
            id={id}
            name={name}
            {...register?.(name, {
              required,
              max: {
                value: maxValue && maxValue,
                message: maxValue && `must be less than ${maxValue}`,
              },
              min: {
                value: minValue && minValue,
                message: minValue && `must be greater than ${minValue}`,
              },
              maxLength: {
                value: maxLength && maxLength,
                message: maxLength && `Max ${maxLength} is allowed`,
              },
            })}
            disabled={isDisabled}
            min={minValue}
            max={maxValue}
          />
          {passwordIcon && (
            <div>
              {toggle ? (
                <VisibilityOffIcon
                  className="end_icon_1"
                  onClick={handleToggle as any}
                />
              ) : (
                <RemoveRedEyeIcon
                  className="end_icon_2"
                  onClick={handleToggle as any}
                />
              )}
            </div>
          )}
        </div>
        {hasError && !hideError && (
          <span className="input-error">
            {errorMsg ? errorMsg : `${label} is required.`}
          </span>
        )}
      </div>
    </>
  );
};

export default Input;
