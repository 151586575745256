import { MODAL_ACTIONS } from "components/Main/enums";
import CreateProject from "./CreateProject/CreateProject";
import CreateProjectMilestone from "./CreateProjectMilestone/ProjectMilestone";
import DeleteProject from "./DeleteProject/DeleteProject";
import DeleteProjectMilestone from "./DeleteProject/DeleteProjectMilestone";
import ViewProjectMilestone from "./ViewMilestone/ViewProjectMilestone";

const {
  EDIT_ACTION,
  CREATE_ACTION,
  DELETE_ACTION,
  CREATE_OTHER_ACTION,
  EDIT_OTHER_ACTION,
  DELETE_OTHER_ACTION,
  VIEW_ACTION
} = MODAL_ACTIONS;

const ProjectModals = (props: {
  modalAction: any;
  onClose: Function;
  handleSuccess: Function;
  isOpen: boolean;
  selectedProject?: any;
  milestone?: any;
}) => {
  const {
    modalAction,
    onClose,
    handleSuccess,
    isOpen,
    selectedProject,
    milestone,
  } = props;

  const renderBasedOnAction = () => {
    switch (modalAction) {
      case EDIT_ACTION:
      case CREATE_ACTION:
        return (
          <CreateProject
            onClose={onClose}
            handleSuccess={handleSuccess}
            isOpen={isOpen}
            selectedProject={selectedProject}
            isEdit={modalAction === EDIT_ACTION}
          />
        );
      case EDIT_OTHER_ACTION:
      case CREATE_OTHER_ACTION:
        return (
          <CreateProjectMilestone
            onClose={onClose}
            handleSuccess={handleSuccess}
            isOpen={isOpen}
            isEdit={modalAction === EDIT_OTHER_ACTION}
            selectedProject={selectedProject}
            selectedMilestone={milestone}
          />
        );

      case VIEW_ACTION:
          return (
            <ViewProjectMilestone
              onClose={onClose}
              isOpen={isOpen}
              selectedMilestone={milestone}
            />
          );

      case DELETE_OTHER_ACTION:
        return (
          <DeleteProjectMilestone
            onClose={onClose}
            handleSuccess={handleSuccess}
            isOpen={isOpen}
            selectedMilestone={milestone}
          />
        );
      case DELETE_ACTION:
        return (
          <DeleteProject
            onClose={onClose}
            handleSuccess={handleSuccess}
            isOpen={isOpen}
            selectedProject={selectedProject}
          />
        );
      default:
        return (
          <CreateProject
            onClose={onClose}
            handleSuccess={handleSuccess}
            isOpen={isOpen}
            selectedProject={selectedProject}
            isEdit={modalAction === EDIT_ACTION}
          />
        );
    }
  };

  return <>{renderBasedOnAction()}</>;
};

export default ProjectModals;
