import Select from "components/ui/Select";

import { Button } from "components/ui";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Input from "components/ui/Input";

import { COMPANY_SIZES, INDUSTRIES, USER_ROLES } from "../enums";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";

import "../Settings.scss";
import { updateAuthInfo } from "store/auth/actions";

const CompanySettings = (props: {
  hideRole?: boolean;
  request?: Object;
  otherClassName?: string;
  btnText?: string;
  hideTitle?: boolean;
  handleContinue?: Function;
}) => {
  const {
    hideRole,
    request,
    otherClassName,
    btnText,
    hideTitle,
    handleContinue,
  } = props;

  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const { company } = employee || {};

  const { id: companyId, onboarding, name: company_name } = company || {};

  const { job_title, industry, role, in_house_dev, in_house_tester, size } =
    onboarding || {};

  const [loading, setLoading] = useState(false);
  const [size_, setSize] = useState<any>(null);
  const [industry_, setIndustry] = useState<any>(null);
  const [error, setErrors] = useState(false);

  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const updateCompany = (data: any) => {
    if (size_ === null) {
      setErrors(true);
    } else if (industry_ === null) {
      setErrors(true);
    } else {
      setLoading(true);
      data.company = companyId;
      API({
        method: httpVerbs?.PATCH,
        url: apiUrls?.updateCompany(onboarding?.id),
        data: {
          ...data,
          size: size_?.value,
          industry: industry_?.value,
          ...request,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Successfully updated");
          const updatedUser = { ...user };
          updatedUser.employee.company = {
            ...updatedUser.employee.company,
            onboarding: resp.data.data,
          };
          dispatch<any>(updateAuthInfo({ user: updatedUser }));
          handleContinue?.();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const createCompany = (data: any) => {
    if (size_ === null) {
      setErrors(true);
    } else if (industry_ === null) {
      setErrors(true);
    } else {
      setLoading(true);
      data.company = companyId;
      API({
        method: httpVerbs?.POST,
        url: apiUrls?.createCompany,
        data: {
          ...data,
          size: size_?.value,
          industry: industry_?.value,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Successfully created");
          const updatedUser = { ...user };
          updatedUser.employee.company = {
            ...updatedUser.employee.company,
            onboarding: resp.data.data,
          };
          dispatch<any>(updateAuthInfo({ user: updatedUser }));
          handleContinue?.();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const onSubmit = (data: any) => {
    onboarding ? updateCompany(data) : createCompany(data);
  };

  useEffect(() => {
    const findIndustry = INDUSTRIES.find((data) => data?.value === industry);
    const findSize = COMPANY_SIZES.find((data) => data?.value === size);
    setValue("company", company_name);
    setValue("job_title", job_title);
    setValue("role", role);
    setValue("in_house_dev", in_house_dev);
    setValue("in_house_tester", in_house_tester);
    setIndustry(findIndustry);
    setSize(findSize);
    // eslint-disable-next-line
  }, [onboarding]);
  console.log({otherClassName})

  return (
    <div className={`${otherClassName ? "" : "settings"}`}>
      {!hideTitle && (
        <h3>
          {onboarding ? "Update your information" : "Set up your onboardin"}
        </h3>
      )}
      <form
        className={`${otherClassName ? otherClassName : "settings__form"}`}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Input
          type="text"
          name="job_title"
          label="Job Title"
          hasError={errors?.job_title ? true : false}
          register={register}
        />
        <Input
          type="text"
          name="company"
          label="Company"
          hasError={errors?.company ? true : false}
          register={register}
          isDisabled
        />
        <div>
          <Select
            label="Company size?"
            placeholder="Select option"
            options={COMPANY_SIZES}
            name="size"
            hasError={errors?.size ? true : false}
            register={register}
            value={size_}
            onChange={(e: any) => setSize(e)}
          />
          {error && size_ === null && (
            <span className="custom-select-error">
              Please select your company size
            </span>
          )}
        </div>
        <div>
          <Select
            label="What industry does your company operate in?"
            placeholder="Select option"
            options={INDUSTRIES}
            name="industry"
            hasError={errors?.industry ? true : false}
            register={register}
            value={industry_}
            onChange={(e: any) => setIndustry(e)}
          />

          {error && industry_ === null && (
            <span className="custom-select-error">
              Please select your industry
            </span>
          )}
        </div>
        {/* <Select
          label="Change  the version control of the system you use?"
          placeholder="Select option"
          hasError={errors?. ? true : false}
          register={register}
        /> */}
        <Button text={btnText || "Update"} type="submit" isLoading={loading} className="onboarding-button" />
      </form>
    </div>
  );
};

export default CompanySettings;
