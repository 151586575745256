import { Box, Button, Typography } from "@mui/material";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";

const DeletePOTask = (props: {
  onClose: Function;
  handleSuccess?: Function;
  selectedProject: any;
  selectedTask: any;
  isOpen: boolean;
}) => {
  const { onClose, handleSuccess, selectedTask, isOpen } = props;
  const [loading, setLoading] = useState(false);

  const deleteTask = () => {
    setLoading(true);
    API({
      method: httpVerbs?.DELETE,
      url: `${apiUrls?.projectTasks}${selectedTask?.id}/`,
    })
      .then((resp) => {
        toastMessage("Task deleted successfully");
        setLoading(false);
        handleSuccess?.();
        onClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };
  return (
    <>
      <DefaultModal
        isOpen={isOpen}
        onClose={onClose}
        title="Delete this project?"
        buttonText="Yes"
        sx={{ width: 500 }}
      >
      <Typography sx={{ textAlign: "center" }}>
        Are you sure you want to delete {" "}
        <b>{selectedTask?.task_name}</b> Task?
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          sx={{ textTransform: "inherit", color: "#008ee2" }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            ml: 2,
            width: loading ? "130px" : "100px",
            textTransform: "inherit",
            backgroundColor: "#008ee2",
            "&:hover": {
              backgroundColor: "#008ee2",
            },
          }}
          onClick={() => deleteTask()}
        >
          {loading ? "Please wait..." : "Yes"}
        </Button>
      </Box>
      </DefaultModal>
    </>
  );
};

export default DeletePOTask;
