import { Button } from "components/ui";

import { useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";

import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";

import "./VerifyForm.scss";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Input from "components/ui/Input";

const VerifyForm = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data: any) => {
    setLoading(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.verifyEmail,
      data,
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Email verification successful!");
        navigate("/auth/login");
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  return (
    <section className="login-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="login-form__inputs">
          <Input
            label="Verification Code"
            name="token"
            hasError={errors?.token ? true : false}
            register={register}
            required={true}
            placeholder="Code.."
          />
          <Button
            text="Verify Account"
            isLoading={loading}
            type="submit"
            isLoadingText="Please wait..."
            className="verify-button"
          />
        </div>
      </form>
    </section>
  );
};

export default VerifyForm;
