import { AvatarUpload, Button } from "components/ui";
import Input from "components/ui/Input";
import Select from "components/ui/Select";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage, getFormData } from "utils/helper";
import toastMessage from "utils/toast";
import { USER_ROLES } from "../enums";
import { updateAuthInfo } from "store/auth/actions";

const UserSettings = () => {
  const { user } = useSelector((state: any) => state.auth);
  const {
    email,
    first_name,
    last_name,
    picture,
    phone_number,
    username,
    id,
    employee,
  } = user || {};

  const { role } = employee || {};

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [file, setFile] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const updateProfile = (data: any) => {
    setLoading(true);
    if (file) {
      data.picture = file;
    } else {
      delete data.picture;
    }
    API({
      method: httpVerbs?.PATCH,
      url: apiUrls?.updateBasicProfile(id),
      data: getFormData(data),
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Successfully updated");
        dispatch<any>(updateAuthInfo({ user: resp.data.data }));
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const onSubmit = (data: any) => {
    updateProfile(data);
  };

  useEffect(() => {
    setValue("email", email);
    setValue("first_name", first_name);
    setValue("last_name", last_name);
    setValue("phone_number", phone_number);
    setValue("username", username);
    setValue("employee", role);
    // eslint-disable-next-line
  }, [user]);
  
  return (
    <div className="settings">
      <h3>Update your information</h3>
      <form className="settings__form" onSubmit={handleSubmit(onSubmit)}>
        <AvatarUpload
          name="picture"
          hasError={errors?.picture ? true : false}
          value={picture}
          setFile={setFile}
        />
        <Input
          type="email"
          name="email"
          label="Email"
          hasError={errors?.email ? true : false}
          register={register}
          required={true}
          isDisabled
        />
        <Input
          type="text"
          name="first_name"
          label="Your first name"
          hasError={errors?.first_name ? true : false}
          register={register}
          required={true}
        />
        <Input
          type="text"
          name="last_name"
          label="Your last name"
          hasError={errors?.last_name ? true : false}
          register={register}
          required={true}
        />
        <Input
          type="text"
          name="phone_number"
          label="Phone number"
          hasError={errors?.phone_number ? true : false}
          register={register}
          required={true}
        />
        <Button text="Update" type="submit" isLoading={loading} />
      </form>
    </div>
  );
};

export default UserSettings;
