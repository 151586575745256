import "./AdminVKpiPE.scss";
import PerfEffectiveness from "./PerfEffectiveness/PerfEffectiveness";
import TasksBreakdown from "./TasksBreakdown/TasksBreakdown";

const AdminVKpiPE = (props: any) => {
  return (
    <section className="admin-vkpi-pe">
      <PerfEffectiveness {...props} />
      <TasksBreakdown {...props} />
    </section>
  );
};

export default AdminVKpiPE;
