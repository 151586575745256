import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";
import SideModal from "components/ui/Modal/SideModal/SideModal";
import { useEffect, useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import { useForm } from "react-hook-form";
import CreateMilestone from "./CreateProject/CreateProject";

const CreateMilestones = (props: {
  handleSuccess: Function;
  onClose: Function;
  isOpen: boolean;
  isEdit?: boolean;
  selectedProject: any;
  selectedMilestone?: any;
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
    reset,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState([]);
  const [employees, setEmployees] = useState([]);
  const {
    handleSuccess,
    onClose,
    isEdit,
    isOpen,
    selectedProject,
    selectedMilestone,
  } = props;

  const handleClose = () => {
    reset();
    onClose();
  };

  const getEmployees = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employees,
      params: {
        all: true,
      },
    })
      .then((resp) => {
        setLoading(false);
        setEmployees(resp?.data?.data?.results);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getEmployees();
    // eslint-disable-next-line
  }, []);

  const project_employees = employees?.filter(
    (employee: any) => employee?.team?.id === selectedProject.team?.id
  );
  const { id, company } = selectedProject;

  const createProject = (data: any) => {
    setLoading(true);
    const filtered_employees = employee?.map((employ: any) => employ?.value);
    API({
      method: httpVerbs?.POST,
      url: `${apiUrls?.projectMilestone}`,
      data: {
        ...data,
        employee: filtered_employees,
        project: id,
        company: company,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Milestone created successfully.");
        handleSuccess();
        handleClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const updateProject = (data: any) => {
    setLoading(true);
    const filtered_employees = employee?.map((employ: any) => employ?.value);
    API({
      method: httpVerbs?.PATCH,
      url: `${apiUrls?.projectMilestone}${selectedMilestone?.id}/`,
      data: {
        ...data,
        employee: filtered_employees,
        project: id,
        company: company,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Milestone updated successfully.");
        handleSuccess();
        handleClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const onSubmit = (data: any) => {
    isEdit ? updateProject(data) : createProject(data);
  };

  useEffect(() => {
    if (selectedMilestone && isEdit) {
      setValue("indicator", selectedMilestone?.indicator);
      setValue("score", selectedMilestone?.score);
      setValue("points", selectedMilestone?.points);
      setValue("cost", selectedMilestone?.cost);
    }
    // eslint-disable-next-line
  }, [selectedMilestone, isEdit]);

  useEffect(() => {
    if (
      isEdit &&
      getValues("points") > selectedProject?.points_left + getValues("points")
    ) {
      setError("points", {
        type: "required",
        message: `Points cannot be more than ${
          selectedProject?.points_left + getValues("points")
        }`,
      });
    } else if (!isEdit && getValues("points") > selectedProject?.points_left) {
      setError("points", {
        type: "required",
        message: `Points cannot be more than ${selectedProject?.points_left}`,
      });
    }
  }, [getValues("points")]);

  useEffect(() => {
    if (isEdit && employees) {
      const active_employees = project_employees?.filter((item: any) => {
        return selectedMilestone?.employee?.find(
          (employee: any) => item?.id === employee
        );
      });
      const new_employees = active_employees?.map((employ: any) => {
        return {
          value: employ?.id,
          label: `${employ?.full_name}`,
        };
      });
      setEmployee(new_employees as []);
    }
  }, [isEdit, employees]);
  return (
    <SideModal
      isOpen={isOpen}
      onClose={onClose}
      title={isEdit ? "Update Milestone" : "Create  Milestone"}
      useCustomHeader
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomModalHeader
          onClose={handleClose}
          title={isEdit ? "Update Milestone" : "Create Milestone"}
          saveBtnIsLoading={loading}
        />
        <CreateMilestone
          register={register}
          errors={errors}
          isEdit={isEdit}
          employee={employee}
          setEmployee={setEmployee}
          selectedProject={selectedProject}
          setValue={setValue}
          project_employees={project_employees}
          loading={loading}
          getValues={getValues}
        />
      </form>
    </SideModal>
  );
};

export default CreateMilestones;
