import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import Layout from "components/ui/layout";
import "./App.css";

// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";

// import "../node_modules/@fullcalendar/core/main.css";
// import "../node_modules/@fullcalendar/daygrid/main.css";
// import "../node_modules/@fullcalendar/list/main.css";

function App() {
  return (
    <Router>
      <Layout>
        <AppRoutes />
      </Layout>
    </Router>
  );
}

export default App;
