import { ReactSVG } from "react-svg";
import "./ProjectListCard.scss";
import assets from "assets";

const ProjectListCard = (props: {
  isActive?: boolean;
  projectInfo: any;
  handleSelect: Function;
}) => {
  const { isActive, projectInfo, handleSelect } = props;

  const { is_risky, team, project_name, progress, start_date, kpi_score } =
    projectInfo || {};
  return (
    <div
      className={`project-list-card ${
        isActive ? (is_risky ? "active_" : "active") : ""
      }`}
      onClick={() => handleSelect(projectInfo)}
    >
      {is_risky ? (
        <div className="team-label_">
          <span>Project is at risk</span>
        </div>
      ) : (
        <div className="team-label">
          <span>
            {progress === 0
              ? "not Started"
              : progress < 100
              ? "In Progress"
              : "Done"}
          </span>
        </div>
      )}
      <div className="project-list-card__container">
        <div className="project-icon">
          <ReactSVG src={assets.icons.projectIcon} />
        </div>
        <div>
          <h3>{project_name}</h3>
          <p style={{ marginTop: "0.3rem" }}>
            Project Team : <span>{team?.title}</span>
          </p>
          <p>
            Team Lead: <span>{team?.lead_details?.name}</span>
          </p>
          <p>
            Start Date : <span>{start_date}</span>
          </p>
          <p>
            Team members: <span>{team?.member_count}</span>
          </p>
          <p>
            Project Progress : <span>{progress}%</span>
          </p>
          <p>
            Project KPI : <span>{kpi_score?.toFixed(2)}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProjectListCard;
