import { Button } from "components/ui";
import "../Settings.scss";
import assets from "assets";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage, getGitlabUrl } from "utils/helper";

const GitlabSettings = (props: {
  redirectUrl?: string;
}) => {
  const { redirectUrl } = props;

  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const isRequestMade = useRef(false);

  const from = ((location.state as any)?.from.pathname as string) || "/profile";
  const { user } = useSelector((state: any) => state.auth);

  const {
    employee: { company, role },
  } = user || {};

  const { installation_types } = company || {};

  const { code }: any = queryString.parse(window.location.search);

  const completeGitlabInstallation = () => {
    setLoading(true);
    isRequestMade.current = true;
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.setup_github,
      data: {
        installation_id: code,
        code,
        installation_type: "gitlab",
        company: company?.id,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Successfully installed Gitlab");
        navigate(redirectUrl ? redirectUrl : "/settings/gitlab/");
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const updateGitlabInstallation = () => {
    setLoading(true);
    isRequestMade.current = true;
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.setup_gitlab,
      data: {
        code,
        installation_type: "github",
        company: company?.id,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Successfully updated");
        navigate(redirectUrl ? redirectUrl : "/settings/gitlab/");
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const renderBasedOnRole = () => {
    switch (role) {
      case "admin":
        return (
          <form className="settings__form">
            <a href={getGitlabUrl(from)} aria-disabled>
              <Button
                text="Install/Update your organization Gitlab account"
                icon={assets.icons.gitlab}
                className="settings__github--button"
                isLoadingText="Please wait..."
                isLoading={loading}
              />
            </a>
          </form>
        );
      case "engineer":
        return (
          <form className="settings__form">
            <a href={getGitlabUrl(from)} aria-disabled>
              <Button
                text="Install/Update your organization Gitlab account"
                icon={assets.icons.gitlab}
                className="settings__github--button"
                isLoadingText="Please wait..."
                isLoading={loading}
              />
            </a>
          </form>
        );

      default:
        return "";
    }
  };

  useEffect(() => {
    if (code && !isRequestMade?.current) {
      completeGitlabInstallation();
    }
    if (installation_types?.includes("gitlab") && code) {
      updateGitlabInstallation();
    }
    // eslint-disable-next-line
  }, [code]);

  return (
    <div className="settings">
      <h3>Update your information</h3>
      {renderBasedOnRole()}
    </div>
  );
};

export default GitlabSettings;
