import StatsCardsList from "components/common/StatsCardsList/StatsCardsList";
import ErrorView from "components/ui/ErrorView";
import Loader from "components/ui/Loader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";

const { IDLE, ERROR, LOADING, SUCCESS } = dataQueryStatuses;

const TeamDetailsCards = () => {
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");

  const [statsData, setStatsData] = useState<{
    total_project?: any;
    long_comment?: any;
    awaiting_review?: any;
    team_cost?: any;
    total_deployment?: any;
    task_completed?: any;
    merged_without_review?: any;
  }>({});

  // const { selectedTeam } = useSelector((state: any) => state.filter);
  const { teamSelected } = useSelector((state: any) => state.team);
  const getTeamMetricsData = (httpsRequest: any) => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.teamSummary(teamSelected?.id),
      headers: {
        // signal: httpsRequest?.signal,
      },
    })
      .then((resp) => {
        const { data } = resp?.data || {};
        setStatus(SUCCESS);
        setStatsData(data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  const stats = [
    {
      title: "Total Project",
      value: statsData?.total_project,
      report: "Last 30 days",
    },
    {
      title: "Total Deployments",
      value: statsData?.total_deployment,
      report: "Last 30 day",
      background: "#008FE3",
    },
    {
      title: "Total Task Completed",
      value: statsData?.task_completed,
      report: "Last 30 Days",
      background: "#7DCFB6",
    },
    {
      title: "Total Team Cost",
      value: statsData?.team_cost?.toLocaleString() || 0,
      report: "Last 30 Days",
      background: "#002C61",
      color: "white",
    },
  ];
  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case ERROR:
        return (
          <ErrorView handleRetry={getTeamMetricsData} message={errorMessage} />
        );
      case SUCCESS:
        return <StatsCardsList stats={stats} />;
      default:
        return "";
    }
  };

  useEffect(() => {
    let httpsRequest = new AbortController();
    if (teamSelected?.id) {
      getTeamMetricsData(httpsRequest);
    }
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, [teamSelected?.id]);

  return <>{renderBasedOnStage()}</>;
};

export default TeamDetailsCards;
