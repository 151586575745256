import { Button, Stack, Typography } from "@mui/material";
import { useEffect, useState, ReactNode } from "react";
import DefaultModal from "../Modal/DefaultModal/DefaultModal";
import Clockin from "../../../assets/general/ClockIn.svg";
import Clockout from "../../../assets/general/ClockOut.svg";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import moment from "moment";
import TimePickers from "../TimePicker/TimePickers";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { updateAuthInfo } from "store/auth/actions";

type Props = {
  isOpen: boolean;
  onClose: Function;
  selectedUser?: any;
  edit?: boolean;
};

const ClockingModal = (props: Props) => {
  const { isOpen, onClose, edit, selectedUser } = props;
  const [loading, setLoading] = useState(false);
  const [loading_, setLoading_] = useState(false);
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [tasks, setTasks] = useState([
    {
      task_id: "",
      start_date: dayjs(new Date()),
      end_date: dayjs(new Date()),
    },
  ]);
  const [time, setTime] = useState("");
  const { user } = useSelector((state: any) => state.auth);

  const getProjectTasks = async (page?: number) => {
    setLoading_(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.projectTasks,
      headers: {
        // signal: httpsRequest?.signal,
      },
    })
      .then((resp) => {
        setLoading_(false);
        const { results } = resp?.data?.data || {};
        setDataList(results);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading_(false);
      });
  };

  useEffect(() => {
    getProjectTasks();
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setTasks(selectedUser?.tasks);
    }
  }, [selectedUser]);

  const handleClocking = () => {
    const find_empty_task = tasks?.find((task) => task?.task_id === "");
    if (find_empty_task && !user?.employee?.is_clocked_in) {
      setError(true);
    } else {
      setLoading(true);
      const filtered_tasks = tasks?.map((task) => {
        return {
          task_id: task?.task_id,
          start_date: edit ? task?.start_date : task?.start_date.toISOString(),
          end_date: edit ? task?.end_date : task?.end_date.toISOString(),
        };
      });
      API({
        method: edit ? httpVerbs?.PATCH : httpVerbs?.POST,
        url: edit
          ? `${apiUrls?.clocking}${selectedUser?.id}/`
          : apiUrls?.clocking,
        data: user?.employee?.is_clocked_in
          ? { employee: user?.employee?.id }
          : { employee: user?.employee?.id, tasks: filtered_tasks },
      })
        .then((resp) => {
          setLoading(false);
          setTime(
            user?.employee?.is_clocked_in
              ? resp?.data?.data?.clock_out
              : resp?.data?.data?.clock_in
          );
          const updatedUser = { ...user };
          updatedUser.employee = {
            is_clocked_in: user?.employee?.is_clocked_in ? false : true,
          };
          dispatch<any>(updateAuthInfo({ user: updatedUser }));
          toastMessage(resp?.data?.message);
          setTimeout(() => {
            onClose();
            setTime("");
          }, 4000);
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };
  const handleClose = () => {
    onClose();
    localStorage.setItem("loggedin", "true");
    setTime("");
  };

  const handleTaskValueChange = (index: any, event: any, name: string) => {
    const taskValueCopy = [...tasks];
    if (name === "task_id") {
      taskValueCopy[index] = {
        ...taskValueCopy[index],
        [name]: event.target.value,
      };
    } else {
      taskValueCopy[index] = {
        ...taskValueCopy[index],
        [name]: event,
      };
    }
    setTasks(taskValueCopy);
  };
  const addNewTask = () => {
    setTasks((current: any) => [...current, {}]);
  };
  const deleteTask = (index: any) => {
    const taskValueCopy = [...tasks];
    taskValueCopy?.splice(index, 1);
    setTasks(taskValueCopy);
  };
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      sx={{ width: 500, borderRadius: 2, overflow: "hidden" }}
      // no_close
    >
      <Stack
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        {time ? (
          <>
            <CheckCircleOutlineIcon />
            <Typography sx={{ fontSize: "24px", fontWeight: 700, mb: 1 }}>
              {user?.employee?.is_clocked_in ? "Clocked In" : "Clocked Out"}
            </Typography>
            <Typography sx={{ fontSize: "20px", fontWeight: 400 }}>
              You clocked {user?.employee?.is_clocked_in ? "in" : "out"} at{" "}
              {moment(time).format("h:mm A")}
            </Typography>
          </>
        ) : (
          <>
            <Typography sx={{ fontSize: "32px", fontWeight: 700 }}>
              {edit
                ? "Update Clockin"
                : user?.employee?.is_clocked_in
                ? "Clock out of"
                : "Clock in to"}
            </Typography>
            <Typography
              sx={{
                color: "#008ee2",
                fontSize: "20px",
                fontWeight: 700,
                mb: 2,
              }}
            >
              Sprintstar
            </Typography>{" "}
            {(!user?.employee?.is_clocked_in || edit) && (
              <>
                {tasks?.map((task: any, index: number) => (
                  <div key={index} className="task_container">
                    <div>
                      <label>Tasks</label>
                      <br />
                      <select
                        placeholder="Task Blocked"
                        // className="task_select"
                        value={task.task_id}
                        onChange={(value) =>
                          handleTaskValueChange(index, value, "task_id")
                        }
                      >
                        <option value="">
                          {loading_ ? "Please Wait" : "Choose Task"}
                        </option>
                        {dataList?.map((option: any) => (
                          <option key={option?.id} value={option?.id}>
                            {option?.task_name}
                          </option>
                        ))}
                      </select>
                      {error && task?.task_id === "" && (
                        <p
                          style={{
                            color: "#f91b0d",
                            margin: "0 !important",
                            fontSize: "12px",
                          }}
                        >
                          Please select a task
                        </p>
                      )}
                    </div>
                    <DemoContainer components={["TimePicker", "TimePicker"]}>
                      <TimePickers
                        value={task.start_date}
                        // onChange={(value: any) =>
                        //   handleTaskValueChange(index, value)
                        // }
                        onChange={(value: any) =>
                          handleTaskValueChange(index, value, "start_date")
                        }
                        // onChange={(newValue: any) => setStartDate(newValue)}
                        label="Start Time"
                      />
                      <TimePickers
                        value={task.end_date}
                        // onChange={(value: any) =>
                        //   handleTaskValueChange(index, value)
                        // }
                        onChange={(value: any) =>
                          handleTaskValueChange(index, value, "end_date")
                        }
                        // onChange={(newValue: any) => setEndDate(newValue)}
                        label="End Time"
                      />
                    </DemoContainer>
                    {tasks.length > 1 && (
                      <Button
                        onClick={deleteTask}
                        variant="contained"
                        sx={{
                          border: "1px solid #008EE2",
                          mt: 1,
                          textTransform: "inherit",
                          fontSize: 12,
                          float: "right",
                        }}
                        endIcon={<RemoveIcon />}
                      >
                        Remove Task
                      </Button>
                    )}
                  </div>
                ))}
              </>
            )}
            {(!user?.employee?.is_clocked_in || edit) &&
              dataList?.length > tasks?.length && (
                <Button
                  onClick={addNewTask}
                  variant="contained"
                  sx={{
                    textTransform: "inherit",
                    fontSize: 12,
                  }}
                  endIcon={<AddIcon />}
                >
                  Add Task
                </Button>
              )}
            <Button
              startIcon={
                loading ? (
                  ""
                ) : user?.employee?.is_clocked_in ? (
                  <img src={Clockout} alt="clockin" width="30" height="30" />
                ) : (
                  <img src={Clockin} alt="clockin" width="30" height="30" />
                )
              }
              variant="contained"
              sx={{
                textTransform: "inherit",
                mt: 8,
                borderRadius: 1,
                py: 1,
                width: "100%",
                backgroundColor: edit
                  ? "#008EE2"
                  : user?.employee?.is_clocked_in
                  ? "#F20505"
                  : "#008EE2",
                fontSize: "24px",
                fontWeight: 700,
                "&:hover": {
                  backgroundColor: edit
                    ? "#008EE2"
                    : user?.employee?.is_clocked_in
                    ? "#F20505"
                    : "#008EE2",
                },
              }}
              onClick={handleClocking}
            >
              {loading
                ? "Please wait..."
                : edit
                ? "Update Clockin"
                : user?.employee?.is_clocked_in
                ? "Clock Out"
                : "Clock in"}
            </Button>
          </>
        )}
      </Stack>
    </DefaultModal>
  );
};

export default ClockingModal;
