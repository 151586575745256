import { useState } from "react";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import { Button } from "components/ui";

const DisconnectModal = (props: {
  onClose: any;
  isOpen: boolean;
  integration: any;
  handleSuccess?: any;
  id: string;
}) => {
  const { onClose, isOpen, integration, handleSuccess, id } = props;

  const [loading, setLoading] = useState(false);

  const deleteIntegrations = () => {
    setLoading(true);
    API({
      method: httpVerbs?.DELETE,
      url: apiUrls?.deleteUserIntegration(id),
    })
      .then((resp) => {
        onClose();
        setLoading(false);
        handleSuccess();
        toastMessage(`Integration Successfully deleted!`);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const deleteCodeIntegrations = () => {
    setLoading(true);
    API({
      method: httpVerbs?.DELETE,
      url: apiUrls?.deleteCodeIntegration(id),
    })
      .then((resp) => {
        onClose();
        setLoading(false);
        handleSuccess();
        toastMessage(`Integration Successfully deleted!`);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      title={`Disconnect ${integration?.name}`}
      showCloseIcon
      sx={{ width: 500 }}
    >
      <div className="modal_delete_title">
        <p>
          {`
        Account disconnected cannot be recovered. Are you sure you want to
        disconnect ${integration?.name}?`}
        </p>
      </div>
      <div className="modal_delete_btns">
        <Button
          className={loading ? "no_btn_" : "yes_btn"}
          text={"Yes"}
          isLoadingText="Please wait..."
          isLoading={loading}
          onClick={() => {
            if (integration?.type === "oauth" && integration?.name?.toLowerCase() !== 'heroku') {
              deleteCodeIntegrations();
            } else {
              deleteIntegrations();
            }
          }}
        />
        <button className={loading ? "no_btn_" : "no_btn"} onClick={onClose}>
          No
        </button>
      </div>
    </DefaultModal>
  );
};

export default DisconnectModal;
