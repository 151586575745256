import { MetricDashboardPropsTypes } from "components/Main/Metric/DashboardD/DashboardD.types";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts";
import Select from "components/ui/Select";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { useEffect, useState } from "react";

const DashboardCodeRepMetric = (props: MetricDashboardPropsTypes) => {
  const { projects, project, setProject, loading, setLoading } = props;
  const [taskCompleted, setTaskCompleted] = useState([]);

  const GetTaskCompleted = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.getTaskCompleted(project?.value || project),
    })
      .then((resp) => {
        setLoading(false);
        const { data } = resp?.data || {};
        setTaskCompleted(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (project) {
      GetTaskCompleted();
    }
  }, [project]);

  return (
    <section className="dashboard__chart1">
      <div className="dashboard__chart__header">
        <h3>Task Completed</h3>
        <div className="d-flex p-y-1 create-pc-modal-form__inputs">
          <Select
            // label="Select Project"
            value={project}
            onChange={(e: any) => setProject(e)}
            isLoading={loading}
            options={[
              ...projects?.map(({ project_name, id }: any) => {
                return {
                  value: JSON.stringify(id),
                  label: project_name,
                };
              }),
            ]}
          />
        </div>
      </div>
      <div className="dashboard_sub_heading"></div>
      <div className="repository-metrics__chart">
        <ResponsiveContainer width="100%" height="100%">
          <LineChart width={300} height={100} data={taskCompleted}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="task"
              stroke="#8884d8"
              strokeDasharray="3 4 5 2"
            /> 
          </LineChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default DashboardCodeRepMetric;
