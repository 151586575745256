import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  Drawer,
  Slide,
  Box,
  Stack,
  IconButton,
  Typography,
  Divider,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const AppViewModal = (props) => {
  const theme = useTheme();
  const { open, handleClose, children, title } = props;
  return (
    <Drawer
      open={open}
      anchor={"right"}
      TransitionComponent={Transition}
      transitionDuration={1000}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        sx: {
          boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.16)",
          borderRadius: 4,
          width: "500px",
          flex: 1,
          overflow: "auto",
          zIndex: 9999,
        },
      }}
      scroll="paper"
    >
      <Box>
        {title && (
          <Stack
            sx={{
              padding: 2,
              width: "55%",
            }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <ArrowBackIosIcon
              sx={{ color: "#3498db", cursor: "pointer" }}
              onClick={handleClose}
            />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 800,
                color: "#3498db",
                ml: 1,
              }}
            >
              {title}
            </Typography>
          </Stack>
        )}
      </Box>
      <Divider sx={{ color: "#F1F5F9" }} />
      <Box sx={{ flex: 1 }}>{children}</Box>
    </Drawer>
  );
};

export default AppViewModal;
