import StatisticsCards from "./StatisticsCards/StatisticsCards";
import StatisticsTable from "./StatisticsTable/StatisticsTable";

const Statistics = () => {
  return (
    <>
      <StatisticsCards />
      <StatisticsTable />
    </>
  );
};

export default Statistics;
