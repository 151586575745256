import React, { useEffect } from "react";
import { useSelector } from "react-redux";
// import { Route, Router, Routes } from "react-router-dom";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { routes } from "./routes";

const AppRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { token } = useSelector((state: any) => state.auth);

  const active_route = routes.find(
    (route) => route.route === location.pathname
  );

  useEffect(() => {
    if (!active_route && !token) {
      navigate("/auth/login");
    } 
  }, [token]);
  return (
    <Routes>
      {routes.map(({ route, component }, key) => {
        return (
          <React.Fragment key={key}>
            <Route path={route} element={component} />
          </React.Fragment>
        );
      })}
    </Routes>
  );
};

export default AppRoutes;
