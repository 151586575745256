import React, { useEffect, useState } from "react";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import { Box } from "@mui/material";
import Cards from "./Cards";
import Header from "./Header";
import Loader from "components/ui/Loader";

const Index = () => {
  const [loading, setLoading] = useState(false);
  const [allCards, setAllCards] = useState([]);

  const getAllCards = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.getAllCards,
    })
      .then((resp) => {
        setAllCards(resp?.data?.data);
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    getAllCards();
  }, []);
  return (
    <Box>
      <Header getAllCards={getAllCards} setLoading={setLoading} />
      {loading ? <Loader /> : <Cards getAllCards={getAllCards} allCards={allCards} />}
    </Box>
  );
};

export default Index;
