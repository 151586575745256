import axios from "axios";
import { getAuthToken } from "storage";
import { apiUrls } from ".";

export const httpSignal = axios.CancelToken.source();

const logOutUser = () => {
  return (window.location.href = "/");
};

const CancelToken = axios.CancelToken;
let requestSignal;

const config = {
  apiGateway: {
    BASE_URL: process.env.REACT_APP_DEV_API_BASE_URL,
  },
};

const { BASE_URL } = config.apiGateway;

const API = axios.create({
  baseURL: BASE_URL,
});

const noTokenUrls = [
  apiUrls.login,
  apiUrls.sendPasswordOtp,
  apiUrls.setNewPassword(""),
  apiUrls.confirmEmail,
  apiUrls.validatePasswordOtp,
  apiUrls.register,
];

API.interceptors.request.use(
  (config) => {
    const accessToken = getAuthToken();
    if (!noTokenUrls?.includes(config.url || "")) {
      accessToken && (config.headers!.Authorization = "Token " + accessToken);
    }
    requestSignal = CancelToken.source();
    config.cancelToken = requestSignal.token;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

API.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    const status = error?.response?.status;
    const accessToken = getAuthToken();
    if (status === 401 && accessToken) {
      sessionStorage.clear();
      localStorage.clear();
      if (!noTokenUrls?.includes(error?.config.url || "")) {
        logOutUser();
      }

      return Promise.reject(error);
    }

    return Promise.reject(error);
  }
);

export default API;
