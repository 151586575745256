import Project from "./Project";
import AdminKPIs from "./AdminKPIs/AdminKPIs";
import Clocking from '../Admin/Clocking/Index';

const ProjectRoutes = [
  {
    title: "Project",
    component: <Project />,
    route: "/project/overview",
  },
  {
    title: "KPIs",
    component: <AdminKPIs />,
    route: "/project/kpis/*",
  },
];

export default ProjectRoutes;
