import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import "./TwoFa.scss";

type Props = {
  register: Function;
  errors: any;
  loading: boolean;
};
const EmailConnectionPage = ({ register, errors, loading }: Props) => {
  const { user } = useSelector((state: any) => state.auth);

  return (
    <Box sx={{ width: "100%" }} className="email_authentication">
      <h1>Verification code required</h1>
      <p>
        Enter the 6-digits verification code sent to your mail{" "}
        <span>{user?.email}</span>
      </p>
      <div>
        <Box sx={{ width: "100%", mt: 3 }}>
          <div>
            <input
              {...register?.("code", { required: true })}
              placeholder="Enter Code"
              type="text"
              className="input"
            />
            {errors?.code && (
              <Typography color="error">Code is required.</Typography>
            )}
          </div>
          <Button
            variant="contained"
            type="submit"
            sx={{
              mt: 1.5,
              px: 6,
              backgroundColor: "#008EE2",
              py: 1.5,
              float: "right",
              "&:hover": {
                backgroundColor: "#008EE2",
              },
            }}
            disabled={loading}
          >
            Verify
          </Button>
        </Box>
      </div>
    </Box>
  );
};

export default EmailConnectionPage;
