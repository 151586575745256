import EmptyView from "components/ui/EmptyView";
import { MetricDashboardPropsTypes } from "../../DashboardD.types";
import "./PagesTested.scss";

import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const COLORS = ["#F79256", "#E4E4E4", "#008EE2"];

const PagesTested = (props: MetricDashboardPropsTypes) => {
  const { issue_chart, project_chart } = props;

  const issueChartData = [
    {
      name: "Open",
      value: issue_chart?.open,
    },
    {
      name: "Completed",
      value: issue_chart?.closed,
    },
    {
      name: "Total",
      value: issue_chart?.total,
    },
  ];

  const projectChartData = [
    {
      name: "Open",
      value: project_chart?.open,
    },
    {
      name: "Completed",
      value: project_chart?.closed,
    },
    {
      name: "Total",
      value: project_chart?.total,
    },
  ];

  return (
    <section className="pages-tested">
      <div className="pages-tested__header">
        <h3>Pages tested</h3>
      </div>

      <div className="pages-tested__chart-container">
        <div>
          <h5>Project</h5>
          <div className="pages-tested__chart-container__chart">
            {project_chart?.total === 0 ? (
              <EmptyView message="No project chart data available yet" />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Tooltip />
                  <Legend height={30} align="left" />
                  <Pie
                    data={projectChartData}
                    dataKey="value"
                    nameKey="name"
                    stroke="none"
                    innerRadius={25}
                    outerRadius={45}
                    fill="#F79256"
                  >
                    {projectChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
        <div>
          <h5>Issues</h5>
          <div className="pages-tested__chart-container__chart">
            {issue_chart?.total === 0 ? (
              <EmptyView message="No issues chart data available yet" />
            ) : (
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Tooltip />
                  <Legend height={30} align="left" />

                  <Pie
                    data={issueChartData}
                    dataKey="value"
                    nameKey="name"
                    stroke="none"
                    innerRadius={25}
                    outerRadius={45}
                    fill="#F79256"
                  >
                    {issueChartData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PagesTested;
