import * as types from "./types";

const initialState = {
  github: "",
  gitlab: "",
};

const AuthReducer = (
  state = initialState,
  props: {
    type: string;
    payload: any;
  }
) => {
  const { type, payload } = props;

  switch (type) {
    case types.SET_GITHUB_REDIRECTION:
      return { ...state, ...payload };

    default:
      return state;
  }
};

export default AuthReducer;
