export const MODAL_ACTIONS = {
  EDIT_ACTION: "EDIT_ACTION",
  DELETE_ACTION: "DELETE_ACTION",
  CREATE_ACTION: "CREATE_ACTION",
  VIEW_ACTION: "VIEW_ACTION",
  CREATE_OTHER_ACTION: "CREATE_OTHER_ACTION",
  EDIT_OTHER_ACTION: "EDIT_OTHER_ACTION",
  DELETE_OTHER_ACTION: "DELETE_OTHER_ACTION",
  CREATE_BLOCKER: "CREATE_BLOCKER",
  ALL_BLOCKERS: "CREATE_BLOCKERS",
  CONNECT_GIT: "CONNECT_GIT",
  VIEW_BLOCKER: "VIEW_BLOCKER",
  EDIT_BLOCKER_ACTION: "EDIT_BLOCKER_ACTION",
};
