import { AppTable } from "components/ui";
import "./AdminUsersTable.scss";
import { getFormatedDate } from "utils/helper";
import { MODAL_ACTIONS } from "components/Main/enums";
import { Box, Fade, Tooltip, Typography } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EmptyView from "components/ui/EmptyView";
import DeleteIcon from "@mui/icons-material/Delete";

const { EDIT_ACTION, DELETE_ACTION } = MODAL_ACTIONS;

const AdminUsersTable = (props: {
  result: any;
  count: any;
  page: number;
  setPage: Function;
  setModalAction: Function;
  selectUser: Function;
  toggleModal: Function;
  per_page: number;
}) => {
  const {
    result,
    count,
    page,
    setPage,
    setModalAction,
    selectUser,
    toggleModal,
    per_page,
  } = props;

  const columns = [
    {
      title: "Name",
      render: (row: any) => (
        <Typography sx={{ fontSize: "12px", textTransform: "capitalize" }}>
          {row?.full_name}
        </Typography>
      ),
    },
    {
      title: "Role",
      align: "left",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.role?.replace(/_/g, " ") || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Team",
      align: "left",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.team?.title || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Wages",
      align: "left",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          &#8358;{row?.wages?.toLocaleString() || 0}
        </Typography>
      ),
    },
    {
      title: "Git Username",
      align: "left",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.git_username || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Start Date",
      align: "center",
      padding: "0 5px",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {getFormatedDate(row?.start_date) || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (row: any) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Tooltip
            title="Update Employee"
            placement="top-end"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <BorderColorIcon
              sx={{ width: 18, height: 18, marginRight: "1rem" }}
              onClick={() => {
                selectUser(row);
                setModalAction(EDIT_ACTION);
                toggleModal(true);
              }}
            />
          </Tooltip>
          <Tooltip
            title="Delete Employee"
            placement="top-start"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <DeleteIcon
              onClick={() => {
                selectUser(row);
                setModalAction(DELETE_ACTION);
                toggleModal(true);
              }}
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const filtered_result = result?.filter((data: any) => data?.role !== "admin");
  return (
    <div className="admin-user-table">
      {filtered_result?.length > 0 ? (
        <AppTable
          columns={columns}
          data={filtered_result}
          sorter={(a: any, b: any) => b?.id - a?.id}
          dataLength={count}
          noPerPage={per_page}
          page={page}
          onPageChange={handlePageChange}
        />
      ) : (
        <EmptyView message="No Employee Found" />
      )}
    </div>
  );
};

export default AdminUsersTable;
