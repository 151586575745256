import { useEffect, useState } from "react";
import AdminUsersTable from "./AdminUsersTable/AdminUsersTable";
import Loader from "components/ui/Loader";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import { useAppContext } from "AppContext";
import AdminUsersModals from "./AdminUsersModals/AdminUsersModals";
import { MODAL_ACTIONS } from "components/Main/enums";
import FilterModal from "components/common/FilterModal/FilterModal";
import { useSelector } from "react-redux";
import AdminTeamsCards from "../AdminTeams/AdminTeamsCards/AdminTeamsCards";

const { LOADING, IDLE, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;
const { CREATE_ACTION } = MODAL_ACTIONS;

const AdminUsers = () => {
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const [result, setResult] = useState([]);

  const { isModalOpen, closeModal, search } = useAppContext();
  const [selectedUser, selectUser] = useState({});
  const [showModal, toggleModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(0);
  const { selectedRole } = useSelector((state: any) => state.filter);

  const getUsers = () => {
    setStatus(LOADING);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employees,
      params: {
        q: search,
        page: page,
        role: selectedRole || undefined,
      },
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const { results, count, per_page } = resp.data.data || {};
        setCount(count);
        setResult(results);
        setPerPage(per_page);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getUsers} message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            <AdminTeamsCards />
            <AdminUsersTable
              result={result}
              count={count}
              page={page}
              setPage={setPage}
              setModalAction={setModalAction}
              selectUser={selectUser}
              toggleModal={toggleModal}
              per_page={per_page}
            />
          </>
        );
      default:
        return "";
    }
  };

  const handleClose = () => {
    selectUser({});
    toggleModal(false);
    closeModal();
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [search, selectedRole, page]);

  return (
    <>
      {renderBasedOnStage()}
      <AdminUsersModals
        onClose={handleClose}
        modalAction={isModalOpen ? CREATE_ACTION : modalAction}
        isOpen={isModalOpen || showModal}
        selectedUser={selectedUser}
        handleSuccess={getUsers}
      />

      <FilterModal filterType="ROLE" />
    </>
  );
};

export default AdminUsers;
