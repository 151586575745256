import { useState } from "react";
import TableRow from "./TableRow";

interface TbodyProps {
  body: Array<object>;
  showCheckbox?: boolean;
  hasMenu?: boolean;
  isDeletable?: boolean;
  handleDelete?: Function;
  handleSelect?: Function;
  selectKey?: string;
  showNA?: boolean;
  menuList?: Array<{ text: string; handleAction?: Function; action?: any }>;
}

const Tbody = (props: TbodyProps) => {
  const {
    body,
    showCheckbox,
    hasMenu,
    isDeletable,
    handleDelete,
    selectKey,
    handleSelect,
    showNA,
    menuList,
  } = props;
  const [selectedItem, selectItem] = useState<any>();

  return (
    <tbody>
      {body.map((item: any, key) => (
        <TableRow
          item={item}
          key={key}
          showCheckbox={showCheckbox}
          hasMenu={hasMenu}
          isDeletable={isDeletable}
          handleDelete={handleDelete}
          {...{ selectKey, handleSelect }}
          handleSelect={(data: any) => {
            handleSelect?.(data);
            selectItem(key);
          }}
          isSelected={key === selectedItem}
          showNA={showNA}
          menuList={menuList}
        />
      ))}
    </tbody>
  );
};

export default Tbody;
