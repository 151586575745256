import { useEffect, useState } from "react";
import { MetricDashboardPropsTypes } from "components/Main/Metric/DashboardD/DashboardD.types";
import {
  BarChart,
  Bar,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Select from "components/ui/Select";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";

const DashboardInfrastructure = (props: MetricDashboardPropsTypes) => {
  const { projects, loading, setLoading, project, setProject } = props;
  const [metrics, setMetrics] = useState([])

  const GetCodeMetrics = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.getCodeMetrics(project?.value || project),
    })
      .then((resp) => {
        setLoading(false);
        const { data } = resp?.data || {};
        setMetrics(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (project) {
      GetCodeMetrics();
    }
  }, [project]);
  return (
    <section className="dashboard__chart">
      <div className="dashboard__chart__header">
        <h3>Code Repository Metric</h3>{" "}
        <div className="d-flex p-y-1 create-pc-modal-form__inputs">
          <Select
            value={project}
            onChange={(e: any) => setProject(e)}
            options={[
              {
                label: loading ? "Please wait" : "Choose Project",
                value: "",
              },
              ...projects?.map(({ project_name, id }: any) => {
                return {
                  value: JSON.stringify(id),
                  label: project_name,
                };
              }),
            ]}
          />
        </div>
      </div>
      <div className="repository-metrics__chart">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={200}
            data={metrics}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="prs" fill="#008FE3" />
            <Bar dataKey="commits" fill="#39BB59" />
            <Bar dataKey="deployments" fill="#F79256" />
            {/* <Bar dataKey="lines" fill="#002C61" /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default DashboardInfrastructure;
