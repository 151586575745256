import { Button } from "components/ui";

import Input from "components/ui/Input";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { useDispatch } from "react-redux";

import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import { updateAuthInfo } from "store/auth/actions";
import { setAuthToken } from "storage";

import "./LoginForm.scss";

const LoginForm = () => {
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(true);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const login = (data: any) => {
    setLoading(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.login,
      data,
    })
      .then((resp) => {
        setLoading(false);
        reset();

        toastMessage("Login Successful");
        const { token, expiry, user } = resp.data.data;
        setAuthToken(token);
        const isUserOnboarded = user?.employee?.company?.is_onboarded;
        const role = user?.employee?.role;
        dispatch<any>(updateAuthInfo({ token, expiry, user }));
        if (user?.mfa) {
          navigate("/auth/two-fa");
        } else {
          if (isUserOnboarded) {
            if (role === "admin") {
              navigate("/dashboard");
            } else {
              navigate("/project/overview");
            }
          } else {
            navigate("/auth/onboarding");
          }
        }
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        console.log({ err });
        if (
          err?.response?.data?.message ===
          "Please check your mail to verify your account."
        ) {
          navigate("/auth/verify-email");
        } else {
          toastMessage(errMsg, true);
        }
      });
  };

  const onSubmit = (data: any) => {
    login(data);
  };

  return (
    <section className="login-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="login-form__inputs">
          <Input
            label="Email Address"
            name="email"
            hasError={errors?.email ? true : false}
            register={register}
            required={true}
          />
          <Input
            label="Password"
            type={toggle ? "password" : "text"}
            name="password"
            hasError={errors?.password ? true : false}
            register={register}
            required={true}
            toggle={toggle}
            handleToggle={handleToggle}
            passwordIcon
          />
        </div>
        <Link to="/auth/forgot-password">
          <span className="primary-color">Forgot password</span>
        </Link>
        <Button
          text="Login to your account"
          isLoading={loading}
          type="submit"
          isLoadingText="Please wait..."
          className="login-button"
        />
      </form>
    </section>
  );
};

export default LoginForm;
