import React, { useState } from "react";
import { ModalProps } from "../Modal/modal.d";
import "./TooltipModal.scss";
// import Icon from "../../Icon";
// import Button from "../Button/index";
import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Card, Fade, Typography } from "@mui/material";
import Polygon from "../../../assets/general/Ploygon.svg";
import SidePolygon from "../../../assets/general/SidePolygon.svg";
import { ReactSVG } from "react-svg";

const ProjectTootltipModal = (props: ModalProps) => {
  const { isOpen, onClose, sx } = props;
  const [page, setPage] = useState(1);

  const modalDetails = [
    {
      id: 1,
      text: "Create as many projects as needed, view their details, milestones, and tasks",
    },
    {
      id: 2,
      text: "Search for project based on project name",
    },
    // {
    //   id: 5,
    //   text: "Modify the details of a project",
    // },
    // {
    //   id: 6,
    //   text: "Create a project milestone",
    // },
    // {
    //   id: 7,
    //   text: "A friendly AI designed to assist, guide, and enhance your interaction.",
    // },
  ];

  return (
    <>
      <Modal open={isOpen} closeAfterTransition>
        <Fade in={isOpen}>
          <Box>
            {modalDetails?.map((detail) => (
              <Box key={detail?.id}>
                {detail?.id === page && (
                  <>
                    <Box
                      sx={{
                        position: "absolute",
                        top:
                          page === 1
                            ? "15rem"
                            : "11rem",
                        left:
                          page === 1
                            ? "22rem"
                            : "85rem",
                        color: "#FFFFFF",
                        width: "40px",
                      }}
                    >
                      <ReactSVG
                        src={detail?.id === 7 ? SidePolygon : Polygon}
                        className="polygon"
                      />
                    </Box>
                    <Card
                      elevation={10}
                      sx={{
                        position: "absolute",
                        top:
                          page === 1
                            ? "16rem"
                            : "12rem",
                        left:
                          page === 1
                            ? "16rem"
                            : "83rem",
                        width: "250px",
                        p: 2,
                      }}
                    >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          textAlign: "left",
                          color: "#1976D2",
                        }}
                      >
                        Project Tab
                      </Typography>

                      <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
                        {detail?.id}/{modalDetails?.length}
                      </Typography>
                    </Box>
                      <Typography sx={{ fontSize: "14px" }}>
                        {detail?.text}
                      </Typography>

                      <Box
                        sx={{
                          display: "flex",
                        justifyContent: page > 1 ? "space-between" : "right",
                        alignItems: "center",
                          mt: 3,
                        }}
                      >
                        {page > 1 && (
                          <Button
                            variant="outlined"
                            sx={{ textTransform: "inherit", p: 0.5 }}
                            onClick={() => setPage(page - 1)}
                          >
                            Prev
                          </Button>
                        )}

                        <Button
                          variant="contained"
                          sx={{ textTransform: "inherit", p: 0.5 }}
                          onClick={() => {
                            if (page < modalDetails?.length) {
                              setPage(page + 1);
                            } else {
                              localStorage.setItem(
                                "project_overview",
                                "project_overview"
                              );
                              onClose();
                            }
                          }}
                        >
                          {page === modalDetails?.length ? "Finish" : "Next"}
                        </Button>
                      </Box>
                    </Card>
                  </>
                )}
              </Box>
            ))}
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default ProjectTootltipModal;
