import ErrorView from "components/ui/ErrorView";
import AdminKPIsCards from "./AdminKPIsCards/AdminKPIsCards";
import AdminKPIsTable from "./AdminKPIsTable/AdminKPIsTable";
import EmptyView from "components/ui/EmptyView";
import Loader from "components/ui/Loader";
import { useEffect, useState } from "react";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import AdminCreateKPIForm from "./AdminKPIsCards/AdminCreateKPIForm/AdminCreateKPIForm";
import AdminCreateIndividualKPIForm from "./AdminKPIsCards/AdminCreateKPIForm/AdminCreateIndividualKPIForm";
import SideModal from "components/ui/Modal/SideModal/SideModal";
import { useAppContext } from "AppContext";
import { useLocation } from "react-router-dom";
import AdminViewTeamKPI from "./AdminViewTeamKPI/AdminViewTeamKPI";
// import AdminDeleteKPI from "./AdminKPIsCards/AdminDeleteKPI/AdminDeleteKPI";
import { useSelector } from "react-redux";
import { MODAL_ACTIONS } from "components/Main/enums";
import AdminViewTeamKPIModal from "./AdminViewTeamKPI/AdminViewKPIModal/AdminViewTeamKPIModal";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";

const { LOADING, IDLE, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;

const AdminKPIs = () => {
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const [result, setResult] = useState([]);
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(0);

  const [showModal, toggleModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [selectedTeam, selectTeam] = useState({});
  const [selectedKpi, selectKpi] = useState({});

  const location = useLocation();
  const { pathname } = location || {};

  const teamKpiId = pathname?.substring?.(13);

  const { VIEW_ACTION } = MODAL_ACTIONS;

  const { teamSelected_ } = useSelector((state: any) => state.filter);

  const { isModalOpen, closeModal, search } = useAppContext();

  const handleClose = () => {
    setModalAction("");
    selectTeam({});
    toggleModal(false);
    closeModal();
  };

  const getEmployeeKPI = (pageNo?: number) => {
    setStatus(LOADING);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employeeKpiTable,
      params: {
        team: teamSelected_?.id,
        q: search,
        page: pageNo,
      },
    })
      .then((resp) => {
        const { results, count, per_page } = resp.data?.data || {};
        setResult(results);
        setCount(count);
        setPerPage(per_page);
        setStatus(results?.length > 0 ? DATAMODE : NULLMODE);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };

  const getKPIsData = (pageNo?: number) => {
    setStatus(LOADING);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.teamKpiTable,
      params: {
        team: teamKpiId ? teamSelected_?.id : undefined,
        q: search,
        page: pageNo,
      },
    })
      .then((resp) => {
        const { results, count, per_page } = resp.data?.data || {};
        setResult(results);
        setCount(count);
        setPerPage(per_page);
        setStatus(results?.length > 0 ? DATAMODE : NULLMODE);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };
  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getKPIsData} message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            <AdminKPIsCards />
            {teamKpiId && teamSelected_?.id ? (
              <AdminViewTeamKPI
                data={result}
                toggleModal={toggleModal}
                selectTeam={selectTeam}
                selectKpi={selectKpi}
                selectedKpi={selectedKpi}
                teamName={teamSelected_?.title}
                count={count}
                page={page}
                setPage={setPage}
                per_page={per_page}
              />
            ) : (
              <AdminKPIsTable
                data={result}
                toggleModal={toggleModal}
                selectTeam={selectTeam}
                setModalAction={setModalAction}
                count={count}
                page={page}
                setPage={setPage}
                per_page={per_page}
              />
            )}
          </>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    teamKpiId ? getEmployeeKPI() : getKPIsData();
    // eslint-disable-next-line
  }, [teamKpiId, search, page]);

  return (
    <>
      {renderBasedOnStage()}
      {teamKpiId ? (
        <SideModal
          title="Create Individual KPI"
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          <AdminCreateIndividualKPIForm
            onClose={closeModal}
            handleSuccess={() => getEmployeeKPI()}
            teamId={teamSelected_?.id}
          />
        </SideModal>
      ) : (
        <DefaultModal
          title="Create KPI"
          isOpen={isModalOpen}
          onClose={closeModal}
          sx={{ width: 600, height: 600 }}
        >
          <AdminCreateKPIForm
            onClose={closeModal}
            handleSuccess={() => getKPIsData()}
          />
        </DefaultModal>
      )}

      <AdminViewTeamKPIModal
        modalAction={isModalOpen ? VIEW_ACTION : modalAction}
        isOpen={showModal}
        onClose={handleClose}
        selectedTeam={selectedTeam}
      />

      {/* <AdminDeleteKPI
        {...{
          showModal,
          onClose: () => toggleModal(false),
          selectedTeam,
          selectedKpi
        }}
        handleSuccess={() => getKPIsData()}
      /> */}
    </>
  );
};

export default AdminKPIs;
