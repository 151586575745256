import { Box, Button, Typography } from "@mui/material";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";

const ProjectMileStone = (props: {
  handleSuccess?: Function;
  onClose: Function;
  selectedProject: any;
  isOpen: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { handleSuccess, onClose, selectedProject, isOpen } = props;

  const deleteProject = () => {
    setLoading(true);
    API({
      method: httpVerbs?.DELETE,
      url: `${apiUrls?.projects}${selectedProject?.id}/`,
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Project deleted successfully.");
        handleSuccess?.();
        onClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      title="Delete this project?"
      buttonText="Yes"
    >
      <Typography sx={{ textAlign: "center" }}>
        Are you sure you want to delete{" "}
        <b>{selectedProject?.project_name}</b> Project?
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Button
          variant="outlined"
          sx={{ textTransform: "inherit", color: "#008ee2" }}
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{
            ml: 2,
            width: loading ? "130px" : "100px",
            textTransform: "inherit",
            backgroundColor: "#008ee2",
            "&:hover": {
              backgroundColor: "#008ee2",
            },
          }}
          onClick={deleteProject}
        >
          {loading ? "Please wait..." : "Yes"}
        </Button>
      </Box>
    </DefaultModal>
  );
};

export default ProjectMileStone;
