import React from "react";
// import { useTheme } from '@mui/material/styles';
import { TableCell } from "@mui/material";

const AppTableCell = (props: any) => {
  const { children, align, padding, sx } = props;
  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        border: "none",
        fontSize: 12,
        padding: padding,
        ...sx,
      }}
      align={align}
    >
      {children}
    </TableCell>
  );
};

export default AppTableCell;
