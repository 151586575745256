import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import CreateBlockerForm from "./CreateBlocker/CreateBlockerForm";

const CreateBlocker = (props: {
  onClose: Function;
  handleSuccess?: Function;
  selectedBlocker: any;
  isEdit?: boolean;
  selectedTask?: any;
  isOpen: boolean;
  dataList: any;
}) => {
  const {
    onClose,
    selectedBlocker,
    handleSuccess,
    isEdit,
    selectedTask,
    isOpen,
    dataList,
  } = props;

  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const { company } = employee || {};

  const { id: companyId } = company || {};

  const [loading, setLoading] = useState(false);
  const [milestone, setMilestone] = useState(null);
  const [result, setResult] = useState([]);
  const [status, setStatus] = useState(false);
  const [blocked_by, setBlockedBy] = useState<any>(null);
  const [status_, setStatus_] = useState<any>(null);
  const [task, setTask] = useState<any>(null);
  const [error, setError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm();

  const handleClose = () => {
    reset();
    onClose?.();
    setMilestone(null);
  };

  const getTeamsData = () => {
    setStatus(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employee_names,
    })
      .then((resp) => {
        setStatus(false);
        setResult(resp.data?.data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
      });
  };

  useEffect(() => {
    getTeamsData();
    // eslint-disable-next-line
  }, []);

  const createProjectTask = async (data: any) => {
    if (blocked_by === null) {
      setError(true);
    } else if (status_ === null) {
      setError(true);
    } else if (task === null) {
      setError(true);
    } else {
      setLoading(true);
      API({
        method: httpVerbs?.POST,
        url: apiUrls?.taskBlockers,
        data: {
          ...data,
          task: task?.value,
          blocked_by: blocked_by?.value,
          status: status_?.value,
          company: companyId,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Blocker created successfully.");
          handleSuccess?.();
          handleClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const updateTask = (data: any) => {
    if (blocked_by === null) {
      setError(true);
    } else if (status_ === null) {
      setError(true);
    } else if (task === null) {
      setError(true);
    } else {
      setLoading(true);
      API({
        method: httpVerbs?.PATCH,
        url: `${apiUrls?.taskBlockers}${selectedBlocker?.id}/`,
        data: {
          ...data,
          blocked_by: blocked_by?.value,
          status: status_?.value,
          company: companyId,
          task: task?.value,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Task added successfully.");
          handleSuccess?.();
          handleClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const onSubmit = (data: any) => {
    isEdit ? updateTask(data) : createProjectTask(data);
  };

  const statusOption = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Resolved",
      value: "iresolved",
    },
  ];

  useEffect(() => {
    if (Object.keys(selectedBlocker)?.length > 0) {
      Object.keys(selectedBlocker)?.map((tk: any) => {
        return setValue(tk, selectedBlocker[tk]);
      });
      const { task, blocked_by, status } = selectedBlocker;
      const findTask = dataList?.find((data: any) => data?.task_name === task);
      const findStatus = statusOption?.find(
        (data: any) => data?.value === status
      );
      const findBlocker: any = result?.find(
        (data_: any) => data_?.name === blocked_by
      );
      setTask({
        label: findTask?.task_name,
        value: findTask?.id,
      });
      setBlockedBy({
        label: findBlocker?.name,
        value: findBlocker?.id,
      });

      setStatus_({
        label: findStatus?.label,
        value: findStatus?.value,
      });
    }
    // eslint-disable-next-line
  }, [selectedBlocker, loading, result]);

  const filtered_users = result?.filter(
    (user: any) => user?.id !== employee?.id
  );
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      title={isEdit ? "Update Blocker" : "Create Blocker"}
      // useCustomHeader
      sx={{ width: 500 }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <CustomModalHeader
          onClose={handleClose}
          title={isEdit ? "Update Task" : "Add New Task"}
          saveBtnIsLoading={loading}
          disabled={
            (find_milestone as any)?.points_left === 0 && !check_Points
              ? true
              : false
          }
        /> */}

        <CreateBlockerForm
          register={register}
          errors={errors}
          milestone={milestone}
          selectedTask={selectedTask}
          isEdit={isEdit}
          loading={loading}
          selectedProject={selectedBlocker}
          dataList={dataList}
          result={filtered_users}
          status={status}
          blocked_by={blocked_by}
          setBlockedBy={setBlockedBy}
          status_={status_}
          setStatus_={setStatus_}
          error={error}
          task={task}
          setTask={setTask}
        />
      </form>
    </DefaultModal>
  );
};

export default CreateBlocker;
