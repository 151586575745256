import { Button } from "components/ui";
import IndicatorInput from "../IndicatorInput/IndicatorInput";
import assets from "assets";
import { useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";

const indicators = {
  AUTOMATIC: "AUTOMATIC",
  MANUAL: "MANUAL",
};

const { MANUAL } = indicators;

const AdminCKPIFormInputs = (props: {
  indicator: string;
  loading: boolean;
  reset: Function;
  onClose?: Function;
  handleSubmit?: Function;
  exitingKPIs: Array<any>;
  newKPIs: Array<any>;
  updateKPI?: Function;
  addNewKPI?: Function;
  removeNewKPI?: any;
  handleNewKPI?: Function;
  selectedTeam?: any;
}) => {
  const {
    loading,
    reset,
    onClose,
    indicator,
    handleSubmit,
    exitingKPIs,
    newKPIs,
    updateKPI,
    addNewKPI,
    handleNewKPI,
    removeNewKPI,
    selectedTeam,
  } = props;

  const [deleteLoading, setDeleteLoading] = useState(false);

  const isIndicatorAutomatic = indicator !== MANUAL;

  const deleteKPI = (kpiId: any, kpiData: any, trackingIndex: any) => {
    setDeleteLoading(true);
    API({
      method: httpVerbs?.DELETE,
      url: `${apiUrls?.teamKpi}${kpiId}/`,
      data: {},
    })
      .then((resp) => {
        setDeleteLoading(false);
        toastMessage("KPI deleted successfully.");
        updateKPI?.(kpiData, true, kpiId === undefined, trackingIndex);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setDeleteLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const handleDeleteKPI = (kpiData: any) => {
    const { id, trackingIndex } = kpiData || {};
    !id ? removeNewKPI(kpiData) : updateKPI?.(kpiData, true, trackingIndex);
  };
  return (
    <>
      <section className="admin-create-kpi-form__form">
        <div className="admin-create-kpi-form__form-container">
          <div className="admin-create-kpi-form__form-header">
            <span>Indicators</span>
            {isIndicatorAutomatic ? (
              <span>Expected Score</span>
            ) : (
              <span>KPI completed</span>
            )}
            {isIndicatorAutomatic && <span>Status</span>}
            {!isIndicatorAutomatic && <span>Delete</span>}
          </div>

          <div className="admin-create-kpi-form__form-body">
            {[...exitingKPIs, ...newKPIs]
              ?.filter?.(
                ({ is_automatic }) => is_automatic === isIndicatorAutomatic
              )
              ?.map((kpiData, key) => (
                <IndicatorInput
                  key={key}
                  index={key}
                  kpiData={kpiData}
                  updateKPI={updateKPI}
                  addNewKPI={addNewKPI}
                  handleDeleteKPI={handleDeleteKPI}
                  handleNewKPI={handleNewKPI}
                  canChangeStatus={isIndicatorAutomatic}
                  isIndicatorAutomatic={isIndicatorAutomatic}
                />
              ))}
          </div>
        </div>

        {!isIndicatorAutomatic && (
          <div className="admin-create-kpi-form__form-manual-btn">
            <div></div>

            <Button
              text="Add row"
              className="admin-create-kpi-form__button"
              onClick={addNewKPI}
              icon={assets.icons.add}
              isLoading={deleteLoading}
              disabled={!selectedTeam}
            />
          </div>
        )}

        <div className="admin-create-kpi-form__form-buttons">
          <Button
            text="Cancel"
            className="cancel"
            onClick={() => {
              onClose?.();
              reset();
            }}
          />
          <Button
            text="Submit"
            type="submit"
            isLoading={loading}
            onClick={handleSubmit}
          />
        </div>
      </section>
    </>
  );
};

export default AdminCKPIFormInputs;
