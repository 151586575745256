import auth from "./auth/Frame.svg";
import authLogo from "./auth/logo main 1.svg";
import uploadAvatar from "./auth/upload-avatar.svg";
import avatar from "./layout/avatar.svg";
import logout from "./layout/ic_round-logout.svg";
import admin from "./layout/admin.svg";
import project from "./layout/project.svg";
import help from "./layout/help.svg";
import metrics from "./layout/metrics.svg";
import report from "./layout/report.svg";
import settings from "./layout/settings.svg";
import arrowUp from "./layout/arrow-up.svg";
import arrowDown from "./layout/arrow-down.svg";
import filter from "./page-header/add.svg";
import search from "./page-header/search.svg";
import add from "./page-header/filter.svg";
import thrash from "./table/delete.svg";
import arrowRight from "./table/arrow-right.svg";
import arrowLeft from "./table/arrow-left.svg";
import menu from "./statscard/menu.svg";
import graph from "./statscard/graph.svg";
import copy from "./general/copy.svg";
import edit from "./general/edit.svg";
import projectIcon from "./general/project.svg";
import ProjectIcon_ from "./general/ProjectIcon.svg";
import Person from "./general/person.svg";
import CostIcon from "./general/CostIcon.svg";
import TeamsIcon from "./general/TeamsIcon.svg";
import KpiIcon from "./general/KpiIcon.svg";
import EmployeeIcon from "./general/EmployeesIcon.svg";
import download from "./layout/download.svg";
import github from "./general/github.svg";
import helpSupport from "./general/helpSupport.svg";
import twitter from "./general/twitter.svg";
import facebook from "./general/facebook.svg";
import instagram from "./general/instagram.svg";
import linkedin from "./general/linkedin.svg";
import cancel from "./general/cancel.svg";
import gitlab from "./general/gitlab.svg";
import check from "./general/mdi_tick.svg";
import deleteOutline from "./general/delete-outline.svg";
import deleteRed from "./general/delete-red.svg";
import fileUpload from "./general/FileUpload.svg";
import save from "./general/save.svg";
import tasks from "./general/tasks.svg";
import apple from "./auth/apple.svg";
import google from "./auth/google.svg";
import gitlab2 from "./auth/gitlab.svg";
import dashboard from "./layout/dashboard.svg";
import passwordOpen from "./auth/PasswordOpen.svg";
import passwordClose from "./auth/PasswordClose.svg";
import userGuide from "./general/userGuide.svg";
import VisaLogo from "./general/visa.svg";
import MasterCardLogo from "./general/mastercard.svg";
import VerveLogo from "./general/verve.svg";
import ActiveCheckCircle from "./general/ActiveCheckCircle.svg";
import InactiveCircle from "./general/InactiveCircle.svg";
import AiLogo from "./general/Bot.png";
import BotLogo from "./general/Bot.gif";
import GroupeLogo from "./general/Group.svg";
import CreditCard from "./general/credit-card.png";
import ClockinIcon from "./general/ClockIn.svg";
import ClockoutIcon from "./general/ClockOut.svg";
import GitIcon from "./table/GitIcon.svg";
import GitBlackIcon from "./general/GitBlackIcon.svg";

const assets = {
  images: {
    auth,
    authLogo,
    helpSupport,
  },

  icons: {
    uploadAvatar,
    avatar,
    logout,
    admin,
    project,
    help,
    metrics,
    report,
    settings,
    arrowUp,
    arrowDown,
    add,
    search,
    filter,
    thrash,
    arrowLeft,
    arrowRight,
    menu,
    graph,
    copy,
    edit,
    projectIcon,
    download,
    github,
    twitter,
    facebook,
    instagram,
    linkedin,
    cancel,
    gitlab,
    save,
    tasks,
    apple,
    google,
    gitlab2,
    dashboard,
    passwordOpen,
    passwordClose,
    check,
    deleteOutline,
    deleteRed,
    fileUpload,
    ProjectIcon_,
    TeamsIcon,
    KpiIcon,
    EmployeeIcon,
    CostIcon,
    Person,
    userGuide,
    MasterCardLogo,
    VisaLogo,
    VerveLogo,
    ActiveCheckCircle,
    InactiveCircle,
    AiLogo,
    GroupeLogo,
    BotLogo,
    CreditCard,
    ClockinIcon,
    ClockoutIcon,
    GitBlackIcon,
    GitIcon,
  },
};

export default assets;
