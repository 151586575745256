/* eslint-disable no-mixed-operators */
// Imports
import { ReactSVG } from "react-svg";
import "./LayoutHeader.scss";
import assets from "assets";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import Icon from "components/ui/Icon";
import { Box, Button, Menu, MenuItem, Tooltip } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";

// Utils
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import { getErrorMessage } from "utils/helper";

// Components
import EmployeeTootltipModal from "components/ui/Modals/EmployeeTooltip";
import TeamsTootltipModal from "components/ui/Modals/TeamsTooltip";
import ProjectTootltipModal from "components/ui/Modals/ProjectTooltipModal";
import KPITooltipModal from "components/ui/Modals/KPITooltipModal";
import DashboardTootltipModal from "components/ui/Modals/DashboardToottipModal";
import ClockingModal from "components/ui/Modals/ClockingModal";
import DashboardGuideModal from "components/ui/Modals/DashboardGuideModal";
import TeamSetupGuideModal from "components/ui/Modals/TeamSetupGuideModal";
import ProjectGuideModal from "components/ui/Modals/ProjectGuideModal";
import SetupMfa from "../modals/SetupMfa";

// import "@fullcalendar/core/main.css";
// import "@fullcalendar/daygrid/main.css";
// import "@fullcalendar/timegrid/main.css";

const LayoutHeader = (
  props: { toggleSidebar: Function; handleOpenCalendar: Function },
  openModals: Function
) => {
  const { toggleSidebar, handleOpenCalendar } = props;
  // Utils
  const navigate = useNavigate();
  const location = useLocation();
  // state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [defaultOpen, setDefaultOpen] = useState(false);
  const [mfaModal, setMfaModal] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const [dataList, setDataList] = useState([]);
  const [clockingModal, setClockingModal] = useState(false);
  const menuRef = useRef(null);

  // constants
  const filtered_task = dataList?.filter((list: any) => list?.state !== "done");
  const get_logged_in = localStorage.getItem("loggedin");
  const get_dashboard = localStorage.getItem("dashboard");
  const get_employee = localStorage.getItem("employee");
  const get_team = localStorage.getItem("teams");
  const get_project_overview = localStorage.getItem("project_overview");
  const get_project_kpi = localStorage.getItem("project_kpi");

  // redux states
  const { user, token } = useSelector((state: any) => state.auth);
  const { email, first_name, last_name, picture, employee } = user || {};

  // Functions
  const handleOpenMenu = (event: any) => {
    setMenuOpen(event?.currentTarget);
  };

  const handleOutsideClick = (event: any) => {
    if (menuRef.current) {
      setMenuOpen(null);
    }
  };
  const openDefaultModal = () => {
    setDefaultOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const closeDefaultModal = () => {
    setDefaultOpen(false);
  };

  const logOut = () => {
    localStorage.clear();
    navigate("/auth/login");
  };

  // Async functions
  useEffect(() => {
    const getProjectTasks = async (page?: number) => {
      API({
        method: httpVerbs?.GET,
        url: apiUrls?.projectTasks,
        headers: {},
      })
        .then((resp) => {
          const { results } = resp?.data?.data || {};
          setDataList(results);
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
        });
    };
    getProjectTasks();
  }, []);

  // Useeffects
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (user?.is_first_login) {
      setIsModalOpen(true);
    }
  }, [user, location.pathname]);

  useEffect(() => {
    if (!token) {
      localStorage.clear();
      navigate("/");
    }
    // eslint-disable-next-line
  }, [token]);

  useEffect(() => {
    if (
      filtered_task?.length > 0 &&
      !user?.is_first_login &&
      user?.employee?.role !== "admin" &&
      !user?.employee?.is_clocked_in
    ) {
      setClockingModal(true);
    }
  }, [get_logged_in, filtered_task]);

  // useEffect(() => {
  //   if (!user?.mfa) {
  //     console.log({ user });
  //     setMfaModal(true);
  //   }
  // }, [user]);

  return (
    <>
      <header className="layout-header">
        <section onClick={() => toggleSidebar()}>
          <ReactSVG src={assets.images.authLogo} />
          <Icon icon="menu" />
        </section>

        <section className="layout-header__mobile-logo">
          <span>
            <ReactSVG src={assets.images.authLogo} />
          </span>
        </section>

        <section className="layout-header__user-box">
          <div className="utils_actions">
            <Button onClick={() => handleOpenCalendar()}>
              <CalendarMonthIcon sx={{ width: 40, height: 40 }} />
            </Button>

            {user.user_type !== "admin" && filtered_task?.length > 0 && (
              <>
                {user?.employee?.is_clocked_in ? (
                  <span className="layout-header__user-box__clockout">
                    <Tooltip title="Clock Out" placement="bottom-end">
                      <div
                        onClick={() => setClockingModal(true)}
                        className="item"
                      >
                        <img
                          src={assets.icons.ClockoutIcon}
                          alt="clockin"
                          className="clock_icon"
                        />
                      </div>
                    </Tooltip>
                  </span>
                ) : (
                  <>
                    <span className="layout-header__user-box__clockin">
                      <Tooltip title="Clock In" placement="bottom-end">
                        <div
                          onClick={() => setClockingModal(true)}
                          className="item"
                        >
                          <img
                            src={assets.icons.ClockinIcon}
                            alt="clockin"
                            className="clock_icon"
                          />
                        </div>
                      </Tooltip>
                    </span>
                  </>
                )}
              </>
            )}
            <>
              {(employee?.role === "admin" ||
                employee?.role === "project_manager") && (
                <>
                  {(location.pathname === "/admin/users/" ||
                    location.pathname === "/dashboard" ||
                    location.pathname === "/admin/teams/" ||
                    location.pathname === "/admin/payroll/" ||
                    location.pathname === "/project/overview") && (
                    <span className="layout-header__user-box__userguide animate-clock">
                      <div className="item" onClick={openDefaultModal}>
                        <div>
                          <Tooltip title="User Guide" placement="bottom-end">
                            <TipsAndUpdatesIcon sx={{ color: "#FFFFFF" }} />
                          </Tooltip>
                        </div>
                      </div>
                    </span>
                  )}
                </>
              )}
            </>
          </div>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              position: "relative",
              cursor: "pointer",
              zIndex: 50000000000,
            }}
            ref={menuRef}
            onClick={handleOpenMenu}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                cursor: "pointer",
                zIndex: 50000000000,
              }}
            >
              <div>
                {picture ? (
                  <Icon src={picture} className="picture" useImg />
                ) : (
                  <ReactSVG
                    src={assets.icons.avatar}
                    className="layout-header__user-box__avatar"
                  />
                )}
              </div>
              <Box sx={{ ml: 2 }}>
                <h3>{first_name + " " + last_name}</h3>
                <p>{email}</p>
              </Box>
            </Box>
            <KeyboardArrowDownIcon />
          </Box>
        </section>
        <Menu
          open={Boolean(menuOpen)}
          anchorEl={menuOpen as any}
          onClose={handleOutsideClick}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          PaperProps={{
            style: {
              width: 180,
            },
          }}
          sx={{ marginTop: "1rem" }}
        >
          {" "}
          <MenuItem
            sx={{
              fontSize: 14,
              fontWeight: 500,
              "&.Mui-focusVisible": {
                background: "none",
              },
            }}
            onClick={() => {
              logOut();
            }}
          >
            <LogoutIcon sx={{ mr: 1 }} /> Logout
          </MenuItem>
        </Menu>
        {location.pathname !== "/settings/mfa" && (
          <SetupMfa isOpen={mfaModal} setMfaModal={setMfaModal} />
        )}
      </header>
      {isModalOpen && (
        <>
          {location.pathname === "/admin/users/" &&
            get_employee !== "employee" && (
              <EmployeeTootltipModal
                isOpen={isModalOpen}
                onClose={closeModal}
              />
            )}
          {location.pathname === "/admin/teams/" && get_team !== "teams" && (
            <TeamsTootltipModal isOpen={isModalOpen} onClose={closeModal} />
          )}
          {location.pathname === "/project/overview" &&
            get_project_overview !== "project_overview" && (
              <ProjectTootltipModal isOpen={isModalOpen} onClose={closeModal} />
            )}
          {location.pathname === "/project/kpis" &&
            get_project_kpi !== "project_kpi" && (
              <KPITooltipModal isOpen={isModalOpen} onClose={closeModal} />
            )}
          {location.pathname === "/dashboard" &&
            get_dashboard !== "dashboard" && (
              <DashboardTootltipModal
                isOpen={isModalOpen}
                onClose={closeModal}
              />
            )}
        </>
      )}
      {/* {defaultOpen && (
        <DefaultTootltipModal
          isOpen={defaultOpen}
          onClose={closeDefaultModal}
        />
      )} */}
      <ClockingModal
        isOpen={clockingModal}
        onClose={() => setClockingModal(false)}
      />
      {defaultOpen && location.pathname === "/dashboard" && (
        <DashboardGuideModal
          open={defaultOpen}
          handleClose={closeDefaultModal}
        />
      )}
      {defaultOpen &&
        (location.pathname === "/admin/users/" ||
          location.pathname === "/admin/teams/" ||
          location.pathname === "/admin/payroll/") && (
          <TeamSetupGuideModal
            open={defaultOpen}
            handleClose={closeDefaultModal}
          />
        )}

      {defaultOpen && location.pathname === "/project/overview" && (
        <ProjectGuideModal open={defaultOpen} handleClose={closeDefaultModal} />
      )}
    </>
  );
};

export default LayoutHeader;
