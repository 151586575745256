import { icons } from "./icons";
import "./Icon.scss";
import { ReactSVG } from "react-svg";

const Icon = (props: {
  icon?: string;
  color?: string;
  className?: string;
  src?: string;
  useImg?: boolean;
}) => {
  const { icon, color, className, src, useImg } = props;
  return (
    <>
      {src ? (
        <div className="custom-icon">
          {useImg ? (
            <img
              src={src}
              className={`custom-icon__img || ${className}`}
              alt="Avatar"
            />
          ) : (
            <ReactSVG
              src={src}
              className={`custom-icon__img || ${className}`}
            />
          )}
        </div>
      ) : (
        <i
          className={`${icons[icon as keyof typeof icons]} || ${className}`}
          aria-hidden="true"
          style={{ color }}
        />
      )}
    </>
  );
};

export default Icon;
