import SettingsRoutes from "components/Main/Settings";
import AdminRoutes from "components/Main/Admin";
import ProjectRoutes from "components/Main/Project";
import MetricsRoutes from "components/Main/Metric";
import DashboardRoutes from "components/Main/Dashboard";
import AuthRoutes from "pages/Auth";
import HelpSupportRoutes from "components/Main/HelpSupport";
import MiddlewareRoutes from "components/middlewares";
// import OtherRoutes from 'components/Others/OtherRoutes'

export const routes = [
  ...AuthRoutes,
  ...DashboardRoutes,
  ...AdminRoutes,
  ...ProjectRoutes,
  ...MetricsRoutes,
  ...SettingsRoutes,
  ...HelpSupportRoutes,
  ...MiddlewareRoutes,
  // ...OtherRoutes,
];
