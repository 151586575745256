import StatsCardsList from "components/common/StatsCardsList/StatsCardsList";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateStatsData } from "store/stats/actions";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";

const AdminKPIsCards = () => {
  const {
    average_kpi,
    average_kpi_score_per_team,
    total_project,
    total_project_cost,
  } = useSelector((state: any) => state.stats);
  const { user } = useSelector((state: any) => state.auth);

  const stats = [
    {
      title: "Total Projects",
      value: total_project || "0",
    },
    {
      title: "Total Projects Cost",
      value: total_project_cost?.toLocaleString() || 0,
    },
    {
      title: "Average KPI",
      value: average_kpi || "0",
      report: `${average_kpi_score_per_team?.toFixed?.(
        2
      )} (Avg. Member per team)`,
      background: "#002C61",
      color: "white",
    },
  ];

  const dispatch = useDispatch();

  const getAdminStats = () => {
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.adminStats,
    })
      .then((resp) => {
        const { data } = resp.data || {};
        dispatch<any>(updateStatsData(data));
      })
      .catch((err) => {});
  };

  useEffect(() => {
    if (
      user?.employee?.role !== "employee" ||
      user?.employee?.role !== "team_lead"
    ) {
      getAdminStats();
    }
    // eslint-disable-next-line
  }, [user?.employee?.role]);

  return <StatsCardsList stats={stats} />;
};

export default AdminKPIsCards;
