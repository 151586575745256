import { ReactNode, useState } from "react";
import "./FilterModal.scss";
import { useAppContext } from "AppContext";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Button } from "components/ui";
import { FILTER_TYPES } from "./enums";
import Select from "components/ui/Select";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedRole, setSelectedTeam } from "store/filter/actions";
import { USER_ROLES } from "components/Main/Settings/enums";

const { TEAM, ROLE } = FILTER_TYPES;

const FilterModal = (props: {
  filterType?: string;
  children?: ReactNode;
  onClear?: Function;
  selectRole?: Function;
  selectTeam?: Function;
}) => {
  const { isFilterOpen, closeFilter } = useAppContext();

  const { children, filterType, onClear, selectRole, selectTeam } = props;

  const { teamList, selectedTeam, selectedRole } = useSelector(
    (state: any) => state.filter
  );

  const dispatch = useDispatch();

  const [selectedFilter, selectFilter] = useState<any>(null);

  const renderFunctionBasedOnFilterType = () => {
    switch (filterType) {
      case TEAM:
        selectTeam?.(selectedFilter?.value);
        return dispatch<any>(setSelectedTeam(selectedFilter?.value));

      case ROLE:
        selectRole?.(selectedFilter?.value);
        return dispatch<any>(setSelectedRole(selectedFilter?.value));

      default:
        return () => {};
    }
  };

  const renderBasedOnFilterType = () => {
    switch (filterType) {
      case TEAM:
        return (
          <Select
            label="Select Team"
            onChange={(val: any) =>
              val !== "NONE" ? selectFilter(val) : selectFilter(null)
            }
            defaultValue={
              Object.keys(selectedTeam || selectedFilter)?.length
                ? JSON.stringify(selectedTeam)
                : ""
            }
            options={[teamList?.map((value: any) => {
                return {
                  value: JSON.stringify(value),
                  label: value?.title,
                };
              }),
            ]}
          />
        );

      case ROLE:
        return (
          <Select
            label="Select Role"
            onChange={(val: any) =>
              val !== "NONE" ? selectFilter?.(val) : selectFilter(null)
            }
            defaultValue={selectedRole}
            options={[...USER_ROLES]}
          />
        );

      default:
        return "";
    }
  };

  const handleClose = () => {
    // selectFilter("");
    closeFilter();
  };

  return (
    <>
      {isFilterOpen ? (
        <Modal
          classNames={{
            root: "filter-modal default-modal",
          }}
          open={isFilterOpen}
          onClose={handleClose}
        >
          <div className="filter-modal__header">
            <span>Filter by</span>
          </div>

          <div className="filter-modal__content">
            {renderBasedOnFilterType()}
            <> {children}</>
          </div>
          <div className="filter-modal__buttons">
            <Button
              text="Clear"
              className="cancel"
              onClick={() => {
                selectFilter(null);
                onClear?.();
                selectRole?.();
                selectTeam?.();
                renderFunctionBasedOnFilterType();
                handleClose();
              }}
            />
            <Button
              text="Apply Filter"
              className="apply"
              //   isLoading={selectedFilter?.length === 0}
              onClick={() => {
                renderFunctionBasedOnFilterType();
                handleClose();
              }}
            />
          </div>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

export default FilterModal;
