import React, { useState } from "react";
import { ModalProps } from "../Modal/modal.d";
import "./TooltipModal.scss";
// import Icon from "../../Icon";
// import Button from "../Button/index";
import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Card, Typography, useTheme } from "@mui/material";
import Polygon from "../../../assets/general/Ploygon.svg";
import SidePolygon from "../../../assets/general/SidePolygon.svg";
import { ReactSVG } from "react-svg";

const DashboardTootltipModal = (props: ModalProps) => {
  const { isOpen, onClose, sx } = props;
  const [page, setPage] = useState(1);
  const theme = useTheme();

  const modalDetails = [
    {
      id: 1,
      text: "Find a comprehensive overview of key data and metrics",
    },
    {
      id: 2,
      text: "A friendly AI designed to assist, guide, and enhance your interaction.",
    },
  ];

  return (
    <>
      <Modal open={isOpen} closeAfterTransition>
        <Box>
          {modalDetails?.map((detail) => (
            <Box key={detail?.id}>
              {detail?.id === page && (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      top: page === 1 ? "10rem" : "51rem",
                      left: page === 1 ? "20rem" : "95.5rem",
                      color: "#FFFFFF",
                      width: "40px",
                      [theme.breakpoints.down("lg")]: {
                        top: page === 1 ? "7.3rem" : "32.5rem",
                        left: page === 1 ? "5rem" : "56rem",
                      },

                    }}
                  >
                    <ReactSVG
                      src={detail?.id === 2 ? SidePolygon : Polygon}
                      className="polygon"
                    />
                  </Box>
                  <Card
                    elevation={10}
                    sx={{
                      position: "absolute",
                      top: page === 1 ? "11rem" : "46rem",
                      left: page === 1 ? "16rem" : "78rem",
                      width: "250px",
                      p: 2,
                      [theme.breakpoints.down("lg")]: {
                        top: page === 1 ? "8rem" : "26rem",
                        left: page === 1 ? "1rem" : "38.5rem",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          textAlign: "left",
                          color: "#1976D2",
                        }}
                      >
                        Dashboard
                      </Typography>

                      <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
                        {detail?.id}/{modalDetails?.length}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {detail.text}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: page > 1 ? "space-between" : "right",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      {page > 1 && (
                        <Button
                          variant="outlined"
                          sx={{ textTransform: "inherit", p: 0.5 }}
                          onClick={() => setPage(page - 1)}
                        >
                          Prev
                        </Button>
                      )}

                      <Button
                        variant="contained"
                        sx={{ textTransform: "inherit", p: 0.5 }}
                        onClick={() => {
                          if (page < modalDetails?.length) {
                            setPage(page + 1);
                          } else {
                            localStorage.setItem("dashboard", "dashboard");
                            onClose();
                          }
                        }}
                      >
                        {page === modalDetails?.length ? "Finish" : "Next"}
                      </Button>
                    </Box>
                  </Card>
                </>
              )}
            </Box>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default DashboardTootltipModal;
