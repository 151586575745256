import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";
import SideModal from "components/ui/Modal/SideModal/SideModal";
import AdminVKpiModalCards from "./AdminVKpiModalCards/AdminVKpiModalCards";
import AdminVKpiPE from "./AdminVKpiPE/AdminVKpiPE";
import AdminViewKpiBreakdown from "./AdminViewKpiBreakdown/AdminViewKpiBreakdown";
import AdminViewKpiTeamActivity from "./AdminViewKpiTeamActivity/AdminViewKpiTeamActivity";
import AdminViewKpiTable from "./AdminViewKpiTable/AdminViewKpiTable";
import { useEffect, useState } from "react";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import Loader from "components/ui/Loader";
import ErrorView from "components/ui/ErrorView";
import EmptyView from "components/ui/EmptyView";

const { LOADING, IDLE, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;

const AdminViewKPIModal = (props: {
  isOpen: boolean;
  selectedKpi: any;
  onClose: Function;
  modalAction?: String;
}) => {
  const { isOpen, selectedKpi, onClose } = props;
  const [result, setResult] = useState([]);
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");

  const getTeamMemberKpi = () => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: `${apiUrls?.individualKpi}${selectedKpi?.id}/`,
      params: {},
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const { data } = resp.data || {};
        setResult(data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };

  useEffect(() => {
    if (selectedKpi?.id) {
      getTeamMemberKpi();
    }
    // eslint-disable-next-line
  }, [selectedKpi]);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return <Loader />;
      case ERROR:
        return <ErrorView message={errorMessage} />;
      case NULLMODE:
        return <EmptyView message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            <AdminVKpiModalCards {...result} />
            <AdminVKpiPE {...result} selectedKpi={selectedKpi} />
            <AdminViewKpiBreakdown {...result} />
            <AdminViewKpiTeamActivity {...result} />
            <AdminViewKpiTable {...result} selectedKpi={selectedKpi} />
          </>
        );

      default:
        return "";
    }
  };

  return (
    <SideModal
      title="Personal KPI"
      isOpen={isOpen}
      onClose={onClose}
      useCustomHeader
    >
      <CustomModalHeader
        onClose={onClose}
        title={"Personal KPI"}
        //   saveBtnIsLoading={loading}
      />
      {renderBasedOnStatus()}
    </SideModal>
  );
};

export default AdminViewKPIModal;
