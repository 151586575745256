export const TASK_PRIORITIES = [
  {
    label: "High",
    value: "high",
  },
  {
    label: "Medium",
    value: "medium",
  },
  {
    label: "Low",
    value: "low",
  },
];

export const TASK_POINTS = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  {
    label: 3,
    value: 3,
  },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
];

export const TASK_STATUS = [
  {
    label: "New",
    value: "new",
  },
  { label: "Assigned", value: "assigned" },
  { label: "In progress", value: "in progresss" },
  { label: "In review", value: "in review" },
  { label: "Merged", value: "merged" },
  { label: "Done", value: "done" },
];
