import DashboardD from "./DashboardD/DashboardD";
import Risks from "./Risks/Risks";
import Statistics from "./Statistics/Statistics";
import TeamDetails from "./TeamDetails/TeamDetails";

const MetricsRoutes = [
  {
    title: "Dashboard",
    component: <DashboardD />,
    route: "/metrics/overview",
  },
  {
    title: "Team Details",
    component: <TeamDetails />,
    route: "/metrics/team-details",
  },
  {
    title: "Risks",
    component: <Risks />,
    route: "/metrics/risks",
  },
  {
    title: "Statistics",
    component: <Statistics />,
    route: "/metrics/statistics",
  },
];

export default MetricsRoutes;
