import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

const TimePickers = (props:{
  value: any;
  onChange: any;
  label: string
}) => {

  const {
   value,
   onChange,
   label
  } = props;
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker label={label} value={value} onChange={onChange} />
    </LocalizationProvider>
  );
};

export default TimePickers;
