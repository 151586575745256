import Input from "components/ui/Input";
import CustomSelect from "components/ui/Select/CustomSelect";

const CreateProject = (props: {
  register: Function;
  errors: any;
  isEdit?: boolean;
  employee: any;
  setEmployee: Function;
  selectedProject: any;
  setValue: Function;
  project_employees: any;
  loading: boolean;
  getValues: Function;
}) => {
  const {
    register,
    errors,
    // employee,
    // setEmployee,
    // project_employees,
    // loading,
    selectedProject,
    isEdit,
    getValues,
  } = props;
  return (
    <div className="p-y-3">
      <div className="p-y-1">
        <Input
          label="Milestone Name"
          placeholder="Text"
          name="indicator"
          register={register}
          hasError={errors?.indicator}
          required
        />
      </div>
      <div className=" p-y-1">
        <Input
          label="Score"
          placeholder="Score in %"
          name="score"
          register={register}
          hasError={errors?.score}
          required
          minValue={0}
          maxValue={100}
          type="number"
        />
      </div>
      <div className=" p-y-1">
        <Input
          label="Milestone Points"
          placeholder="Milestone Points"
          name="points"
          register={register}
          hasError={errors?.points}
          required
          type="number"
          maxValue={
            isEdit
              ? selectedProject?.points_left + getValues("points")
              : selectedProject?.points_left
          }
          minValue={1}
          maxLength={
            isEdit
              ? (selectedProject?.points_left + getValues("points"))?.length
              : selectedProject?.points_left?.length
          }
        />
      </div>
      {selectedProject?.cost_left > 0 && (
        <div className="d-flex p-y-1 create-pc-modal-form__inputs">
          <Input
            type="number"
            label="Milestone Cost"
            name="cost"
            register={register}
            hasError={errors?.cost}
            required={selectedProject?.cost_left > 0}
            minValue={selectedProject?.cost_left === 0 ? 0 : 1}
            maxValue={
              isEdit
                ? selectedProject?.cost_left + getValues("cost")
                : selectedProject?.cost_left
            }
            maxLength={
              isEdit
                ? (selectedProject?.cost_left + getValues("cost"))?.length
                : selectedProject?.cost_left?.length
            }
          />
        </div>
      )}
      {/* <div className="p-y-1">
        <CustomSelect
          name="employee"
          label="Select Employee"
          register={register}
          hasError={errors?.employee}
          isDisabled={loading}
          value={employee}
          onChange={setEmployee}
          errorMsg={"Select atleast an employee"}
          options={[
            {
              label: loading ? "Please wait" : "Select employees",
              value: "",
            },
            ...project_employees?.map(({ full_name, id }: any) => ({
              value: id,
              label: full_name,
            })),
          ]}
          required
        />
      </div> */}
    </div>
  );
};

export default CreateProject;
