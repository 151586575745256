import { ReactSVG } from "react-svg";
import "./PerfEffectiveness.scss";
import assets from "assets";
import { Cell, Pie, PieChart } from "recharts";
import EmptyView from "components/ui/EmptyView";

const COLORS = ["#F8F8FC", "#01058A", "#002BB4"];

const PerfEffectiveness = (props: any) => {
  const { effective_score, efficiency_score, performance_score } = props || {};
  const data = [
    {
      name: "Effective Score",
      value: effective_score,
    },
    {
      name: "Efficiency Score",
      value: efficiency_score,
    },
    {
      name: "Performance Score",
      value: performance_score,
    },
  ];

  const sumTotal = effective_score + efficiency_score + performance_score;
  return (
    <section className="perf-effectiveness">
      <h3>Performance and Effectiveness</h3>
      <div className="perf-effectiveness__container">
        <div>
          {sumTotal > 0 ? (
            <PieChart width={90} height={90}>
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                stroke="none"
                innerRadius={25}
                outerRadius={45}
                fill="#F79256"
              >
                {data?.map?.((entry, index) => (
                  <Cell key={index} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
            </PieChart>
          ) : (
            <EmptyView message="No Chart Data Available" />
          )}
        </div>
        <div className="perf-effectiveness__container__right">
          <div className="perf-effectiveness__perf-card">
            <h6>Performance & Effectiveness</h6>
            <div className="perf-effectiveness__perf-card__items">
              <div>
                <h5>{performance_score}%</h5>
                <section>
                  <span></span>
                  <p>Performance</p>
                </section>
              </div>
              <div>
                <h5>{effective_score}%</h5>
                <section>
                  <span></span>
                  <p>Effectiveness</p>
                </section>
              </div>
            </div>
          </div>

          <div className="perf-effectiveness__bottom">
            <div className="icon">
              <ReactSVG src={assets.icons.graph} />
            </div>
            <div>
              <h4>Efficiency</h4>
              <section>
                <span className="days">{efficiency_score}</span>
                {/* <span className="ratings">
                  <Rating rateValue={efficiency_score} />
                </span> */}
              </section>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PerfEffectiveness;
