import Input from "components/ui/Input";
import { Button } from "components/ui";
import "../integration.scss";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import { useState } from "react";
import assets from "assets";
import { ReactSVG } from "react-svg";
import Loader from "components/ui/Loader";

const ConnectIntegration = (props: {
  register: Function;
  errors: any;
  loading: boolean;
  integration: any;
  setServiceAccount: Function;
}) => {
  const { register, errors, loading, integration, setServiceAccount } = props;
  const [img, setImg] = useState("");
  const [loading_, setLoading_] = useState(false);

  const UploadFile = (formData: any) => {
    setLoading_(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.uploadIntegrationFile,
      data: formData,
    })
      .then((resp) => {
        setLoading_(false);
        setServiceAccount(resp?.data?.data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg, true);
      });
  };

  // CONVERT IMAGE TO BASE 64
  const convertedBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const filReader = new FileReader();
      filReader.readAsDataURL(file);
      filReader.onload = () => {
        resolve(filReader.result);
      };
      filReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (e: any) => {
    const formData = new FormData();
    const file = e.target.files[0];
    if (file && file.type.includes("image")) {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result as any);
      };
      reader.readAsDataURL(file);
      convertedBase64(file).then(() => {});
    }
    formData.append("file_upload", e.target.files?.[0]);
    formData.append("integration_name", integration?.name);
    UploadFile(formData);
  };

  const dragEnter = () => {
    const target = document.getElementById("drop-zone") as any;
    target.style.borderColor = "#C4C4C4";
    target.style.backgroundColor = "#A58980";
  };

  const dragLeave = () => {
    const target = document.getElementById("drop-zone") as any;
    target.style.borderColor = "#A58980";
    target.style.backgroundColor = "transparent";
  };

  const dragOver = (e: any) => {
    e.preventDefault();
  };
  const dragDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.includes("image")) {
      const reader = new FileReader();
      reader.onload = () => {
        setImg(reader.result as any);
      };
      reader.readAsDataURL(file);
      convertedBase64(file).then(() => {});
    }
    dragLeave();
  };
  return (
    <div className="connect_modal">
      <Input
        label="Account Name"
        name="account_name"
        register={register}
        placeholder="Enter Preferred Account Name"
        hasError={errors?.account_name}
        type="text"
        required
      />
      <br />
      {integration?.type !== "oauth" && (
        <>
          {integration?.fields?.map((field: any, index: number) => {
            const modified_title = field?.field_name?.split("_");

            for (var i = 0; i < modified_title.length; i++) {
              modified_title[i] =
                modified_title[i].charAt(0).toUpperCase() +
                modified_title[i].slice(1);
            }
            const modified_title2 = modified_title.join(" ");
            return (
              <div key={index}>
                {field?.field_type === "text" ? (
                  <Input
                    label={modified_title2}
                    name={field?.field_name}
                    register={register}
                    placeholder={modified_title2}
                    hasError={errors?.[field?.field_name]}
                    type={field?.field_type}
                    required
                  />
                ) : (
                  <div>
                    <p className="field_name">
                      Please add your{" "}
                      <span>
                        {field?.field_name?.replace(/_/g, " ")} Document
                      </span>
                    </p>
                    <div
                      id="drop-zone"
                      className="drop-zone"
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={dragDrop}
                      onDragOver={dragOver}
                    >
                      <p>Drag Files here to upload</p>
                      <label htmlFor="file-input" className="drop_img">
                        <ReactSVG src={assets.icons.fileUpload} />
                      </label>
                      <input
                        type="file"
                        onChange={(e) => handleFileUpload(e)}
                        hidden
                        id="file-input"
                      />
                      <p>
                        Or{" "}
                        <label htmlFor="file-input" id="file-input">
                          browse
                        </label>{" "}
                        a file on your computer.
                      </p>
                      <p></p>
                    </div>
                  </div>
                )}
                <br />
              </div>
            );
          })}
        </>
      )}
      {img && (
        <div className="img_div">
          <img src={img} alt="img" height="100px" />
          {loading_ && (
            <div className="img_div_loader">
              <Loader />
            </div>
          )}
        </div>
      )}
      <br />
      <Button
        text="Add Account"
        type="submit"
        isLoading={loading}
        isLoadingText="Please wait..."
      />
    </div>
  );
};

export default ConnectIntegration;
