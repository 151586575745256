import { ReactNode, useState } from "react";
import "./AuthFooter.scss";
import { Link, To, useLocation } from "react-router-dom";
import PrivacyPolicy from "components/Others/PrivacyPolicy/PrivacyPolicy";

const AuthFooter = (props: {
  text: ReactNode;
  link?: To;
  linkText: String;
  hideLastText?: boolean;
  bottomText?: ReactNode;
}) => {
  const location = useLocation();
  const { text, link, linkText, hideLastText, bottomText } = props;
  const [open, setOpen] = useState(false);
  return (
    <section className="auth-footer">
      <p>
        {text}{" "}
        {link && (
          <Link to={link} className="primary-color">
            {linkText}
          </Link>
        )}
      </p>
      {location?.pathname !== "/auth/login" && !hideLastText && (
        <p className="terms">
          By clicking sign up you’ve read and agreed to the{" "}
          {/* <Link to={'/privacy-policy'}>Privacy Policy</Link> &{" "} */}
          <span onClick={() => setOpen(true)}>Privacy Policy</span>{" "}
          {/* <a href="dev.sprinster.com">Terms of Service </a> */}
          {""}
          Agreement.
        </p>
      )}
      {bottomText && <h3>{bottomText}</h3>}
      <PrivacyPolicy isOpen={open} onClose={() => setOpen(false)} />
    </section>
  );
};

export default AuthFooter;
