import { useEffect, useState } from "react";
import AdminTeamsCards from "./AdminTeamsCards/AdminTeamsCards";
import AdminTeamsTable from "./AdminTeamsTable/AdminTeamsTable";
import ErrorView from "components/ui/ErrorView";
import EmptyView from "components/ui/EmptyView";
import Loader from "components/ui/Loader";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import { useAppContext } from "AppContext";
import AdminTeamsModals from "./AdminTeamsModals/AdminTeamsModals";
import { MODAL_ACTIONS } from "components/Main/enums";

const { LOADING, IDLE, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;

const { CREATE_ACTION } = MODAL_ACTIONS;

const AdminTeams = () => {
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const [result, setResult] = useState([]);
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(0);

  const { search } = useAppContext();

  const [modalAction, setModalAction] = useState("");
  const { isModalOpen, closeModal } = useAppContext();
  const [selectedTeam, selectTeam] = useState({});
  const [showModal, toggleModal] = useState(false);

  const handleClose = () => {
    setModalAction("");
    selectTeam({});
    toggleModal(false);
    closeModal();
  };

  const getTeamsData = (pageNo?: number) => {
    setStatus(LOADING);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.teams,
      params: {
        q: search,
        page: pageNo,
      },
    })
      .then((resp) => {
        const { results, count, per_page } = resp.data?.data || {};
        setResult(results);
        setCount(count);
        setPerPage(per_page);
        setStatus(results?.length > 0 ? DATAMODE : NULLMODE);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getTeamsData} message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            <AdminTeamsCards />
            <AdminTeamsTable
              data={result}
              count={count}
              page={page}
              setPage={setPage}
              setModalAction={setModalAction}
              selectTeam={selectTeam}
              toggleModal={toggleModal}
              per_page={per_page}
            />
          </>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    getTeamsData();
    // eslint-disable-next-line
  }, [search, page]);

  return (
    <>
      {renderBasedOnStage()}

      <AdminTeamsModals
        onClose={handleClose}
        modalAction={isModalOpen ? CREATE_ACTION : modalAction}
        isOpen={isModalOpen || showModal}
        selectedTeam={selectedTeam}
        handleSuccess={getTeamsData}
      />
    </>
  );
};

export default AdminTeams;
