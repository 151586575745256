const PROJECT_SECTIONS = {
  OVERVIEW: "OVERVIEW",
  TEST_SUITES: "TEST_SUITES",
  TEST_PLANS: " TEST_PLANS",
  CONFIGURATIONS: "CONFIGURATIONS",
};

export const PROJECT_ACTION_MODE = {
  LIST_PROJECTS: "LIST_PROJECTS",
  SHOW_PROJECT_DETAILS: "SHOW_PROJECT_DETAILS",
};

export const PROJECT_STATUSES = [

  {
    label: "Choose a Team",
    value: "",
  },
  {
    label: "Not Started",
    value: "not_started",
  },
  {
    label: "In Progress",
    value: "in_progress",
  },
  { label: "Done", value: "done" },
];

export const PROJECT_STATUS: any = {
  not_started: "Not Started",
  in_progress: "In Progress",
  done: "Done",
};

export default PROJECT_SECTIONS;
