import SideModal from "components/ui/Modal/SideModal/SideModal";
import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";

const ViewProjectMilestone = (props: {
  onClose: Function;
  isOpen: boolean;
  selectedMilestone: any;
}) => {
  const { onClose, isOpen  } = props;


  const handleClose = () => {
    onClose();
  };


  return (
    <SideModal
      isOpen={isOpen}
      onClose={handleClose}
      useCustomHeader
    >
       <CustomModalHeader
          onClose={handleClose}
          title={"View Milestone"}
          noButton
        />
    </SideModal>
  );
};

export default ViewProjectMilestone;
