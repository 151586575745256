import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";

import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import toastMessage from "utils/toast";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { formatDatePickerDate, getErrorMessage } from "utils/helper";
import AddNewUserForm from "./AddNewUserForm/AddNewUserForm";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { Box } from "@mui/material";
import { USER_ROLES_ } from "components/Main/Settings/enums";

const AddNewUserModal = (props: {
  onClose: Function;
  selectedUser?: any;
  handleSuccess?: Function;
  isOpen: boolean;
  isEdit?: boolean;
  // selectedTeam?: any;
  hideTeamSelection?: boolean;
}) => {
  const {
    onClose,
    isOpen,
    selectedUser,
    isEdit,
    handleSuccess,
    // selectedTeam,
    hideTeamSelection,
  } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm();
  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const { company } = employee || {};

  const { id: companyId } = company || {};

  const [loading, setLoading] = useState(false);
  const [showGitInput, toggleGitInput] = useState(false);
  const [role, setRole] = useState<any>(null);
  const [error, setError] = useState(false);

  const createUser = (data: any) => {
    if (role === null) {
      setError(true);
    } else {
      setLoading(true);
      API({
        method: httpVerbs?.POST,
        url: apiUrls?.employee,
        data: {
          ...data,
          company: companyId,
          end_date: data?.end_date || undefined,
          git_username: data?.git_username || undefined,
          team: data?.team || undefined,
          role: role?.value,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Employee created successfully.");
          handleSuccess?.();
          reset();

          onClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const updateUser = (data: any) => {
    console.log({ data });
    if (role === null) {
      setError(true);
    } else {
      setLoading(true);
      API({
        method: httpVerbs?.PATCH,
        url: `${apiUrls?.employee}${selectedUser?.id}/`,
        data: {
          ...data,
          role: role?.value,
          company: companyId,
        },
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Employee updated successfully.");
          handleSuccess?.();
          reset();
          onClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const onSubmit = (data: any) => {
    isEdit ? updateUser(data) : createUser(data);
  };

  useEffect(() => {
    if (selectedUser) {
      const { start_date, git_username, role, wages } = selectedUser || {};
      const firstName = (selectedUser?.full_name || selectedUser?.user?.name)
        ?.split(" ")
        ?.slice(0, -1)
        ?.join(" ");
      const lastName = (selectedUser?.full_name || selectedUser?.user?.name)
        ?.split(" ")
        ?.slice(-1)
        ?.join(" ");
      setValue("first_name", firstName);
      setValue("wages", wages);
      setValue("last_name", lastName);
      setValue("start_date", formatDatePickerDate(start_date));
      setValue("email", selectedUser?.user?.email || selectedUser?.email);
      setValue("git_username", git_username);
      // git username select option show
      const find_role = USER_ROLES_.find(
        (role) => role.value === selectedUser?.role
      );
      setRole(find_role);
    }
    // eslint-disable-next-line
  }, [selectedUser]);

  useEffect(() => {
    if (role?.value === "employee" || role?.value === "team_lead") {
      toggleGitInput(true);
    } else {
      toggleGitInput(false);
    }
    // eslint-disable-next-line
  }, [role]);

  const handleClose = () => {
    reset();
    onClose();
  };
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      title={isEdit ? "Update Employee" : "Add New Employee"}
      showTitle={true}
      hideButton={true}
      maxWidth="500px"
      width="550px"
      height="650px"
      // minWidth="400px"
      // useCustomHeader
    >
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <AddNewUserForm
            register={register}
            errors={errors}
            hideTeamSelection={hideTeamSelection}
            showGitInput={showGitInput}
            isEdit={isEdit}
            handleClose={handleClose}
            loading={loading}
            role={role}
            setRole={setRole}
            error={error}
          />
        </form>
      </Box>
    </DefaultModal>
  );
};

export default AddNewUserModal;
