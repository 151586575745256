import React from "react";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import "../LayoutHeader/LayoutHeader.scss";
import "components/ui/Modal/DefaultModal/DefaultModal.scss";
import { Button } from "@mui/material";

const ChangeMfaType = (props: {
  handleClose: Function;
  isOpen: boolean;
  SetupMfa: Function;
  loading: boolean;
  mfa_type: string;
}) => {
  const { handleClose, isOpen, SetupMfa, loading, mfa_type } = props;

  // Asybc request
  const handleSetup = () => {
    SetupMfa();
    handleClose();
  };

  return (
    <>
      <DefaultModal isOpen={isOpen} onClose={handleClose} sx={{ width: 500 }}>
        <div className="add_card">
          {/* <h4 style={{ textAlign: "center" }}>Two Factor Authentication</h4> */}
          {mfa_type === "email" ? (
            <p>
              You are about to change Email Authentication to Google
              Authenticator(TOTP) Authentication
              <br />
              <br />
              Please note that, by doing this, Email Authentication won’t work
              anymore.
            </p>
          ) : (
            <p>
              You are about to change Google Authenticator(TOTP) to Email
              Authentication
              <br />
              <br />
              Please note that, by doing this, Google Authenticator(TOTP) won’t
              work anymore.
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
          sx={{
            mt: 5,
            textTransform: "inherit",
            color: "#FF0000",
            border: "1px solid #FF0000",
            backgroundColor: "transparent",
            py: 1.3,
            px: 3,
            "&:hover": {
            backgroundColor: "transparent",
          },
          }}
          variant="outlined"
          onClick={() => handleClose()}
        >
          Cancel
        </Button>
            <Button
              sx={{
                mt: 5,
                textTransform: "inherit",
                backgroundColor: "#008EE2",
                py: 1.5,
                ml: 3, 
                "&:hover": {
                  backgroundColor: "#008EE2",
                },
              }}
              variant="contained"
              disabled={loading}
              onClick={handleSetup}
            >
              change Method
            </Button>
          </div>
        </div>
      </DefaultModal>
    </>
  );
};

export default ChangeMfaType;
