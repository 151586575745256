import { Button, AppTable } from "components/ui";
import "./AdminViewTeamKPI.scss";
import assets from "assets";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import AdminViewKPIModal from "./AdminViewKPIModal/AdminViewKPIModal";
import FilterModal from "components/common/FilterModal/FilterModal";
import EmptyView from "components/ui/EmptyView";
import { Typography } from "@mui/material";

const AdminViewTeamKPI = (props: {
  data: any;
  toggleModal: Function;
  selectTeam: Function;
  selectedKpi: Object;
  selectKpi: Function;
  teamName: string;
  count: any;
  page: number;
  setPage: Function;
  per_page: number;
}) => {
  const {
    selectedKpi,
    teamName,
    per_page,
    setPage,
    page,
    count,
  } = props;

  const [openModal, showModal] = useState(false);

  const { data } = props;

  const navigate = useNavigate();

  const columns = [
    {
      title: "Employee Name",
      width: "200px",
      render: (row: any) => (
        <Typography sx={{ fontSize: "12px", textTransform: "capitalize" }}>
          {row?.full_name}
        </Typography>
      ),
    },
    {
      title: "Manual Kpi",
      key: "manual_kpi_score",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.manual_kpi_score || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Auto Kpi",
      key: "auto_kpi_score",
      width: "400",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.auto_kpi_score}
        </Typography>
      ),
    },
    {
      title: "Kpi Score",
      key: "kpi_score",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.kpi_score || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Wages",
      key: "wages",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.wages || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Role",
      key: "role",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.role || "N/A"}
        </Typography>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };
  return (
    <>
      <div className="admin-view-team-kpi">
        <section>
          <Button
            text="Back"
            icon={assets.icons.arrowLeft}
            onClick={() => navigate("/project/kpis")}
          />
          <div className="admin-view-team-kpi__links">
            <span onClick={() => navigate("/project/kpis")}>Team KPIs</span>
            <span>/</span>
            <span className="active">Team members KPIs</span>
          </div>
        </section>
        <h3>{`${teamName} KPI`}</h3>
        {data?.length > 0 ? (
          <AppTable
            columns={columns}
            data={data}
            sorter={(a: any, b: any) => b?.id - a?.id}
            dataLength={count}
            noPerPage={per_page}
            page={page}
            onPageChange={handlePageChange}
          />
        ) : (
          <EmptyView message="No Individual KPI Found" />
        )}
      </div>

      <AdminViewKPIModal
        isOpen={openModal}
        onClose={() => showModal(false)}
        selectedKpi={selectedKpi}
      />

      <FilterModal
        filterType="TEAM"
        onClear={() => navigate("/project/kpis")}
      />
    </>
  );
};

export default AdminViewTeamKPI;
