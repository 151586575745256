import { Button } from "components/ui";
import Input from "components/ui/Input";
import Select from "components/ui/Select";
import { formatDate } from "utils/helper";
import "../TaskStyles.scss";

const CreatePOTaskForm = (props: {
  register: Function;
  errors: any;
  milestone: any;
  selectedTask: any;
  isEdit: any;
  loading: any;
  getValues: Function;
  watch: Function;
  projectMilestones: any;
  check_Points: any;
  users: any;
  selectedProject: any;
  setMilestone: any;
  assigned: any;
  setAssigned: any;
  state_: any;
  setState_: any;
  error: boolean;
}) => {
  const {
    register,
    errors,
    milestone,
    isEdit,
    loading,
    getValues,
    watch,
    projectMilestones,
    check_Points,
    users,
    selectedProject,
    setMilestone,
    state_,
    setState_,
    assigned,
    setAssigned,
    error,
  } = props;

  const find_milestone = projectMilestones?.find(
    (milestone_: any) => milestone_?.id === milestone
  );

  const start_date = new Date(watch("start_date"));
  const get_next_day = new Date(start_date);
  get_next_day.setDate(start_date.getDate() + 1);

  const minValue = get_next_day
    ? formatDate(get_next_day)
    : formatDate(new Date());
  const filtered_users = users?.filter((user: any) =>
    selectedProject?.team?.employees?.includes(user?.id)
  );
  return (
    <div className="create-pc-modal-form">
      <div className="create-pc-modal-form__inputs p-y-1">
        <Input
          label="Task Name"
          placeholder="Enter Task Name"
          name="task_name"
          register={register}
          hasError={errors?.task_name}
          required
        />
        <br />
        <Input
          label="Label"
          placeholder="Preferred Task Group(Optional)"
          name="label"
          register={register}
        />
      </div>
      <div className=" p-y-1">
        <Select
          label="Task Status"
          register={register}
          hasError={errors?.state}
          name="state"
          isDisabled={loading}
          value={state_}
          onChange={(e: any) => setState_(e)}
          required
          options={[
            {
              label: "Not Started",
              value: "not_started",
            },
            {
              label: "In Progress",
              value: "in_progress",
            },
            {
              label: "Completed",
              value: "done",
            },
          ]}
        />
        {error && state_ === null && (
          <span className="custom-select-error">
            Please select a task status
          </span>
        )}
      </div>
      <div className=" p-y-1">
        <Select
          label="Assign To"
          register={register}
          hasError={errors?.assigned}
          name="assigned"
          placeholder="Assign Project"
          isDisabled={loading}
          value={assigned}
          isLoading={loading}
          onChange={(e: any) => setAssigned(e)}
          options={[
            ...filtered_users?.map(({ name, id }: any) => ({
              value: id,
              label: name,
            })),
          ]}
        />
      </div>
      <div className="create-pc-modal-form__inputs p-y-1">
        <Select
          label="Milestone"
          register={register}
          hasError={errors?.milestone}
          name="milestone"
          isDisabled={loading}
          required
          isLoading={loading}
          value={milestone}
          onChange={(e: any) => setMilestone(e)}
          options={[
            ...projectMilestones.map(({ indicator, id }: any) => {
              return {
                value: id,
                label: indicator,
              };
            }),
          ]}
        />
        {error && milestone === null && (
          <span className="custom-select-error">
            Please select a project milestone
          </span>
        )}
        {(find_milestone as any)?.points_left === 0 && !check_Points && (
          <span style={{ fontSize: "10px", color: "red" }}>
            This milestone have no point left, please select another milestone
          </span>
        )}
        {milestone && (
          <div className=" p-y-1">
            <Input
              label="Task Points"
              placeholder="Task Points"
              name="points"
              register={register}
              hasError={errors?.points}
              required
              type="number"
              maxValue={
                isEdit
                  ? (find_milestone as any)?.points_left + getValues("points")
                  : (find_milestone as any)?.points_left
              }
              minValue={1}
              maxLength={
                isEdit
                  ? ((find_milestone as any)?.points_left + getValues("points"))
                      ?.length
                  : (find_milestone as any)?.points_left?.length
              }
            />
          </div>
        )}
        <div className=" p-y-1">
          <Input
            label="Complexity"
            name="complexity"
            register={register}
            hasError={errors?.complexity}
            placeholder="Range from 1 to 10"
            minValue={1}
            maxValue={10}
            type="number"
            required
          />
        </div>
        <div className=" p-y-1">
          <Input
            label="Start Date"
            name="start_date"
            register={register}
            hasError={errors?.start_date}
            type="date"
            required
          />
        </div>
        <Input
          label="Expected Delivery Date"
          name="expected_date"
          register={register}
          type="date"
          minValue={minValue}
          hasError={errors?.expected_date}
          required
        />
      </div>
      <div className="admin-project_btn">
        <Button text="Submit" type="submit" isLoading={loading} />
      </div>
    </div>
  );
};

export default CreatePOTaskForm;
