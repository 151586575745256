import "./LinearProgressBar.scss";

const LinearProgressBar = (props: {
  currentPercent: number;
  showLabel?: boolean;
  label?: string;
  className?: string;
}) => {
  const { label, showLabel, className, currentPercent } = props;
  return (
    <div className={`linear-progress-bar ${className}`}>
      <div
        className={`linear-progress-bar__progress`}
        style={{
          width: `${currentPercent}%`,
        }}
      >
        {showLabel && currentPercent > 0 && <span>{label}</span>}
      </div>
    </div>
  );
};

export default LinearProgressBar;
