import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import auth from "./auth/reducer";
import installations from "./installations/reducer";
import stats from "./stats/reducer";
import filter from "./filter/reducer";
import team from "./team/reducer";

export const SIGNOUT_REQUEST = "SIGNOUT_REQUEST";

const appReducer = combineReducers({
  auth,
  installations,
  stats,
  filter,
  team,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === SIGNOUT_REQUEST) {
    // for all keys defined in your persistConfig(s)
    localStorage.removeItem("persist:sprinster");
    storage.removeItem("persist:sprinster");
    // storage.removeItem('persist:otherKey')
    state = undefined;
    // return appReducer(undefined, {});
  }
  return appReducer(state, action);
};

export default rootReducer;
