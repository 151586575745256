import { MetricDashboardPropsTypes } from "../DashboardD.types";
import "./DashboardCodeAnalysis.scss";
import DevAnalysis from "./DevAnalysis/DevAnalysis";
import RepositoryMetrics from "./RepositoryMetrics/RepositoryMetrics";

const DashboardCodeAnalysis = (props: MetricDashboardPropsTypes) => {
  const { efficiency, total_cost } = props;
  const data = {
    title: "Development Analytics",
    data: [
      {
        label: "Team Efficiency",
        value: efficiency,
        color: "low",
      },
      {
        label: "Total Cost",
        value: total_cost,
        color: "very-high",
      },
    ],
  };

  return (
    <div className="dashboard-code-analysis">
      <RepositoryMetrics {...props} />
      <DevAnalysis {...data} />
    </div>
  );
};

export default DashboardCodeAnalysis;
