import Icon from "../Icon";
import "./FileInput.scss";

interface FileInputProps {
  className?: string;
  labelText?: string;
  onChange?: Function;
  value?: string;
  id?: string;
  name?: string;
  invertStyle?: boolean;
  register?: Function;
  required?: boolean;
  isDisabled?: boolean;
  hasError?: boolean;
  errorMsg?: string;
}

const FileInput = (props: FileInputProps) => {
  const {
    className,
    labelText,
    onChange,
    id,
    name,
    invertStyle,
    register,
    isDisabled,
    required,
    errorMsg,
    hasError,
  } = props;
  return (
    <>
      <div className="file-input">
        <label className={`${className} ${invertStyle ? "label-invert" : ""}`}>
          {labelText ? `${labelText} ` : "Select File "} <Icon icon="file" />
          <input
            type="file"
            size={60}
            onChange={(e) => onChange?.(e.target.files)}
            id={id}
            name={name}
            {...register?.(name, { required })}
            disabled={isDisabled}
          />
        </label>
        {hasError && (
          <span className="file-input-error">
            {errorMsg ? errorMsg : `${labelText} is required.`}
          </span>
        )}
      </div>
    </>
  );
};

export default FileInput;
