import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { useEffect, useState } from "react";
import assets from "assets";
import { ReactSVG } from "react-svg";
import "../integration.scss";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import Loader from "components/ui/Loader";

const ViewModal = (props: {
  onClose: Function;
  isOpen: boolean;
  integration: any;
  disconnect?: boolean;
  handleSuccess?: any;
  setId?: any;
  handleDisconnectModalOpen?: any;
}) => {
  const {
    onClose,
    isOpen,
    integration,
    disconnect,
    setId,
    handleDisconnectModalOpen,
  } = props;

  // states
  const [loading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [code_results, setCodeResults] = useState([]);

  const getUserIntegrations = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.connectUserIntegration,
    })
      .then((resp) => {
        setResults(resp.data.data);
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const getUserCodeIntegrations = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.connectCodeUserIntegration,
    })
      .then((resp) => {
        setCodeResults(resp.data.data?.results);
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    if (isOpen) {
      getUserIntegrations();
      getUserCodeIntegrations();
    }
  }, [isOpen]);

  const filtered_results = [...results, ...code_results]?.filter(
    (result: any) =>
      result?.integration === integration?.id &&
      (result?.name || result?.account_name)
  );
  return (
    <>
      <DefaultModal
        isOpen={isOpen}
        onClose={onClose}
        title={`All accounts connected to ${integration?.name}`}
        buttonText="Connect"
        hideButton={true}
        showCloseIcon
        center
        sx={{ width: 500 }}
      >
        <div className="view_children">
          {loading ? (
            <Loader />
          ) : (
            <div>
              {filtered_results?.map((result: any, index: number) => (
                <div className="view_child" key={index}>
                  <p>{result?.name ? result?.name : result?.account_name}</p>
                  {disconnect && (
                    <ReactSVG
                      src={assets.icons.deleteRed}
                      className="title_icon"
                      onClick={() => {
                        handleDisconnectModalOpen();
                        setId(result?.id);
                      }}
                    />
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      </DefaultModal>
    </>
  );
};

export default ViewModal;
