import { Button } from "components/ui";
import Input from "components/ui/Input";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";

const VerifyOtpForm = (props: {
  handleContinue: Function;
  request: Object;
}) => {
  const [loading, setLoading] = useState(false);
  const { handleContinue, request } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const validateOTP = (data: any) => {
    setLoading(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.validatePasswordOtp,
      data: {
        ...data,
        ...request,
      },
    })
      .then((resp) => {
        setLoading(false);
        const { code, id } = resp?.data?.data || {};
        handleContinue?.({ code, id });
        reset();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };
  const onSubmit = (data: any) => {
    validateOTP(data);
  };

  return (
    <>
      <section className="login-form">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="login-form__inputs">
            <Input
              label="Code"
              placeholder="Enter Code"
              name="code"
              hasError={errors?.code ? true : false}
              register={register}
              required={true}
            />
          </div>
          <Button
            text="Validate Recovery Code"
            isLoading={loading}
            type="submit"
            isLoadingText="Please wait..."
          />
        </form>
      </section>
    </>
  );
};

export default VerifyOtpForm;
