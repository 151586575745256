import React, { useEffect, useRef, useState } from "react";
import ConnectModal from "./ConnectIntegration";
import assets from "assets";
import "../integration.scss";
import { ReactSVG } from "react-svg";
import ViewModal from "./ViewModal";
import DisconnectModal from "./DisconnectModal";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { getErrorMessage } from "utils/helper";
import { useLocation, useNavigate } from "react-router-dom";
import { apiUrls, httpVerbs } from "utils/api";
import queryString from "query-string";
import API from "utils/api/API";
import { useSelector } from "react-redux";
import toastMessage from "utils/toast";

const IntegrationCard = (props: {
  integration: { [key: string]: any };
  handleSuccess?: any;
}) => {
  const location = useLocation();
  // Props
  const { integration, handleSuccess } = props;
  const navigate = useNavigate();

  //   states
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, toggleModal] = useState(false);
  const [showView, setShowView] = useState(false);
  const [showDisconnect, setDisconnect] = useState(false);
  const [id, setId] = useState("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isRequestMade = useRef(false);
  const openEl = Boolean(anchorEl);
  const { code, installation_id, setup_action }: any = queryString.parse(
    window.location.search
  );

  const { user } = useSelector((state: any) => state.auth);

  const {
    employee: { company },
  } = user || {};

  // functions
  const handleclose = () => {
    toggleModal(false);
  };

  const openMenu = (event: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(event.currentTarget);
  const closeMenu = () => setAnchorEl(null);

  const handleViewClose = () => {
    setShowView(false);
  };

  const handleDisconnectModalOpen = () => {
    setOpen(true);
  };
  const handleDisconnectModalClose = () => {
    setOpen(false);
  };

  const handleDisconnectClose = () => {
    setDisconnect(false);
  };
  const handleModal = () => {
    toggleModal(true);
  };
  const integration_name = localStorage.getItem("integration_name");
  const account_name = localStorage.getItem("account_name");
  const github_payload = {
    installation_id,
    code,
    installation_type: "github",
    company: company?.id,
    account_name,
    integration: integration?.id,
  };

  const gitlab_payload = {
    installation_id: code,
    installation_type: "gitlab",
    company: company?.id,
    account_name,
    integration: integration?.id,
  };

  const heroku_payload = {
    code,
    company_id: company?.id,
    name: account_name,
  };

  const digitalocean_payload = {
    code,
    company_id: company?.id,
    name: account_name,
  };
  const completeInstallation = () => {
    setLoading(true);
    isRequestMade.current = true;
    API({
      method: httpVerbs?.POST,
      url:
        integration_name?.toLowerCase() === "heroku"
          ? apiUrls?.setup_heroku
          : integration_name?.toLowerCase() === "digitalocean"
          ? apiUrls?.setup_digitalocean
          : apiUrls?.setup_github,
      data:
        integration_name?.toLowerCase() === "github"
          ? github_payload
          : integration_name?.toLowerCase() === "heroku"
          ? heroku_payload
          : integration_name?.toLowerCase() === "digitalocean"
          ? digitalocean_payload
          : gitlab_payload,
    })
      .then((resp) => {
        setLoading(false);
        handleSuccess();
        toastMessage(`Successfully installed ${integration_name}`);
        localStorage.removeItem("integration_name");
        navigate("/settings/integrations/");
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };
  useEffect(() => {
    if (
      code !== undefined &&
      installation_id &&
      integration?.name === integration_name &&
      !isRequestMade?.current
    ) {
      completeInstallation();
    }
    // eslint-disable-next-line
  }, [code, installation_id, integration_name]);

  useEffect(() => {
    if (
      code &&
      integration?.name === integration_name &&
      !isRequestMade?.current
    ) {
      completeInstallation();
    }
    // eslint-disable-next-line
  }, [code, integration_name]);

  return (
    <div>
      <div className="integration_card">
        <div className="integration_card_top">
          <div className="integration_card_img_div">
            <img src={integration?.logo} alt="icon" />
            {integration?.is_configured && (
              <IconButton
                aria-controls={openEl ? "long-menu" : undefined}
                aria-expanded={openEl ? "true" : undefined}
                onClick={openMenu}
              >
                <ReactSVG src={assets.icons.menu} />
              </IconButton>
            )}
          </div>
          <Menu
            open={openEl}
            anchorEl={anchorEl}
            onClose={closeMenu}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              sx={{
                fontSize: 14,
                fontWeight: 500,
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={() => {
                setAnchorEl(null);
                toggleModal(true);
              }}
            >
              Add account
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowView(true);
                setAnchorEl(null);
              }}
              sx={{
                fontSize: 14,
                fontWeight: 500,
                color: "#46464A",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              View accounts
            </MenuItem>
          </Menu>
          <div className="integration_card_content">
            <h2>{integration?.name}</h2>
            <p>{integration?.group}</p>
          </div>
        </div>
        <div className="footer">
          <p>
            Connect to {integration?.name} to get infrastructure costs and
            billings
          </p>
          <div className="btn_div">
            {integration?.is_configured ? (
              <button className="integration_connected_button">
                <span>Connected</span>
                <ReactSVG src={assets.icons.check} className="connected_icon" />
              </button>
            ) : (
              <button
                className="integration_connect_button"
                onClick={handleModal}
              >
                Connect
              </button>
            )}
            {integration?.is_configured && (
              // integration?.group !== "Code Repository" && (
              <button
                className="integration_disconnect_button"
                onClick={() => setDisconnect(true)}
              >
                <ReactSVG
                  src={assets.icons.deleteOutline}
                  className="disconnect_icon"
                />
                <span>Disconnect</span>
              </button>
            )}
          </div>
        </div>
      </div>
      <ConnectModal
        onClose={handleclose}
        isOpen={showModal}
        integration={integration}
        handleSuccess={handleSuccess}
      />

      <ViewModal
        onClose={handleViewClose}
        isOpen={showView}
        integration={integration}
      />
      <ViewModal
        onClose={handleDisconnectClose}
        isOpen={showDisconnect}
        integration={integration}
        disconnect
        handleSuccess={handleSuccess}
        setId={setId}
        handleDisconnectModalOpen={handleDisconnectModalOpen}
      />
      <DisconnectModal
        isOpen={open}
        onClose={handleDisconnectModalClose}
        integration={integration}
        handleSuccess={handleSuccess}
        id={id}
      />
    </div>
  );
};

export default IntegrationCard;
