import EmptyView from "components/ui/EmptyView";
import RisksCards from "./RisksCards/RisksCards";
import RisksTable from "./RisksTable/RisksTable";
import { useAppContext } from "AppContext";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const Risks = () => {
  const { isFilterLoading } = useAppContext();
  const { selectedTeam } = useSelector((state: any) => state.filter);
  const navigate = useNavigate();

  const renderBasedOnTeamAvailability = () => {
    if (!isFilterLoading && !selectedTeam?.id) {
      return (
        <EmptyView
          message="No team to view metric data for"
          hasActionBtn
          actionBtnText="Create A Team"
          handleAction={() => navigate("/admin/teams/")}
        />
      );
    } else {
      return (
        <>
          <RisksCards />
          <RisksTable />
        </>
      );
    }
  };

  return <>{renderBasedOnTeamAvailability()}</>;
};

export default Risks;
