import React, { useEffect, useState } from "react";
import API from "utils/api/API";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import { Box, Fade, Tooltip, Typography } from "@mui/material";
import { AppTable } from "components/ui";
import EmptyView from "components/ui/EmptyView";
import { useAppContext } from "AppContext";
import Loader from "components/ui/Loader";
import ErrorView from "components/ui/ErrorView";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ClockingModal from "components/ui/Modals/ClockingModal";
import { useSelector } from "react-redux";
import Select from "components/ui/Select";
import "./Clocking.scss";
import moment from "moment";

const { LOADING, IDLE, ERROR, DATAMODE } = dataQueryStatuses;

const Index = () => {
  const [status, setStatus] = useState(IDLE);
  const { search } = useAppContext();
  const [clockingData, setClockinData] = useState([]);
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedUser, selectUser] = useState({});
  const [showModal, toggleModal] = useState(false);
  const { user } = useSelector((state: any) => state.auth);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState<any>(null);

  const getUsers = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employee_names,
    })
      .then((resp) => {
        setUsers(resp.data.data);
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (
      user?.employee?.role === "project_manager" ||
      user?.employee?.role === "admin"
    ) {
      getUsers();
    }
  }, [user?.employee?.role]);

  const getClocking = () => {
    setStatus(LOADING);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.clocking,
      params: {
        q: search,
        employee: employee?.value,
        page: page,
      },
    })
      .then((resp) => {
        const { results, count, per_page } = resp.data.data;
        setStatus(DATAMODE);
        setCount(count);
        setClockinData(results);
        setPerPage(per_page);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        toastMessage(errMsg, true);
        setErrorMessage(errMsg);
      });
  };

  useEffect(() => {
    getClocking();
  }, [search, page, employee]);

  const handlePageChange = (page: number) => {
    setPage(page);
  };
  const columns = [
    {
      title: "Name",
      render: (row: any) => (
        <Typography sx={{ fontSize: "12px", textTransform: "capitalize" }}>
          {row?.employee?.name}
        </Typography>
      ),
    },
    {
      title: "Role",
      align: "left",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.employee?.role || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Hours Worked",
      align: "left",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
          }}
        >
          {row?.hours_worked || 0}
        </Typography>
      ),
    },
    {
      title: "Task Completed",
      align: "left",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.tasks_completed || 0}
        </Typography>
      ),
    },
    {
      title: "Clock in Date",
      align: "center",
      padding: "0 5px",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {moment(row?.clock_in).format("DD MMM YYYY HH:mm:ss") || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Clock out Date",
      align: "center",
      padding: "0 5px",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.clock_out !== null
            ? moment(row?.clock_out).format("DD MMM YYYY HH:mm:ss")
            : "N/A"}
        </Typography>
      ),
    },
    {
      title: "",
      align: "center",
      render: (row: any) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          {user.employee?.id === row?.employee?.id ? (
            <Tooltip
              title="Update Clockin"
              placement="top-end"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <>
                {row?.clock_out === null && (
                  <BorderColorIcon
                    sx={{ width: 18, height: 18, marginRight: "1rem" }}
                    onClick={() => {
                      selectUser(row);
                      toggleModal(true);
                    }}
                  />
                )}
              </>
            </Tooltip>
          ) : null}
        </Box>
      ),
    },
  ];

  const filtered_users = users?.filter((user: any) => user?.role !== "admin");
  const renderBasedOnStage = () => {
    switch (status) {
      case LOADING:
        return <Loader />;
      case ERROR:
        return <ErrorView handleRetry={getClocking} message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            {clockingData?.length > 0 ? (
              <AppTable
                columns={columns}
                data={clockingData}
                sorter={(a: any, b: any) => b?.id - a?.id}
                dataLength={count}
                noPerPage={per_page}
                page={page}
                onPageChange={handlePageChange}
              />
            ) : (
              <EmptyView message="No Clockin Found" />
            )}
          </>
        );
      default:
        return "";
    }
  };
  return (
    <div
      style={{ backgroundColor: "white", paddingTop: "2rem !important" }}
      className="item_div"
    >
      {clockingData?.length > 0 && (
        <>
          {user?.employee?.role !== "employee" &&
            user?.employee?.role !== "team_lead" && (
              <div className="item">
                <Select
                  value={employee}
                  onChange={setEmployee}
                  options={[
                    {
                      label: loading ? "Loading..." : "Choose Employee",
                      value: "",
                    },
                    ...users?.map(({ name, id }: any) => ({
                      value: id,
                      label: name,
                    })),
                  ]}
                  label="Filter Employee"
                  placeholder="Text"
                  name="employee"
                  required
                />
              </div>
            )}
        </>
      )}
      {renderBasedOnStage()}

      <ClockingModal
        isOpen={showModal}
        onClose={() => toggleModal(false)}
        selectedUser={selectedUser}
        edit
      />
    </div>
  );
};

export default Index;
