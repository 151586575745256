import { MODAL_ACTIONS } from "components/Main/enums";
import AddNewUserModal from "./AddNewUserModal/AddNewUserModal";
import DeleteUserModal from "./DeleteUserModal/DeleteUserModal";

const { EDIT_ACTION, CREATE_ACTION, DELETE_ACTION } = MODAL_ACTIONS;

const AdminUsersModals = (props: {
  modalAction: string;
  onClose: Function;
  handleSuccess?: Function;
  selectedUser?: any;
  isOpen: boolean;
  selectedTeam?: any;
  hideTeamSelection?: boolean;
}) => {
  const { modalAction, onClose, handleSuccess, selectedUser, isOpen, ...rest } =
    props;

  const renderBasedOnAction = () => {
    switch (modalAction) {
      case EDIT_ACTION:
      case CREATE_ACTION:
        return (
          <AddNewUserModal
            onClose={onClose}
            handleSuccess={handleSuccess}
            selectedUser={selectedUser}
            isOpen={isOpen}
            isEdit={modalAction === EDIT_ACTION}
            {...rest}
          />
        );

      case DELETE_ACTION:
        return (
          <DeleteUserModal
            onClose={onClose}
            handleSuccess={handleSuccess}
            selectedUser={selectedUser}
            isOpen={isOpen}
          />
        );
      default:
        return <AddNewUserModal
        onClose={onClose}
        handleSuccess={handleSuccess}
        selectedUser={selectedUser}
        isOpen={isOpen}
        isEdit={modalAction === EDIT_ACTION}
        {...rest}
      />;
    }
  };

  return <>{renderBasedOnAction()}</>;
};

export default AdminUsersModals;
