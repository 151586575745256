import "./DefaultModal.scss";
// import Icon from "../../Icon";
import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  DialogActions,
  Typography,
  Box,
  Button,
} from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { useState } from "react";
import CloudUpload from "../../../assets/general/CloudUpload.svg";
import Trash from "../../../assets/general/trash.svg";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import { useSelector } from "react-redux";

const BulkUploadModal = (props: any) => {
  const { isOpen, onClose } = props;

  const [document, setDocument] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { user } = useSelector((state: any) => state.auth);
  const { employee } = user || {};

  const { company } = employee || {};
  const { id: companyId } = company || {};

  const dragEnter = () => {
    const target = document.getElementById("drop-zone");
    target.style.borderColor = "#C4C4C4";
    target.style.backgroundColor = "#A58980";
  };

  const dragLeave = () => {
    const target = document.getElementById("drop-zone");
    target.style.borderColor = "#A58980";
    target.style.backgroundColor = "transparent";
  };

  const dragOver = (e: any) => {
    e.preventDefault();
  };

  const handleDrop = (e: any) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && file.type.includes("image")) {
      const reader = new FileReader();
      reader.onload = () => {
        setDocument(reader.result);
      };
      reader.readAsDataURL(file);
      convertedBase64(file).then(() => {});
    } else {
      // enqueueSnackbar('Invalid Data Dropped', { variant: 'error' });
    }
    dragLeave();
  };

  const uploadImage = async (event: any) => {
    const input = event.target;
    const file = input.files?.item(0);
    const fileSize = file.size / 1000 / 1000;
    if (fileSize > 2) {
      setDocument("");
    } else {
      // const base64 = await convertedBase64(file);
      setDocument(file);
    }
  };
  const convertedBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const filReader = new FileReader();
      filReader.readAsDataURL(file);
      filReader.onload = () => {
        resolve(filReader.result);
      };
      filReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const UploadEmployees = (event: any) => {
    if (!document) {
      setError(true);
    } else {
      event.preventDefault();
      const formData = new FormData();
      formData.append("company", companyId);
      formData.append("file_upload", document);
      setLoading(true);
      API({
        method: httpVerbs?.POST,
        url: apiUrls?.employeesUpload,
        data: formData,
      })
        .then((resp) => {
          setLoading(false);
          toastMessage("Employees added successfully.");
          onClose();
        })
        .catch((err) => {
          const errMsg = getErrorMessage(err);
          setLoading(false);
          toastMessage(errMsg, true);
        });
    }
  };

  const handleClose = () => {
    setDocument(null);
    setError(false);
    onClose();
  };
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            justifyContent: "center",
            alignItems: "center",
          },
          overflow: "hidden",
        }}
        PaperProps={{
          sx: {
            boxShadow: "0px 12px 24px rgba(0, 0, 0, 0.16)",
            minWidth: 370,
            position: "relative",
            top: 0,
            left: { md: "16%", lg: "0%" },
            width: "500px",
            maxWidth: "unset",
            height: "unset",
            maxHeight: 800,
            m: 0,
            zIndex: 1002,
            overflow: "hidden !important",
          },
        }}
        scroll="paper"
      >
        <DialogTitle
          sx={{
            fontSize: 24,
            fontWeight: 700,
            color: "black",
            marginBottom: 0,
            paddingBottom: "8px",
            backgroundColor: "#EDF7FD",
          }}
        >
          <Typography
            sx={{
              textAlign: "center",
              color: "black",
              fontWeight: 500,
              fontSize: "18px",
            }}
          >
            Employee Bulk Upload
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ padding: "0 28px", overflow: "auto" }}>
          <Box sx={{ mt: 3 }}>
            <Typography
              sx={{
                color: "black",
                fontWeight: 500,
                fontSize: "18px",
              }}
            >
              How to upload
            </Typography>
            <Box sx={{ mt: 2, backgroundColor: "#EDF7FD", p: 2 }}>
              <Typography sx={{ fontSize: "12px", fontWeight: 400, pb: 1 }}>
                1. Download the template file below
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 400, pb: 1 }}>
                2. Fill it with the necessary data and save it
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 400, pb: 1 }}>
                3. Upload the filled template in the uploading space below{" "}
              </Typography>
              <Typography sx={{ fontSize: "12px", fontWeight: 400, pb: 1 }}>
                4. View the bulk upload in the employee table.
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                component="a"
                // href="https://sprintstar-dev.s3.amazonaws.com/templates/employee-upload-template.csv"
                href="https://sprintstar-prod.s3.amazonaws.com/templates/employee-upload-template.csv"
                target="_blank"
                rel="noreferrer"
                sx={{
                  my: 3,
                  textTransform: "inherit",
                }}
                startIcon={<InsertDriveFileOutlinedIcon />}
                variant="outlined"
              >
                Download CSV Template
              </Button>
            </Box>

            <Box sx={{ my: 2 }}>
              <Box
                sx={{
                  backgroundColor: "#EDF7FD",
                  border: "1px dashed #008EE2",
                  borderRadius: 2,
                  padding: 1,
                  minHeight: 150,
                  position: "relative",
                  mt: 2,
                }}
              >
                <Box
                  id="drop-zone"
                  onDragEnter={dragEnter}
                  onDragLeave={dragLeave}
                  onDrop={handleDrop}
                  onDragOver={dragOver}
                  sx={{ height: "100%", p: 2 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img src={CloudUpload} alt={CloudUpload} />
                    <label htmlFor="file-input">
                      <Typography
                        sx={{
                          color: "000000",
                          fontSize: "16px",
                          fontWeight: 500,
                          mt: 2,
                        }}
                      >
                        Drop and drop file or{" "}
                        <Typography
                          component="span"
                          sx={{
                            color: "#008EE2",
                            fontWeight: 400,
                            fontSize: "16px",
                            cursor: "pointer",
                          }}
                        >
                          browse
                        </Typography>
                      </Typography>
                      <Typography
                        sx={{
                          color: "#475569",
                          fontSize: "12px",
                          fontWeight: 400,
                          textAlign: "center",
                        }}
                      >
                        Supported format: CSV
                      </Typography>
                    </label>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 1,
                    }}
                  >
                    <input
                      type="file"
                      accept=".csv"
                      onChange={(e) => uploadImage(e)}
                      hidden
                      id="file-input"
                    />
                  </Box>
                  <input type="file" accept=".csv" hidden id="file-input" />
                </Box>
              </Box>
              {error && !document && (
                <Typography
                  sx={{ fontSize: "12px", color: "#FF0909", mt: 0.5 }}
                >
                  Please upload document to continue!
                </Typography>
              )}
            </Box>
            {document && (
              <Box>
                <Typography
                  sx={{
                    color: "#475569",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  File Uploaded
                </Typography>
                <Box
                  sx={{
                    border: "0.5px solid #475569",
                    borderRadius: 1,
                    display: "flex",
                    justifyContent: "space-between",
                    alignitems: "center",
                    py: 1.5,
                    px: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: "#475569",
                      fontSize: "12px",
                      fontWeight: 400,
                      wordBreak: "break-word",
                    }}
                  >
                    {document.name}
                  </Typography>
                  <img
                    src={Trash}
                    alt="Trash"
                    onClick={() => setDocument(null)}
                    style={{ cursor: "pointer" }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            px: 3,
            mt: 4,
            mb: 2,
          }}
        >
          {/* {actions} */}
          <Button
            variant="contained"
            sx={{
              textTransform: "inherit",
              backgroundColor: "#E9E9F0",
              color: "#000000",
              "&:hover": {
                backgroundColor: "#E9E9F0",
                color: "#000000",
              },
            }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            type="button"
            onClick={UploadEmployees}
            sx={{
              textTransform: "inherit",
              backgroundColor: "#008EE2",
              "&:hover": {
                backgroundColor: "#008EE2",
              },
            }}
            disabled={loading}
          >
            {loading ? "Submitting...." : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BulkUploadModal;
