import React, { useState } from "react";
import { ModalProps } from "../Modal/modal.d";
import "./TooltipModal.scss";
// import Icon from "../../Icon";
// import Button from "../Button/index";
import "react-responsive-modal/styles.css";
// import { Modal } from "react-responsive-modal";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Card, Typography } from "@mui/material";
import Polygon from "../../../assets/general/Ploygon.svg";
import SidePolygon from "../../../assets/general/SidePolygon.svg";
import { ReactSVG } from "react-svg";

const TeamsTootltipModal = (props: ModalProps) => {
  const { isOpen, onClose, sx } = props;
  const [page, setPage] = useState(1);

  const modalDetails = [
    {
      id: 1,
      sub_text:
        "This tab shows all the details of all Teams in your organization",
    },
    {
      id: 2,
      text: "Clockin",
      sub_text: "Clockin helps you manage the your time and productivity!",
    },
    {
      id: 3,
      sub_text: "Search field, helps you find a team based on the team name",
    },
  ];

  return (
    <>
      <Modal open={isOpen}>
        <Box>
          {modalDetails?.map((detail) => (
            <Box key={detail?.id}>
              {detail?.id === page && (
                <>
                  <Box
                    sx={{
                      position: "absolute",
                      top:
                        page === 1 ? "15rem" : page === 2 ? "11rem" : "11rem",
                      left:
                        page === 1 ? "28rem" : page === 2 ? "76rem" : "84rem",
                      color: "#FFFFFF",
                      width: "40px",
                    }}
                  >
                    <ReactSVG
                      src={detail?.id === 6 ? SidePolygon : Polygon}
                      className="polygon"
                    />
                  </Box>
                  <Card
                    elevation={10}
                    sx={{
                      position: "absolute",
                      top:
                        page === 1 ? "16rem" : page === 2 ? "12rem" : "12rem",
                      left:
                        page === 1 ? "25rem" : page === 2 ? "70rem" : "80rem",
                      width: "250px",
                      p: 2,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mb: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          textAlign: "left",
                          color: "#1976D2",
                        }}
                      >
                        Teams Tab
                      </Typography>

                      <Typography sx={{ fontWeight: 400, fontSize: "12px" }}>
                        {detail?.id}/{modalDetails?.length}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#008EE2",
                        mb: 1,
                      }}
                    >
                      {detail?.text}
                    </Typography>
                    <Typography sx={{ fontSize: "14px" }}>
                      {detail?.sub_text}
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: page > 1 ? "space-between" : "right",
                        alignItems: "center",
                        mt: 3,
                      }}
                    >
                      {page > 1 && (
                        <Button
                          variant="outlined"
                          sx={{ textTransform: "inherit" }}
                          onClick={() => setPage(page - 1)}
                        >
                          Prev
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        sx={{ textTransform: "inherit" }}
                        onClick={() => {
                          if (page < modalDetails?.length) {
                            setPage(page + 1);
                          } else {
                            localStorage.setItem("teams", "teams");
                            onClose();
                          }
                        }}
                      >
                        {page === modalDetails?.length ? "Finish" : "Next"}
                      </Button>
                    </Box>
                  </Card>
                </>
              )}
            </Box>
          ))}
        </Box>
      </Modal>
    </>
  );
};

export default TeamsTootltipModal;
