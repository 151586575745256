import GithubInstallation from "./GithubInstallation/GithubInstallation";

const MiddlewareRoutes = [
  {
    route: "/auth/github/",
    component: <GithubInstallation />,
  },
];

export default MiddlewareRoutes;
