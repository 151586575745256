export const USER_ROLES = [
  {
    label: "Choose type",
    value: "",
  },
  {
    label: "Employee",
    value: "employee",
  },
  {
    label: "Project Manager",
    value: "project_manager",
  },
  {
    label: "Team Lead",
    value: "team_lead",
  },
];
export const USER_ROLES_ = [
  {
    label: "Employee",
    value: "employee",
  },
  {
    label: "Project Manager",
    value: "project_manager",
  },
  {
    label: "Team Lead",
    value: "team_lead",
  },
];


export const ISSUE_STATES = [
  {
    label: "Select State",
    value: "",
  },
  {
    label: "Open",
    value: "open",
  },
  {
    label: "Close",
    value: "close",
  },
];

export const COMPANY_SIZES = [
  {
    label: "0-10",
    value: 10,
  },
  {
    label: "11-50",
    value: 50,
  },
  { label: "51-80", value: 80 },
  {
    label: "81-100",
    value: 100,
  },
  {
    label: "101-150",
    value: 150,
  },
];

export const INDUSTRIES = [
  {
    label: "Finance",
    value: "finance",
  },
  {
    label: "Retail",
    value: "retail",
  },
  {
    label: "Gaming",
    value: "gaming",
  },
  {
    label: "Entertainment",
    value: "entertainment",
  },
  { label: "IT service", value: "IT service" },
  {
    label: "Health care",
    value: "health care",
  },
  {
    label: "Government agency",
    value: "government agency",
  },
  {
    label: "Others",
    value: "others",
  },
];
