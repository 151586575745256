import React from "react";
import EmailConnectionPage from "./EmailConnectionPage";
import WestIcon from "@mui/icons-material/West";
import { Button } from "@mui/material";
import Authenticator from "./Authenticator";

type Props = {
  type: string;
  goBack: any;
  values?: any;
  register: Function;
  errors: any;
  loading: boolean
};

const ConnectionPage = ({ type, goBack, values, register, errors, loading }: Props) => {
  return (
    <div>
      <Button
        variant="contained"
        startIcon={<WestIcon />}
        sx={{
          backgroundColor: "#008EE2",
          px: 7,
          py: 1.5,
          "&:hover": {
            backgroundColor: "#008EE2",
          },
        }}
        onClick={goBack}
      >
        Go Back
      </Button>
      {type === "email" ? (
        <EmailConnectionPage register={register} errors={errors} loading={loading}/>
      ) : (
        <Authenticator values={values} register={register} errors={errors} loading={loading} />
      )}
    </div>
  );
};

export default ConnectionPage;
