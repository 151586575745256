import * as types from "./types";

const initialState = {
  selectedTeam: undefined,
  teamList: [],
  selectedRole: undefined,
  teamSelected_: undefined,

};

const AuthReducer = (
  state = initialState,
  props: {
    type: string;
    payload: any;
  }
) => {
  const { type, payload } = props;

  switch (type) {
    case types.SET_TEAM_LIST:
      return { ...state, teamList: payload };

    case types.SET_SELECTED_TEAM:
      return { ...state, selectedTeam: payload };

    case types.SET_SELECTED_ROLE:
      return { ...state, selectedRole: payload };
      case types.SET_TEAM_SELECTED:
        return { ...state, teamSelected_: payload}
    default:
      return state;
  }
};

export default AuthReducer;
