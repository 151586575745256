import { useEffect, useState } from "react";
import Loader from "components/ui/Loader";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import { useAppContext } from "AppContext";
import FilterModal from "components/common/FilterModal/FilterModal";
import AdminTeamsCards from "../AdminTeams/AdminTeamsCards/AdminTeamsCards";
import { Box, Button, Typography } from "@mui/material";
import { AppTable } from "components/ui";
import DownloadIcon from "@mui/icons-material/Download";
import AdminUsersModals from "../AdminUsers/AdminUsersModals/AdminUsersModals";
import { MODAL_ACTIONS } from "components/Main/enums";

const { LOADING, IDLE, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;
const { CREATE_ACTION } = MODAL_ACTIONS;

const Index = () => {
  const [status, setStatus] = useState(IDLE);
  const [showModal, toggleModal] = useState(false);
  const { isModalOpen, closeModal, search } = useAppContext();
  const [errorMessage, setErrorMessage] = useState("");
  const [result, setResult] = useState([]);
  const [link, setLink] = useState("");

  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(0);

  const handleClose = () => {
    toggleModal(false);
    closeModal();
  };

  const getUsers = () => {
    setStatus(LOADING);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employees,
      params: {
        q: search,
        page: page,
      },
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const { results, count, per_page } = resp.data.data || {};
        setCount(count);
        setResult(results);
        setPerPage(per_page);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };

  const getAllPayroll = () => {
    setStatus(LOADING);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.allPayroll,
      params: {
        q: search,
        page: page,
      },
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const { results, count, per_page } = resp.data.data || {};
        setCount(count);
        setResult(results);
        setPerPage(per_page);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };

  const downloadPayroll = () => {
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.allPayroll,
      data: {},
    })
      .then((resp) => {
        setLink(resp.data.data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };

  const columns = [
    {
      title: "Employee Name",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.user}
        </Typography>
      ),
    },
    {
      title: "Hours worked",
      align: "left",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.hours_worked || 0}
        </Typography>
      ),
    },
    {
      title: "Base Salary",
      align: "left",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          &#8358;{row?.wages?.toLocaleString() || 0}
        </Typography>
      ),
    },
    {
      title: "Salary Bonus",
      align: "left",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          &#8358;{row?.salary_bonus?.toLocaleString() || 0}
        </Typography>
      ),
    },
    {
      title: "Salary Deduction",
      align: "left",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          &#8358;{row?.salary_deduction || 0}
        </Typography>
      ),
    },
    {
      title: "Total Salary Earned",
      align: "center",
      padding: "0 5px",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          &#8358;{row?.total_salary?.toLocaleString() || 0}
        </Typography>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getAllPayroll} message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            <AdminTeamsCards />
            {result?.length > 0 ? (
              <AppTable
                columns={columns}
                data={result}
                sorter={(a: any, b: any) => b?.id - a?.id}
                dataLength={count}
                noPerPage={per_page}
                page={page}
                onPageChange={handlePageChange}
                title={"Payroll"}
                showTitleBar
                actions={
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <Button
                      variant="contained"
                      startIcon={<DownloadIcon />}
                      sx={{
                        textTransform: "inherit",
                        backgroundColor: "#008EE2",
                        "&:hover": {
                          backgroundColor: "#008EE2",
                        },
                      }}
                      onClick={downloadPayroll}
                    >
                      Download table
                    </Button>
                  </a>
                }
              />
            ) : (
              <EmptyView message="No Employee Found" />
            )}
          </>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    getAllPayroll();
    downloadPayroll();
    // eslint-disable-next-line
  }, [search, page]);

  return (
    <>
      {renderBasedOnStage()}
      <AdminUsersModals
        onClose={handleClose}
        modalAction={CREATE_ACTION}
        isOpen={isModalOpen || showModal}
        handleSuccess={getUsers}
      />

      <FilterModal filterType="ROLE" />
    </>
  );
};

export default Index;
