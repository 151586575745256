import { Button } from "components/ui";
import Input from "components/ui/Input";
import Select from "components/ui/Select";
import "../TaskStyles.scss";

const CreateBlockerForm = (props: {
  register: Function;
  errors: any;
  milestone: any;
  selectedTask: any;
  isEdit: any;
  selectedProject: any;
  dataList: any;
  loading: any;
  result: any;
  status: boolean;
  blocked_by: any;
  status_: any;
  setStatus_: any;
  setBlockedBy: any;
  error: boolean;
  task: any;
  setTask: any;
}) => {
  const {
    register,
    errors,
    dataList,
    loading,
    result,
    status,
    blocked_by,
    setBlockedBy,
    status_,
    setStatus_,
    error,
    task,
    setTask,
  } = props;
  return (
    <div className="create-pc-modal-form">
      <div className=" p-y-1">
        <Select
          label="Task"
          register={register}
          hasError={errors?.task}
          isDisabled={status}
          name="task"
          placeholder="Task Blocked"
          value={task}
          onChange={(e: any) => setTask(e)}
          options={[
            ...dataList?.map(({ task_name, id }: any) => ({
              value: id,
              label: task_name,
            })),
          ]}
        />
        {error && task === null && (
          <span className="custom-select-error">Please select a task</span>
        )}
      </div>
      <div className="create-pc-modal-form__inputs p-y-1">
        <div>
          <Select
            label="Blocked By"
            register={register}
            hasError={errors?.blocked_by}
            name="blocked_by"
            isDisabled={status}
            value={blocked_by}
            onChange={(e: any) => setBlockedBy(e)}
            required
            isLoading={status}
            options={[
              ...result?.map(({ name, id }: any) => {
                return {
                  value: id,
                  label: name,
                };
              }),
            ]}
          />
          {error && blocked_by === null && (
            <span className="custom-select-error">Please select a user</span>
          )}
        </div>
        <br />
        <Input
          label="Description"
          placeholder="Description"
          name="description"
          type="textarea"
          register={register}
        />
      </div>
      <div className=" p-y-1">
        <Select
          label="Blocker Status"
          register={register}
          hasError={errors?.status}
          name="status"
          required
          value={status_}
          onChange={(e: any) => setStatus_(e)}
          options={[
            {
              label: "Active",
              value: "active",
            },
            {
              label: "Resolved",
              value: "iresolved",
            },
          ]}
        />
        {error && status_ === null && (
          <span className="custom-select-error">Please select a status</span>
        )}
      </div>
      <div className="admin-project_btn">
        <Button text="Submit" type="submit" isLoading={loading} />
      </div>
    </div>
  );
};

export default CreateBlockerForm;
