import { useState } from "react";

import ProjectOverview from "./ProjectOverview/ProjectOverview";
import ProjectList from "./common/ProjectList/ProjectList";

import PROJECT_SECTIONS from "./enum";
import "./Project.scss";
import { dataQueryStatuses } from "utils/api";
import EmptyView from "components/ui/EmptyView";
import SelectTeamModal from "./ProjectModals/SelectTeamModal/SelectTeamModal";
import Loader from "components/ui/Loader";
import AdminKPIsCards from "./AdminKPIs/AdminKPIsCards/AdminKPIsCards";
import { useSelector } from "react-redux";

const { OVERVIEW } = PROJECT_SECTIONS;

const subRoutes = [
  {
    title: "Overview",
    value: OVERVIEW,
  },
  // { title: "Test Suites", value: TEST_SUITES },
  // {
  //   title: "Test Plans",
  //   value: TEST_PLANS,
  // },
  // {
  //   title: "Configurations",
  //   value: CONFIGURATIONS,
  // },
];

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;

const Project = () => {
  const [selectedItem, selectItem] = useState(OVERVIEW);
  const { user } = useSelector((state: any) => state.auth);
  const [selectedProject, selectProject] = useState<{ id: any }>();
  const [status, setStatus] = useState(IDLE);
  const [modalAction, setModalAction] = useState("");
  const [showModal, toggleModal] = useState(false);
  const [showProjects, toggleProjects] = useState(false);
  const [milestone, setMilestone] = useState({});

  const [selectedTeam, selectTeam] = useState({});

  const renderBasedOnSelection = () => {
    switch (selectedItem) {
      case OVERVIEW:
        return (
          <ProjectOverview
            selectedProject={selectedProject}
            setModalAction={setModalAction}
            toggleModal={toggleModal}
            setMilestone={setMilestone}
          />
        );
      default:
        return "";
    }
  };

  const renderBasedOnStatus = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <EmptyView message="An error occurred" />;
      case DATAMODE:
        return <>{renderBasedOnSelection()}</>;
      default:
        return "";
    }
  };
  return (
    <>
      {(user?.employee?.role === "admin" || user?.employee?.role === "project_manager") && <AdminKPIsCards />}
      <div className="project">
        <div className="project__left">
          <ProjectList
            {...{
              selectedProject,
              selectProject,
              setStatus,
              status,
              showModal,
              toggleModal,
              modalAction,
              setModalAction,
              showProjects,
              toggleProjects,
              selectedTeam,
              milestone,
              setMilestone,
            }}
          />
        </div>
        <div className="project__details">
          {/* <ProjectHeader
            {...{ selectItem, selectedItem, subRoutes, toggleProjects }}
          /> */}
          {renderBasedOnStatus()}
        </div>
      </div>

      <SelectTeamModal selectTeam={selectTeam} />
    </>
  );
};

export default Project;
