import Dropdown from "react-bootstrap/Dropdown";
import "./Dropdown.scss";
import { ReactNode, useState } from "react";

interface Props {
  items: Array<{ text: string; handleAction?: Function }>;
  menuTitle: string;
  customButton?: ReactNode;
  handleSelect?: Function;
  data: any;
  id: any;
}

const DropdownMenu = (props: Props) => {
  const { items, menuTitle, customButton, handleSelect, data, id } = props;
  const [isOpen, toggleOpen] = useState(true);

  return (
    <>
      <Dropdown
        className="custom-dropdown"
        onMouseOut={() => toggleOpen(false)}
        onMouseOver={() => toggleOpen(true)}
      >
        <Dropdown.Toggle variant="success">
          {customButton || menuTitle}
        </Dropdown.Toggle>

        {isOpen && (
          <Dropdown.Menu>
            {items?.map(({ text, handleAction, ...rest }, key) => (
              <div key={key}>
                <Dropdown.Item
                  onClick={() => {
                    handleAction?.(data, id);
                    handleSelect?.({ text, ...rest });
                  }}

                  // className="active"
                >
                  {text}
                </Dropdown.Item>
              </div>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </>
  );
};

export default DropdownMenu;
