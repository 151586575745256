import toastMessage from "./toast";

export const helper = () => {};

export const getErrorMessage = (error: any) => {
  const response = error?.response;
  const defaultMssg = "Something went wrong. Please try again.";
  const has500xError = response?.status?.toString?.()?.includes?.("50");
  const errorMessage = has500xError
    ? defaultMssg
    : response?.data
    ? response?.data?.detail || response?.data?.message
    : defaultMssg;

  return errorMessage;
};

export const getFormData = (object: any) => {
  const formData = new FormData();
  Object.keys(object).forEach((key) => formData.append(key, object[key]));
  return formData;
};

export const getBase64 = (file?: any) => {
  // var reader = new FileReader();
  // reader.readAsDataURL(file);
  // reader.onload = function () {
  //   return reader.result;
  // };

  // reader.onerror = function (error) {
  // };

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

// dev
// export function getGitHubUrl(from: string) {
//   const rootURl = "https://github.com/apps/sprintstar-app/installations/new";

//   const options = {
//   };

//   const qs = new URLSearchParams(options);

//   return `${rootURl}?${qs.toString()}`;
// }

// export function getHerokuUrl(from: string) {
//   const rootURl =
//     "https://id.heroku.com/oauth/authorize?client_id=d67dc4d9-dd1a-49af-b30d-bde7afb99808&response_type=code&scope=read&state=1234";

//   const options = {
//     client_id: "Iv1.3cdbcd5f706e27d6" as string,
//     redirect_uri: "https://dev.sprintstar.io/" as string,
//     scope: "global",
//     state: from,
//   };

//   const qs = new URLSearchParams(options);

//   return `${rootURl}?${qs.toString()}`;
// }

// export function getGitlabUrl(from: string) {
//   const rootURl = "https://gitlab.com/oauth/authorize";

//   const options = {
//     client_id:
//       "02b33b9ca7d5e942b5a8f1412f637663cbeff16d5cb39f9159253768a3eac9ec" as string,
//     redirect_uri: "https://dev.sprintstar.io/settings/integrations" as string,
//     scope: "api",
//     state: from,
//     response_type: "code",
//   };

//   const qs = new URLSearchParams(options);

//   return `${rootURl}?${qs.toString()}`;
// }

// live
export function getGitHubUrl(from: string) {
  const rootURl = "https://github.com/apps/sprintstar-app/installations/new";

  const options = {};

  const qs = new URLSearchParams(options);

  return `${rootURl}?${qs.toString()}`;
}

export function getHerokuUrl(from: string) {
  const rootURl = "https://id.heroku.com/oauth/authorize";

  const options = {
    client_id: "1d1e6be7-31d2-444e-9741-87ecc07a5b14" as string,
    redirect_uri: "https://app.sprintstar.io/settings/integrations" as string,
    scope: "global",
    state: "1234",
    response_type: "code",
  };

  const qs = new URLSearchParams(options);

  return `${rootURl}?${qs.toString()}`;
}

export function getGitlabUrl(from: string) {
  const rootURl = "https://gitlab.com/oauth/authorize";

  const options = {
    client_id:
      "5cbe0ada7b9082def4d95209310976ece64ca97f6240d16a2d9c87c2929f8015" as string,
    redirect_uri: "https://app.sprintstar.io/settings/integrations" as string,
    scope: "api",
    state: from,
    response_type: "code",
  };

  const qs = new URLSearchParams(options);

  return `${rootURl}?${qs.toString()}`;
}

export const getFormatedDate = (
  reqDate: any,
  getTimeOnly?: boolean,
  getDateOnly?: boolean
) => {
  if (!reqDate) return "";
  let fullDate = new Date(reqDate);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  // const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const year = fullDate.getFullYear(); // 2019
  const date = fullDate.getDate();

  const monthName = months[fullDate.getMonth()];
  // const dayName = days[fullDate.getDay()];
  let hour = fullDate.getHours();

  let minute = fullDate.getMinutes();

  let minuteInWord;

  minuteInWord = minute < 10 ? `0${minute}` : minute;

  let timePostfix = hour > 12 ? "pm" : "am";

  hour = hour > 12 ? hour - 12 : hour;

  let formattedTime = `${hour}:${minuteInWord} ${timePostfix}`;

  let formattedDate = `${date} ${monthName} ${year}`;

  return getTimeOnly
    ? `${formattedTime}`
    : getDateOnly
    ? `${formattedDate}`
    : `${formattedDate}`;
};

interface Typ {}

export const getMonthName = (monthNo: number) => {
  const months: Typ = {
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sept",
    10: "Oct",
    11: "Nov",
    12: "Dec",
  };

  return months;
};

export const formatDatePickerDate = (reqDate: any) => {
  if (!reqDate) {
    return "";
  }
  var d = new Date(reqDate),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const getPercentage = (currentValue: number, expectedValue: number) => {
  return (currentValue / expectedValue) * 100;
};

// export const computeTeamActivityChart =()=>{

// }

const daysList: any = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednessday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const getDayMonthAndYearOfDate = (reqDate: any) => {
  if (!reqDate) {
    return "";
  }
  var d = new Date(reqDate),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    dayD = d.getDay(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return { year, month, day, dayName: daysList[dayD] };
};

export const isTokenInActive = () => {
  const currentDate = new Date();
  let rootStorage: any = localStorage.getItem("persist:root");

  if (!rootStorage) {
    return true;
  }

  rootStorage = JSON.parse(rootStorage);

  let { auth } = rootStorage || {};

  if (!auth) {
    return true;
  }

  auth = JSON.parse(auth);

  let { expiry } = auth;

  if (!expiry) {
    return true;
  }

  const expiryDate = new Date(expiry);

  if (currentDate > expiryDate) {
    return true;
  }

  return false;
};

export const generateRandomString = (length = 10) => {
  let result = "";
  let characters = "01234567898976543210";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const formatDate = (date: any) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export function setFirstTimeModal(name: string, value: boolean) {
  const d = new Date();
  d.setTime(d.getTime() + 30 * 24 * 60 * 60 * 1000);
  let expires = `expires=${d.toUTCString()}`;
  document.cookie = `token=${name};${expires};`;
  document.cookie = `${name}=${JSON.stringify(value)};${expires};`;
}

export function getFirstTimeModal(cname: string) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export function getDate(dayString: any) {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
}

export const copierHelper = (text: string) => {
  if (text) {
    navigator.clipboard.writeText(text);
    toastMessage("Copied Successfully");
  } else {
    toastMessage("Copied Successfully");
  }
};
