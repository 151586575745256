import assets from "assets";
import { ReactSVG } from "react-svg";
import "./TasksBreakdown.scss";

const TasksBreakdown = (props: any) => {
  const { task_breakdown } = props || {};
  console.log({task_breakdown});
  return (
    <section className="tasks-breakdown">
      <h3>Tasks breakdown</h3>
      {/* <div className="icon">
        <ReactSVG src={assets.icons.tasks} />
      </div> */}
      <h5>
        Tasks assigned <b>{task_breakdown?.assigned}</b>
      </h5>
      <div className="task-done">
        <span>Tasks done</span>
        <b>{task_breakdown?.completed}</b>
      </div>
      <div className="task-done">
        <span>Task in-progress</span>
        <b>{task_breakdown["in-progress"]}</b>
      </div>
    </section>
  );
};

export default TasksBreakdown;
