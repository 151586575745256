import Input from "components/ui/Input";
import { AvatarUpload, Button } from "components/ui";
import "./SignupProfileForm.scss";
import { useState } from "react";

const SignupProfileForm = (props: {
  register?: Function;
  errors?: any;
  loading?: any;
  file?: string;
  setFile?: Function;
}) => {
  const { register, errors, loading, file, setFile } = props;
  const [toggle, setToggle] = useState(true);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  return (
    <div className="signup-profile__inputs">
      <div className="signup-avatar">
        <AvatarUpload value={file} onChange={setFile} />
      </div>
      <div className="signup-profile">
        <div className="input_item">
          <Input
            label="Your first name?"
            name="first_name"
            hasError={errors?.first_name ? true : false}
            register={register}
            required={true}
          />
        </div>
        <div className="input_item">
          <Input
            label="Your last name?"
            name="last_name"
            hasError={errors?.last_name ? true : false}
            register={register}
            required={true}
          />
        </div>
        <div className="input_item">
          <Input
            label="Company's name?"
            name="company_name"
            hasError={errors?.company_name ? true : false}
            register={register}
            required={true}
          />
        </div>
        <div className="input_item">
          <Input
            label="Password"
            type={toggle ? "password" : "text"}
            name="password"
            hasError={errors?.password ? true : false}
            register={register}
            required={true}
            toggle={toggle}
            handleToggle={handleToggle}
            passwordIcon
          />
        </div>
      </div>

      <Button
        text="Submit"
        isLoading={loading}
        type="submit"
        isLoadingText="Please wait..."
      />
    </div>
  );
};

export default SignupProfileForm;
