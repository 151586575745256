import { Table } from "components/ui";

const data = [
  {
    task: "Onboarding",
    screens: 30,
    commits: 30,
    issues: 30,
    deployments: "30",
    kps: "30%",
    linesOfCode: "3000",
    tasks: 3,
  },
  {
    task: "Onboarding",
    screens: 30,
    commits: 30,
    issues: 30,
    deployments: "30",
    kps: "30%",
    linesOfCode: "3000",
    tasks: 3,
  },
  {
    task: "Onboarding",
    screens: 30,
    commits: 30,
    issues: 30,
    deployments: "30",
    kps: "30%",
    linesOfCode: "3000",
    tasks: 3,
  },
];

const StatisticsTable = () => {
  return (
    <Table
      head={[
        "Task",
        "Screens",
        "Commits",
        "Issues Resolved",
        "Deployments",
        "KPI",
        "Lines Of Code",
        "Task Completed",
      ]}
      body={data}
      showCheckbox
      isDeletable
    />
  );
};

export default StatisticsTable;
