import StatsCardsList from "components/common/StatsCardsList/StatsCardsList";
import ErrorView from "components/ui/ErrorView";
import Loader from "components/ui/Loader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";

const { IDLE, ERROR, LOADING, SUCCESS } = dataQueryStatuses;

const RisksCards = () => {
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");

  const [statsData, setStatsData] = useState<{
    total_project?: any;
    long_comment?: any;
    awaiting_review?: any;
    team_cost?: any;
    total_deployment?: any;
    task_completed?: any;
    merged_without_review?: any;
  }>({});

  const { selectedTeam } = useSelector((state: any) => state.filter);

  const getTeamMetricsData = (httpsRequest: any) => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.teamSummary(selectedTeam?.id),
      headers: {
        // signal: httpsRequest?.signal,
      },
    })
      .then((resp) => {
        const { data } = resp?.data || {};
        setStatus(SUCCESS);
        setStatsData(data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  const stats = [
    {
      title: "PRs Merged without review",
      value: statsData?.merged_without_review,
      report: "Last 30 days",
    },
    {
      title: "PRs with long comments",
      value: statsData?.long_comment,
      report: "Last 30 Days",
      background: "#7DCFB6",
    },
    {
      title: "PRs awaiting reviews for over 7 days",
      value: statsData?.awaiting_review,
      report: "Last 30 Days",
      background: "#008FE3",
    },
  ];

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case ERROR:
        return (
          <ErrorView handleRetry={getTeamMetricsData} message={errorMessage} />
        );
      case SUCCESS:
        return <StatsCardsList stats={stats} colNum={3} />;
      default:
        return "";
    }
  };

  useEffect(() => {
    let httpsRequest = new AbortController();
    getTeamMetricsData(httpsRequest);
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, [selectedTeam]);

  return <>{renderBasedOnStage()}</>;
};

export default RisksCards;
