import { useEffect, useState } from "react";
import "./ProjectList.scss";

import ProjectListCard from "./ProjectListCard/ProjectListCard";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import Loader from "components/ui/Loader";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import ProjectModals from "../../ProjectModals/ProjectModals";
import { useAppContext } from "AppContext";
import { MODAL_ACTIONS } from "components/Main/enums";
import { Search } from "components/ui";
import { ReactSVG } from "react-svg";
import assets from "assets";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;

const { CREATE_ACTION } = MODAL_ACTIONS;

const ProjectList = (props: {
  selectedProject: any;
  selectProject: Function;
  status: any;
  setStatus: Function;
  modalAction?: any;
  showModal: boolean;
  toggleModal: Function;
  setModalAction: Function;
  showProjects?: boolean;
  toggleProjects: Function;
  selectedTeam?: any;
  milestone?: any;
  setMilestone?: Function;
}) => {
  const {
    selectedProject,
    selectProject,
    status,
    setStatus,
    modalAction,
    showModal,
    toggleModal,
    setModalAction,
    showProjects,
    toggleProjects,
    selectedTeam,
    milestone,
    setMilestone,
  } = props;

  const [projects, setProjects] = useState([]);
  const [search, setSearch] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const getProjects = (httpsRequest: any) => {
    setStatus(LOADING);
    toggleProjects?.(false);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.projects,
      params: {
        team: selectedTeam?.id || undefined,
        q: search,
      },
    })
      .then((resp) => {
        const { data } = resp?.data || {};
        data?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
        setProjects(data);
        if (selectedProject) {
          const pp = data?.filter(({ id }: any) => selectedProject?.id === id);
          if (pp?.length === 1) {
            return selectProject(pp[0]);
          }
        }
        if (data?.length > 0) {
          selectProject(data[0]);
        }
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getProjects} message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            {projects?.map((projectInfo: any, key) => (
              <ProjectListCard
                isActive={selectedProject?.id === projectInfo?.id}
                projectInfo={projectInfo}
                handleSelect={selectProject}
                key={key}
              />
            ))}
          </>
        );
      default:
        return "";
    }
  };

  const { isModalOpen, closeModal } = useAppContext();

  useEffect(() => {
    let httpsRequest = new AbortController();
    getProjects(httpsRequest);
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, [selectedTeam?.id, search]);

  return (
    <>
      <section
        className={`project-list ${status === DATAMODE ? "" : "p-relative"} ${
          showProjects && projects?.length > 0 ? "show" : ""
        }`}
      >
        <div className="project-list__header">
          <span>Select Project</span>
          <ReactSVG
            src={assets.icons.cancel}
            className="cancel"
            onClick={() => toggleProjects(false)}
          />
        </div>
        <div>
        {/* <div className="project-list__cards"> */}
          <Search
            placeholder="Search project"
            value={search}
            className="search"
            handleSearch={(data: any) => setSearch(data)}
          />
          <div className="cards">{renderBasedOnStage()}</div>
        </div>
      </section>
      <ProjectModals
        modalAction={isModalOpen ? CREATE_ACTION : modalAction}
        onClose={() => {
          closeModal();
          setMilestone?.({});
          toggleModal(false);
          setModalAction("");
        }}
        handleSuccess={getProjects}
        isOpen={isModalOpen || showModal}
        selectedProject={selectedProject}
        milestone={milestone}
      />
    </>
  );
};

export default ProjectList;
