import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import DefaultModal from "../Modal/DefaultModal/DefaultModal";
import { ModalProps } from "../Modal/modal.d";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { updateAuthInfo } from "store/auth/actions";

const PremiumSwitchModal = (props: ModalProps) => {
  const { isOpen, onClose } = props;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);

  const updateAiUsage = (data: any) => {
    setLoading(true);
    API({
      method: httpVerbs?.PATCH,
      url: apiUrls?.updatePremiumAi(user?.employee?.company?.id),
      data: { premium_ai: false },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Successful!");
        const updatedUser = { ...user };
        updatedUser.employee.company = {
          ...updatedUser.employee.company,
          premium_ai: false,
        };
        dispatch<any>(updateAuthInfo({ user: updatedUser }));
        onClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg, true);
      });
  };
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      sx={{ width: 400, borderRadius: 5, overflow: "hidden" }}
      title="Switch to Free"
    >
      <Stack
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2, mt: 1 }}
      >
        <Typography>
          Are you sure you want to switch back to free AI?
        </Typography>

        <Typography sx={{ textAlign: "center", mt: 1 }}>
          Please click to contnue
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 4,
          }}
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#f91b0d",
              textTransform: "inherit",
              "&:hover": {
                backgroundColor: "#f91b0d",
              },
            }}
            onClick={onClose as any}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            sx={{ ml: 5, textTransform: "inherit" }}
            onClick={updateAiUsage}
          >
            {loading ? "loading...." : "Continue"}
          </Button>
        </Box>
      </Stack>
    </DefaultModal>
  );
};

export default PremiumSwitchModal;
