import { MetricDashboardPropsTypes } from "../../DashboardD.types";
import "./DTeamOverview.scss";

const DTeamOverview = (props: MetricDashboardPropsTypes) => {
  const { lead_name, selectedFilter, member_count, title } = props;
  return (
    <section className="dteam-overview">
      <div className="dteam-overview__layer">
        <span className="team-banner">{selectedFilter?.title || title}</span>
        {/* <b>{selectedFilter?.title || title}</b> */}
      </div>
      <div className="dteam-overview__layer">
        <span>Team members</span>
        <span className="team-count">
          {selectedFilter?.member_count || member_count}
        </span>
      </div>
      <div className="dteam-overview__layer">
        <span>Team lead</span>
        <span className="name">{lead_name || "N/A"}</span>
      </div>
    </section>
  );
};

export default DTeamOverview;
