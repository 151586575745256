import React from "react";
import { Box, Button, Card, Typography, useTheme } from "@mui/material";
import assets from "assets";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import EmptyView from "components/ui/EmptyView";

const Cards = (props: { allCards: any; getAllCards: any }) => {
  const { allCards, getAllCards } = props;
  const theme = useTheme();

  const DeleteCard = (id: number) => {
    API({
      method: httpVerbs?.DELETE,
      url: apiUrls?.deleteCard(id),
    })
      .then((resp) => {
        toastMessage("Card deleted successfully.");
        getAllCards?.();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg, true);
      });
  };

  const UpdateCard = (id: number) => {
    const body = {
      is_default: true,
    };
    API({
      method: httpVerbs?.PATCH,
      url: apiUrls?.updateCard(id),
      data: body,
    })
      .then((resp) => {
        toastMessage("Card Updated successfully.");
        getAllCards?.();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        toastMessage(errMsg, true);
      });
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start !important",
        width: "55%",
        mt: 4,
        [theme.breakpoints.down("md")]: {
          width: "80%",
        },
        [theme.breakpoints.down("sm")]: {
          display: "block",
          width: "100%",
        },
      }}
    >
      <Box>
        <Typography
          sx={{ fontWeight: 500, fontSize: "28px", color: "#000000" }}
        >
          Card details
        </Typography>
        <Typography
          sx={{ fontWeight: 400, fontSize: "14px", color: "#000000" }}
        >
          Select default credit card
        </Typography>
      </Box>
      {allCards?.length ? (
        <Box
          sx={{
            [theme.breakpoints.down("sm")]: {
              mt: 3,
            },
          }}
        >
          {allCards?.map((data: any, index: number) => (
            <Card
              elevation={0}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start !important",
                p: 2,
                mb: 2,
                width: "340px",
                height: "90px",
                background: "none",
                border: data?.is_default
                  ? "1.5px solid #000000"
                  : "1px solid #bdbaba",
                borderRadius: "10px",
                cursor: "pointer",
                [theme.breakpoints.down("sm")]: {
                  width: "87%",
                  height: "120px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ mt: "0.05rem" }}>
                  <img
                    src={
                      data?.card_type === "visa "
                        ? assets.icons.VisaLogo
                        : data?.card_type === "verve "
                        ? assets?.icons.VerveLogo
                        : assets?.icons.MasterCardLogo
                    }
                    alt="img"
                    style={{
                      width: data?.card_type === "verve " ? "50px" : "",
                      height: data?.card_type === "verve " ? "50px" : "",
                    }}
                  />
                </Box>
                <Box sx={{ ml: 2 }}>
                  <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>
                    {data?.card_type === "visa "
                      ? "Visacard"
                      : data?.card_type === "verve "
                      ? "Verve"
                      : "Mastercard"}{" "}
                    {""}{" "}
                    <Typography
                      component="span"
                      sx={{ fontSize: 16, fontWeight: "bold", ml: 2 }}
                    >
                      **** **** {data?.last4}
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{ fontSize: 16, fontWeight: "medium", mt: 0.5 }}
                  >
                    Expiry{""}
                    {""}
                    {""}
                    {data?.exp_month}/{data?.exp_year}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      // alignItems: "center",
                      mt: 1.5,
                    }}
                  >
                    {data?.is_default ? (
                      <Typography
                        sx={{ fontSize: 12, fontWeight: "medium", pt: 1 }}
                      >
                        Default
                      </Typography>
                    ) : (
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: "#008FE3",
                          color: "#008FE3",
                          textTransform: "inherit",
                          py: 0,
                          fontSize: 12,
                        }}
                        onClick={() => UpdateCard(data?.id)}
                      >
                        Set as default
                      </Button>
                    )}
                    {allCards?.length > 1 && (
                      <Box onClick={() => DeleteCard(data?.id)}>
                        <img
                          src={assets.icons.thrash}
                          alt="assets.icons.thrash"
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box>
                <img
                  src={
                    data?.is_default
                      ? assets.icons.ActiveCheckCircle
                      : assets.icons.InactiveCircle
                  }
                  alt="img"
                />
              </Box>
            </Card>
          ))}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mt: 15,
          }}
        >
          <EmptyView message="No Card Added Yet" />
        </Box>
      )}
    </Box>
  );
};

export default Cards;
