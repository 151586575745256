// import Select from "components/ui/Select";
import "./RepositoryMetrics.scss";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { MetricDashboardPropsTypes } from "../../DashboardD.types";

const RepositoryMetrics = (props: MetricDashboardPropsTypes) => {
  const { git_metric_chart } = props;

  const chartData = git_metric_chart?.map?.(({ month, year, ...rest }: any) => {
    return {
      year: `${month} ${year}`,
      month,
      ...rest,
    };
  });

  return (
    <section className="repository-metrics">
      <div className="repository-metrics__header">
        <h3>Code Repository Metrics</h3>
        {/* <Select
          value="2023"
          options={[
            {
              label: "2023",
              value: "2023",
            },
          ]}
        /> */}
      </div>
      <div className="repository-metrics__chart">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={chartData} width={100}>
            <CartesianGrid strokeDasharray="1 5" />
            <XAxis dataKey="year" />
            <YAxis dataKey={"month"} />
            <Tooltip />
            <Legend
              verticalAlign="top"
              height={30}
              align="left"
              iconSize={14}
            />
            <Bar
              dataKey="commits"
              fill="#008FE3"
              background={false}
              barSize={11}
            />
            <Bar dataKey="prs" fill="#A800E3" background={false} barSize={11} />
            <Bar
              dataKey="deployments"
              fill="#38E54D"
              background={false}
              barSize={11}
            />
            <Bar
              dataKey="lines"
              fill="#E6C139"
              background={false}
              barSize={11}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default RepositoryMetrics;
