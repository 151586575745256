import DashboardCodeMetrics from "./DashboardCostAnalysis/DashboardCodeMetrics";
import DashboardInfrastructure from "./DashboardInfrastructure/DashboardCodeMetrics";
import DashboardCompleted from "./DashboardTaskCompleted/DashboardCompleted";

import "./Dashboard.scss";
import { useEffect, useRef, useState } from "react";
import { MetricDashboardPropsTypes } from "../Metric/DashboardD/DashboardD.types";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import EmptyView from "components/ui/EmptyView";
import Loader from "components/ui/Loader";
import ErrorView from "components/ui/ErrorView";
import DashboardCards from "../Metric/DashboardD/DashboardCards/DashboardCards";
import { Box, useTheme } from "@mui/material";

const { IDLE, ERROR, NULLMODE, LOADING, DATAMODE } = dataQueryStatuses;

const Dashboard = () => {
  const [status, setStatus] = useState(IDLE);
  const [projects, setProjects] = useState<any>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [result, setResult] = useState<MetricDashboardPropsTypes>({});
  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<any>();
  const theme = useTheme();

  const selectedFilter = {
    title: "All Teams",
    member_count: "All",
    lead_details: {},
  };

  const getProjects = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.projects,
    })
      .then((resp) => {
        setLoading(false);
        const { data } = resp?.data || {};
        setProjects(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    let httpsRequest = new AbortController();
    getProjects();
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (projects?.length > 0) {
      setProject({
        label: projects[0]?.project_name,
        value: projects[0]?.id,
      });
    }
  }, [projects]);
  const getStatsData = () => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.dashboardMetrics,
      params: {},

      headers: {
        // signal: httpsRequest?.signal,
      },
    })
      .then((resp) => {
        const { data } = resp?.data || {};
        setStatus(DATAMODE);
        setResult(data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  useEffect(() => {
    getStatsData();
  }, []);

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getStatsData} message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            <DashboardCards
              {...result}
              projects={projects}
              loading={loading}
              project={project}
              setLoading={setLoading}
              setProject={setProject}
              selectedFilter={selectedFilter}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                [theme.breakpoints.down("sm")]: {
                  display: "block",
                  width: "100%",
                },
              }}
            >
              <DashboardCodeMetrics
                {...result}
                projects={projects}
                loading={loading}
                project={project}
                setLoading={setLoading}
                setProject={setProject}
                selectedFilter={selectedFilter}
              />
              <DashboardCompleted
                {...result}
                projects={projects}
                loading={loading}
                project={project}
                setLoading={setLoading}
                setProject={setProject}
                selectedFilter={selectedFilter}
              />
            </Box>
            <DashboardInfrastructure
              {...result}
              setLoading={setLoading}
              selectedFilter={selectedFilter}
            />
            <div className="dashboard__section"></div>
          </>
        );
      default:
        return "";
    }
  };

  return <div className="dashboard">{renderBasedOnStage()}</div>;
};

export default Dashboard;
