import { ReactSVG } from "react-svg";
import "./AdminViewKpiBreakdown.scss";
import assets from "assets";
import { LinearProgressBar, Table } from "components/ui";
import { getPercentage } from "utils/helper";
import { useState } from "react";

const AdminViewKpiBreakdown = (props: any) => {
  const [show, toggleShow] = useState(false);

  let { auto_kpis, manual_kpis } = props || {};
  auto_kpis = auto_kpis || [];
  manual_kpis = manual_kpis || [];
  const combinedKpis = [...auto_kpis, ...manual_kpis];

  const tableData = combinedKpis?.map(
    ({ indicator, expected, progress, is_met }: any) => {
      const currentPercent =
        progress >= 0 ? getPercentage(progress, expected) : is_met ? 100 : 0;
      return {
        indicator,
        expected: <>{expected}%</>,
        progress: (
          <LinearProgressBar
            currentPercent={currentPercent}
            label={progress >= 0 ? `${progress}%` : is_met ? "100%" : "0%"}
            showLabel
          />
        ),
      };
    }
  );
  return (
    <section className="admin-view-kpi-breakdown">
      <div className="admin-view-kpi-breakdown__header">
        <p>KPI Breakdown</p>
        <ReactSVG
          src={show ? assets.icons.arrowDown : assets.icons.arrowUp}
          onClick={() => toggleShow(!show)}
        />
      </div>

      {show && (
        <div className="admin-view-kpi-breakdown__table">
          <Table
            head={["Indicators", "Score", "Progress Score"]}
            body={tableData}
            hidePagination
          />
        </div>
      )}
    </section>
  );
};

export default AdminViewKpiBreakdown;
