import { AppTable } from "components/ui";
import { getFormatedDate } from "utils/helper";
import { MODAL_ACTIONS } from "components/Main/enums";
import EmptyView from "components/ui/EmptyView";
import "./PODetails.scss";
import { Box, Fade, Tooltip, Typography } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";

const { EDIT_OTHER_ACTION, DELETE_OTHER_ACTION } = MODAL_ACTIONS;

const MilestoneTable = (props: {
  data: any;
  page: number;
  setPage: Function;
  count: any;
  per_page: number;
  setModalAction: Function;
  toggleModal?: Function;
  setMilestone: Function;
}) => {
  const {
    data,
    count,
    page,
    setPage,
    per_page,
    setModalAction,
    toggleModal,
    setMilestone,
  } = props;

  const columns = [
    {
      title: "Milestone",
      render: (row: any) => (
        <Typography sx={{ fontSize: "12px", textTransform: "capitalize" }}>
          {row?.indicator} {""}
        </Typography>
      ),
    },
    {
      title: "Score",
      key: "score",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.score || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Employee Count",
      key: "employee",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.employee?.length || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Date Created",
      key: "date_created",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {getFormatedDate(row?.date_created, false, true) || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Action",
      render: (row: any) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Tooltip
            title="Update Milestone"
            placement="top-end"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <BorderColorIcon
              sx={{ width: 18, height: 18, marginRight: "1rem" }}
              onClick={() => {
                setMilestone(row);
                setModalAction(EDIT_OTHER_ACTION);
                toggleModal?.(true);
              }}
            />
          </Tooltip>
          <Tooltip
            title="Delete Milestone"
            placement="top-start"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
          >
            <DeleteIcon
              onClick={() => {
                setMilestone(row);
                setModalAction(DELETE_OTHER_ACTION);
                toggleModal?.(true);
              }}
            />
          </Tooltip>
        </Box>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  return (
    <>
      <section className="po-tasks">
        <div className="admin-user-table">
          {data?.length > 0 ? (
            <AppTable
              columns={columns}
              data={data}
              sorter={(a: any, b: any) => b?.id - a?.id}
              dataLength={count}
              noPerPage={per_page}
              page={page}
              onPageChange={handlePageChange}
            />
          ) : (
            <EmptyView message="No Milestone Found" />
          )}
        </div>
      </section>
    </>
  );
};

export default MilestoneTable;
