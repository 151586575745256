import "./ContributionGraph.scss";
import Calendar from "react-github-contribution-calendar";

const ContributionGraph = (props: { data: any }) => {
  const { data } = props;
  // var values = {
  //   "2023-06-23": 1,
  //   "2023-06-26": 2,
  //   "2023-06-27": 3,
  //   "2023-06-28": 4,
  //   "2023-06-29": 4,
  // };

  // var until = "2023-12-31";

  var panelColors = [
    "#ebebeb",
    "rgba(0,55,0,1)",
    "rgba(0,150,0,1)",
    "rgba(0,200,0,1)",
    "rgba(0,255,0,1)",
  ];

  const dataKeys = Object.keys(data) || 0;

  const lastDate = dataKeys[dataKeys?.length - 1];

  var weekLabelAttributes = {
    style: {
      "font-size": 10,
      "alignment-baseline": "central",
      fill: "#AAA",
    },
  };

  var monthLabelAttributes = {
    style: {
      "text-decoration": "underline",
      "font-size": 10,
      "alignment-baseline": "central",
      fill: "#AAA",
    },
  };

  return (
    <div className="contribution-graph">
      <Calendar
        // weekLabelAttributes={[]}
        // monthLabelAttributes={[]}
        weekLabelAttributes={weekLabelAttributes}
        monthLabelAttributes={monthLabelAttributes}
        panelAttributes={[]}
        weekNames={["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"]}
        panelColors={panelColors}
        values={data}
        until={lastDate}
      />
    </div>
  );
};

export default ContributionGraph;
