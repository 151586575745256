import { ReactNode, useEffect, useRef, useState } from "react";

import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LayoutHeader from "./LayoutHeader/LayoutHeader";
import LayoutSidebar from "./LayoutSidebar/LayoutSidebar";
import PageHeader from "components/ui/PageHeader/PageHeader";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Layout.scss";
import layoutRoutes from "./layoutRoutes";
import { AppContext } from "AppContext";
import { Box, Button, ButtonGroup } from "@mui/material";
import SprintstarAI from "../../Main/SprintstarAi/Index";
import Bot from "./Bot";
import { useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import { getAuthToken } from "storage";

type ObjectKey = keyof typeof layoutRoutes;

const Layout = (props: { children?: ReactNode }) => {
  const { children } = props;
  const { user } = useSelector((state: any) => state.auth);
  const [aIModalOpen, setAIModalOpen] = useState(false);

  const openAIModal = () => {
    setAIModalOpen(true);
  };
  const closeAIModal = () => {
    setAIModalOpen(false);
  };

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const path = pathname.substring(1);

  const hideLayout =
    path.includes("signup") ||
    path.includes("login") ||
    path.includes("onboarding") ||
    path.includes("two-fa") ||
    path.includes("forgot-password") ||
    path.includes("verify-email") ||
    path.includes("privacy-policy") ||
    path === "";

  // const isProjectRoute = path.includes?.("project");

  const firstSubPath = path.split("/")[0].toString() as ObjectKey;

  const [isModalOpen, toggleModal] = useState(false);
  const [isFilterOpen, toggleFilter] = useState(false);

  const [search, updateSearch] = useState("");
  const [filterList, setFilterList] = useState([]);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [taskCalendar, setTaskCalendar] = useState([]);
  const [projectCalendar, setProjectCalendar] = useState([]);
  const [showSidebar, toggleSidebar] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [type, setType] = useState("project");
  const accessToken = getAuthToken();

  const calendarRef = useRef<any>(null);

  const closeModal = () => {
    toggleModal(false);
  };

  const closeFilter = () => {
    toggleFilter(false);
  };

  const updateFilterList = (filters: any) => {
    setFilterList(filters);
  };

  useEffect(() => {
    if (pathname === "/") {
      navigate("/auth/login");
    }
  });

  const handleOpenCalendar = () => {
    setOpenCalendar(!openCalendar);
  };

  useEffect(() => {
    if (
      accessToken &&
      pathname !== "/" &&
      pathname !== "/auth/login" &&
      pathname !== "/auth/signup" &&
      pathname !== "/auth/onboarding" &&
      pathname !== "/auth/two-fa" &&
      pathname !== "/auth/forgot-password" &&
      pathname !== "/auth/verify-email" &&
      pathname !== "/privacy-policy"
    ) {
      const getAllCalendar = () => {
        API({
          method: httpVerbs?.GET,
          url: apiUrls?.projectCalendar,
        })
          .then((resp) => {
            setProjectCalendar(resp?.data?.data?.projects);
            setTaskCalendar(resp?.data?.data?.tasks);
          })
          .catch((err) => {
            const errMsg = getErrorMessage(err);
            toastMessage(errMsg, true);
          });
      };
      getAllCalendar();
    }
  }, [accessToken]);

  const modified_project_calendar = projectCalendar?.map((task: any) => {
    return {
      end: task?.expected_date,
      id: task?.id,
      start: task?.start_date,
      title: task?.project_name,
    };
  });

  const modified_task_calendar = taskCalendar?.map((task: any) => {
    return {
      end: task?.expected_date,
      id: task?.id,
      start: task?.start_date,
      title: task?.task_name,
    };
  });

  useEffect(() => {
    const dropEffectClick = (e: any) => {
      if (
        calendarRef.current !== null &&
        !calendarRef.current.contains(e.target)
      ) {
        setOpenCalendar(!openCalendar);
      }
    };
    if (calendarRef) {
      window.addEventListener("click", dropEffectClick);
    }
    return () => {
      window.removeEventListener("click", dropEffectClick);
    };
  }, [calendarRef]);

  return (
    <Box>
      <AppContext.Provider
        value={{
          search,
          isModalOpen,
          closeModal,
          filterList,
          updateFilterList,
          isFilterLoading,
          setIsFilterLoading,
          closeFilter,
          isFilterOpen,
        }}
      >
        <div className={hideLayout ? "" : "layout"}>
          <Box sx={{ position: "relative" }}>
            {!hideLayout && (
              <LayoutHeader
                toggleSidebar={() => toggleSidebar(!showSidebar)}
                handleOpenCalendar={handleOpenCalendar}
              />
            )}
            <Box>
              {openCalendar && (
                <Box>
                  <Box
                    sx={{
                      position: "absolute",
                      top: 90,
                      right: "9%",
                      zIndex: 1000000,
                      p: 4,
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <ButtonGroup sx={{ backgroundColor: "transparent" }}>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor:
                            type === "project" ? "#008EE2" : "transparent",
                          color: type === "project" ? "#FFFFFF" : "#000000",
                          textTransform: "inherit",
                          borderRadius: 0,
                          px: 3,
                          py: 1.4,
                          "&:hover": {
                            backgroundColor:
                              type === "project" ? "#008EE2" : "transparent",
                          },
                        }}
                        onClick={() => setType("project")}
                      >
                        Projects
                      </Button>
                      <Button
                        variant="contained"
                        sx={{
                          backgroundColor:
                            type === "task" ? "#008EE2" : "transparent",
                          color: type === "task" ? "#FFFFFF" : "#000000",
                          textTransform: "inherit",
                          borderRadius: 0,
                          px: 3,
                          py: 1.4,
                          "&:hover": {
                            backgroundColor:
                              type === "task" ? "#008EE2" : "transparent",
                          },
                        }}
                        onClick={() => setType("task")}
                      >
                        Tasks
                      </Button>
                    </ButtonGroup>
                  </Box>
                  <Box
                    sx={{
                      mt: 2,
                      position: "absolute",
                      top: 50,
                      right: 0,
                      width: "500px",
                      height: "700px",
                      zIndex: 100,
                      p: 4,
                    }}
                  >
                    <FullCalendar
                      // initialView="dayGridMonth"
                      // header={{
                      //   left: "prev,next",
                      //   center: "title",
                      //   right: "dayGridMonth,timeGridWeek,timeGridDay"
                      // }}
                      weekends={false}
                      themeSystem="Simplex"
                      plugins={[dayGridPlugin]}
                      events={
                        type === "project"
                          ? modified_project_calendar
                          : modified_task_calendar
                      }
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
          <div className={hideLayout ? "" : "layout__container"}>
            {!hideLayout && (
              <LayoutSidebar
                showSidebar={showSidebar}
                closeModal={() => {
                  toggleSidebar(false);
                  closeModal();
                }}
              />
            )}
            {!hideLayout && user?.employee?.role && (
              <div className="layout__page-header">
                <PageHeader
                  subRoutes={layoutRoutes[firstSubPath]?.routes}
                  {...layoutRoutes[firstSubPath]}
                  {...{
                    toggleModal,
                    updateSearch,
                    isModalOpen,
                    filterList,
                    toggleFilter,
                  }}
                  // preventSelectFirstItem={isProjectRoute}
                />
              </div>
            )}
            <main
              className={
                firstSubPath === "settings"
                  ? "push-up"
                  : firstSubPath === "help-support"
                  ? "help"
                  : firstSubPath === "dashboard"
                  ? "dashboard"
                  : ""
              }
              style={{ zIndex: 1 }}
            >
              {children}
              {pathname !== "/" &&
                pathname !== "/auth/login" &&
                pathname !== "/auth/signup" &&
                pathname !== "/auth/onboarding" &&
                pathname !== "/auth/two-fa" &&
                pathname !== "/auth/forgot-password" &&
                pathname !== "/auth/verify-email" &&
                pathname !== "/privacy-policy" && (
                  <>
                    <Box
                      sx={{
                        position: "fixed",
                        bottom: "5%",
                        right: 0,
                        cursor: "pointer",
                      }}
                      onClick={openAIModal}
                    >
                      {/* <img
                        src={assets.icons.AiLogo}
                        alt="aiLogo"
                        width="100"
                        height="`100"
                        style={{ borderRadius: "30%" }}
                      /> */}
                      <Bot />
                    </Box>
                    <SprintstarAI
                      open={aIModalOpen}
                      handleClose={closeAIModal}
                    />
                  </>
                )}
            </main>
          </div>
        </div>
        <ToastContainer />
      </AppContext.Provider>
    </Box>
  );
};

export default Layout;
