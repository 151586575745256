import { MetricDashboardPropsTypes } from "components/Main/Metric/DashboardD/DashboardD.types";
import { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";

const DashboardCodeRepMetric = (props: MetricDashboardPropsTypes) => {
  const { setLoading } = props;
  const [costs, setCosts] = useState([]);

  const GetTotalCosts = () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.getTotalCosts,
    })
      .then((resp) => {
        setLoading(false);
        const { data } = resp?.data || {};
        setCosts(data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
      GetTotalCosts();
  }, []);
  return (
    <section className="dashboard__chart">
      <div className="dashboard__chart__header">
        <h3>Infrastructures Cost</h3>
        <h3>Cost for the last one year</h3>
      </div>
      <div className="dashboard_sub_heading"></div>
      <div className="repository-metrics__chart">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={200}
            data={costs}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="amount" fill="#008EE2" />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default DashboardCodeRepMetric;
