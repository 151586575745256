import EmptyView from "components/ui/EmptyView";
import TeamDetailsCards from "./TeamDetailsCards/TeamDetailsCards";
import TeamDetailsTable from "./TeamDetailsTable/TeamDetailsTable";
import { useNavigate } from "react-router-dom";
import { useAppContext } from "AppContext";
import { useSelector } from "react-redux";
import SelectTeamModal from "../common/SelectTeamModal/SelectTeamModal";

const TeamDetails = () => {
  const { isFilterLoading } = useAppContext();
  const { selectedTeam } = useSelector((state: any) => state.filter);
  const navigate = useNavigate();

  const renderBasedOnTeamAvailability = () => {
    if (!isFilterLoading && !selectedTeam?.id) {
      return (
        <EmptyView
          message="No team to view metric data for"
          hasActionBtn
          actionBtnText="Create A Team"
          handleAction={() => navigate("/admin/teams/")}
        />
      );
    } else {
      return (
        <>
          <TeamDetailsCards />
          <TeamDetailsTable />
        </>
      );
    }
  };

  return (
    <>
      {renderBasedOnTeamAvailability()}
      <SelectTeamModal />
    </>
  );
};

export default TeamDetails;
