import { ReactNode } from "react";
import "./AuthHeader.scss";

const AuthHeader = (props: { title: ReactNode; subTitle?: any }) => {
  const { title, subTitle } = props;
  return (
    <section className="auth-header">
      <h1>{title}</h1>
      {subTitle && <h3>{subTitle}</h3>}
    </section>
  );
};

export default AuthHeader;
