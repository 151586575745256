import { USER_ROLES_ } from "components/Main/Settings/enums";
import { Button } from "components/ui";
import Input from "components/ui/Input";
import Select from "components/ui/Select";
import "../UserStyle.scss";

const AddNewUserForm = (props: {
  register: Function;
  errors: any;
  hideTeamSelection?: boolean;
  showGitInput?: boolean;
  isEdit?: boolean;
  handleClose: Function;
  loading: boolean;
  role: any;
  setRole: any;
  error: boolean
}) => {
  const { register, errors, showGitInput, isEdit, handleClose, loading, role, setRole, error } =
    props;

  return (
    <div className="create-user-form">
      <div className="form_items">
        <div className="item">
          <Input
            label="First Name"
            placeholder="Enter First Name"
            name="first_name"
            register={register}
            hasError={errors?.first_name}
            required
          />
        </div>
        <div className="item">
          <Input
            label="Last Name"
            placeholder="Enter Last Name"
            name="last_name"
            register={register}
            hasError={errors?.last_name}
            required
          />
        </div>
      </div>
      <div className="form_items">
        <div className="item">
          <Input
            label="Email address"
            placeholder="adet@gmail.com..."
            name="email"
            register={register}
            hasError={errors?.email}
            isDisabled={isEdit}
            type="email"
            required
          />
        </div>
        <div className="item">
          <Input
            label="Salary"
            placeholder="Enter Salary..."
            name="wages"
            register={register}
            hasError={errors?.wage || errors?.wages}
            minValue={1}
            required
            type="number"
          />
        </div>
      </div>

      <div className="form_items">
        <div className="item">
          <Input
            label="Start Date"
            name="start_date"
            register={register}
            hasError={errors?.start_date}
            type="date"
            required
          />
        </div>
        <div className="item">
          <Select
            options={USER_ROLES_}
            label="Role"
            placeholder="Text"
            name="role"
            register={register}
            hasError={errors?.role}
            value={role}
            onChange={(e: any) => setRole(e)}
            required
          />
           {error && role === null && (
          <span className="custom-select-error">Please select appropriate role</span>
        )}
        </div>
      </div>
      <div className="create-pc-modal-form__inputs">
        <div className="p-y-1">
          {showGitInput && (
            <Input
              label="Git Username"
              placeholder="github username"
              name="git_username"
              register={register}
              hasError={errors?.git_username}
            />
          )}
        </div>
      </div>
      <div className="admin-users_btn">
        <Button
          text="Submit"
          type="submit"
          className="submit"
          isLoading={loading}
        />
      </div>
    </div>
  );
};

export default AddNewUserForm;
