import Input from "components/ui/Input";
import "../Settings.scss";
import { Button } from "components/ui";

import { useForm } from "react-hook-form";
import API from "utils/api/API";
import { useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";

const PasswordManagement = () => {
  const [loading, setLoading] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const updatePassword = (data: any) => {
    setLoading(true);
    setErrMsg("");
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.updatePassword,
      data,
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Successfully updated");
        reset();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const onSubmit = (data: any) => {
    if (data?.new_password !== data?.confirm_password) {
      return setErrMsg("The two passwords do not match");
    }
    updatePassword(data);
  };

  return (
    <div className="settings">
      <h3>Update your Password</h3>
      <form className="settings__form" onSubmit={handleSubmit(onSubmit)}>
        {errMsg && <p className="error-message">{errMsg}</p>}
        <Input
          type="password"
          name="old_password"
          label="Old Password"
          placeholder="********"
          hasError={errors?.old_password ? true : false}
          register={register}
          required={true}
        />
        <Input
          type="password"
          name="new_password"
          label="New Password"
          placeholder="********"
          hasError={errors?.new_password ? true : false}
          register={register}
          required={true}
        />
        <Input
          type="password"
          name="confirm_password"
          label="Confirm Password"
          placeholder="********"
          hasError={errors?.confirm_password ? true : false}
          register={register}
          required={true}
        />
        <Button text="Update" type="submit" isLoading={loading} />
      </form>
    </div>
  );
};

export default PasswordManagement;
