import { ReactSVG } from "react-svg";
import "./LayoutSidebarItem.scss";
import { NavLink } from "react-router-dom";

const LayoutSidebarItem = (props: {
  title: String;
  icon: string;
  route: string;
  isOpen?: Boolean;
  closeModal?: Function;
}) => {
  const { title, icon, isOpen, route, closeModal } = props;
  return (
    <NavLink
      to={route}
      onClick={() => closeModal?.()}
      className={`layout-sidebar__item ${isOpen ? "active" : ""}`}
    >
      <ReactSVG
        src={icon}
        className={`layout-sidebar__item__left-icon ${
          isOpen ? "active__svg" : ""
        }`}
      />
      <span>{title}</span>
    </NavLink>
  );
};

export default LayoutSidebarItem;
