import { FILTER_OPTIONS } from "./enums";

interface IRoutesContent {
  canDownloadTable?: boolean;
  canCreate?: boolean;
  hasSearch?: boolean;
  hasFilter?: boolean;
  hasClockin?: boolean;
  createBtnText?: string;
  pageTitle?: string;
  hideRoutes?: boolean;
  filterName?: string;
  filterOption?: string;
  routes: Array<{
    title: string;
    route: string;
    canDownloadTable?: boolean;
    canCreate?: boolean;
    hasSearch?: boolean;
    hasFilter?: boolean;
    hasClockin?: boolean;
    createBtnText?: string;
    pageTitle?: string;
    hide?: boolean;
    filterName?: any;
    filterOption?: string;
  }>;
}

interface IRoutes {
  admin: IRoutesContent;
  dashboard: IRoutesContent;
  project: IRoutesContent;
  metrics: IRoutesContent;
  reports: IRoutesContent;
  settings: IRoutesContent;
  "help-support": IRoutesContent;
}

const { TEAM, ROLE } = FILTER_OPTIONS;
const layoutRoutes: IRoutes = {
  admin: {
    canDownloadTable: true,
    canCreate: true,
    hasFilter: true,
    hasSearch: true,
    filterName: "Choose Team",
    filterOption: TEAM,
    routes: [
      {
        title: "Employees",
        route: "/admin/users/",
        createBtnText: "Add new employee",
        hasSearch: true,
        hasFilter: true,
        pageTitle: "Employee Overview",
        filterName: "Choose Role",
        filterOption: ROLE,
      },
      {
        title: "Teams",
        route: "/admin/teams/",
        createBtnText: "Add new team",
        hasSearch: true,
        canDownloadTable: true,
        pageTitle: "Teams",
      },
      {
        title: "Payroll",
        route: "/admin/payroll/",
        createBtnText: "Add new employee",
        hasSearch: true,
        pageTitle: "Payroll",
      },
      {
        title: "Team Details",
        route: "/admin/team-details",
        createBtnText: "Add new user",
        hasSearch: true,
        hasFilter: true,
        canDownloadTable: true,
        pageTitle: "Team details",
        filterName: "Choose Team",
        hide: true,
        filterOption: ROLE,
      },
      {
        title: "Clock In",
        route: "/admin/clockin",
        hasSearch: true,
        canDownloadTable: true,
        hasClockin: true,
        pageTitle: "Clock in",
        hide: true,
      },
    ],
  },

  dashboard: {
    canDownloadTable: true,
    canCreate: true,
    routes: [],
    pageTitle: "Dashboard",
  },

  project: {
    canDownloadTable: true,
    canCreate: true,
    hasFilter: true,
    hasSearch: true,
    // createBtnText: "Add new project",
    filterName: "Choose Team",
    filterOption: TEAM,
    routes: [
      {
        title: "Project Overview",
        route: "/project/overview",
        createBtnText: "Create Project",
        hasSearch: true,
        hasFilter: false,
        canDownloadTable: true,
        pageTitle: "Project Overview",
        filterName: "Choose Team",
        filterOption: TEAM,
      },
      {
        title: "KPIs",
        route: "/project/kpis",
        createBtnText: "Set new KPI",
        hasSearch: true,
        hasFilter: false,
        canDownloadTable: true,
        pageTitle: "Projects",
        filterName: "Choose Team",
        filterOption: TEAM,
      },
      {
        title: "KPIs",
        route: "/project/kpis/*",
        createBtnText: "Set new KPI",
        hasSearch: true,
        hasFilter: true,
        canDownloadTable: true,
        pageTitle: "Team KPIs",
        hide: true,
        filterName: "Choose Team",
        filterOption: TEAM,
      },
    ],
  },

  metrics: {
    canDownloadTable: true,
    canCreate: true,
    createBtnText: "Add new project",
    routes: [
      {
        title: "Dashboard",
        route: "/metrics/overview/",
        hasSearch: false,
        hasFilter: true,
        pageTitle: "Dashboard",
        filterName: "Choose Team",
        filterOption: TEAM,
      },
      {
        title: "Team Details",
        route: "/metrics/team-details",
        createBtnText: "Add new user",
        hasSearch: true,
        hasFilter: true,
        canDownloadTable: true,
        pageTitle: "Team details",
        filterName: "Choose Team",
        filterOption: TEAM,
      },
      // {
      //   title: "Statistics",
      //   route: "/metrics/statistics",
      //   createBtnText: "Add new user",
      //   hasSearch: true,
      //   hasFilter: true,
      //   canDownloadTable: true,
      //   pageTitle: "Team Statistics",
      // },
      // {
      //   title: "Risks",
      //   route: "/metrics/risks",
      //   createBtnText: "",
      //   hasSearch: true,
      //   hasFilter: false,
      //   canCreate: false,
      //   canDownloadTable: true,
      //   // createBtnText:"",
      //   pageTitle: "Team Risks",
      // },
    ],
  },
  reports: { canDownloadTable: true, canCreate: true, routes: [] },
  settings: {
    hideRoutes: true,
    routes: [
      {
        title: "User settings",
        route: "/settings/user/",
        pageTitle: "Settings",
      },
      {
        title: "Company settings",
        route: "/settings/company",
        pageTitle: "Settings",
      },
      // {
      //   title: "Github Installation",
      //   route: "/settings/github",
      //   pageTitle: "Settings",
      // },
      // {
      //   title: "Gitlab",
      //   route: "/settings/gitlab",
      //   pageTitle: "Settings",
      // },
      {
        title: "Password Management",
        route: "/settings/password",
        pageTitle: "Settings",
      },
      {
        title: "Integrations",
        route: "/settings/integrations",
        pageTitle: "Integrations",
      },
      {
        title: "Subscriptions",
        route: "/settings/subscriptions",
        pageTitle: "Subscriptions",
      },
      {
        title: "Two-Factor Authentication",
        route: "/settings/mfa",
        pageTitle: "Two-Factor Authentication",
      },
      {
        title: "Payments & billing",
        route: "/settings/billings",
        pageTitle: "Payments & Billings",
      },
    ],
  },
  "help-support": {
    pageTitle: "Help & Support",
    hideRoutes: true,
    routes: [],
  },
};

export default layoutRoutes;
