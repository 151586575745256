import { Box, Button, Stack, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
// import { CheckCircle } from "@mui/icons-material";
import ActiveRadio from "assets/general/ActiveRadioIcon.svg";
import InactiveRadio from "assets/general/InactiveRadioIcon.svg";
import { useSelector } from "react-redux";
import EastIcon from "@mui/icons-material/East";
import ChangeMfaType from "components/ui/layout/modals/ChangeMfaType";

type Props = {
  type: string;
  setType: any;
  SetupMfa: any;
  error: boolean;
  loading: boolean;
};

const TwoFactorOptionPage = ({
  type,
  setType,
  SetupMfa,
  error,
  loading,
}: Props) => {
  const theme = useTheme();
  const { user } = useSelector((state: any) => state.auth);
  const [changeType, setChangeType] = useState(false);

  const handleChangeMfaClose = () => {
    setChangeType(false);
  };

  const twoFAAuthOptions = [
    {
      id: 1,
      header: "Google Authenticator App (TOTP)",
      description:
        "If you choose this option, we are going to use the email that is linked to this account as a Two-Factor Authentication. An OTP will then be sent to the email.",
      type: "authenticator",
      icon: "/img/totp.svg",
    },
    {
      id: 2,
      header: "Email Authentication",
      description:
        "If you choose this option, we are going to use the email that is linked to this account as a Two-Factor Authentication. An OTP will then be sent to the email.",
      type: "email",
      icon: "/img/email_auth.svg",
    },
  ];
  return (
    <Box
      sx={{
        width: "80%",
        [theme.breakpoints.down("xl")]: {
          width: "100%",
        },
      }}
    >
      <h1>Two-Factor Authentication (2FA)</h1>
      <p>
        Two-factor authentication (2FA) adds an extra layer of security to your
        authentication{" "}
      </p>
      <h4>Choose an authentication method</h4>
      <Box sx={{ flexGrow: 1, my: 5 }}>
        {twoFAAuthOptions?.map((item, index) => (
          <Box
            key={index}
            sx={{
              border: `${
                type === item.type ? `2px solid #008EE2` : "0.3px solid gray"
              }`,
              //   border: "1px solid gray",
              boxShadow:
                "-23px 170px 68px rgba(209, 209, 209, 0.01), -13px 95px 58px rgba(209, 209, 209, 0.05), -6px 42px 43px rgba(209, 209, 209, 0.09), -1px 11px 24px rgba(209, 209, 209, 0.1), 0px 0px 0px rgba(209, 209, 209, 0.1)",
              borderRadius: "8px",
              p: 3,
              mb: 5,
              cursor: "pointer",
            }}
            onClick={() => setType(item.type)}
          >
            <Stack
              direction={{ lg: "row", sm: "row", md: "row" }}
              justifyContent="space-around"
              alignItems="center"
            >
              <Box
                sx={{
                  width: "70%",
                  [theme.breakpoints.down("xl")]: {
                    width: "60%",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 15,
                    mb: 3,
                    color: "gray",
                  }}
                >
                  {item.header}
                  {/* {mfa_type === item.type && <CheckCircle color='primary' sx={{ ml: 1 }} />} */}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: 15,
                    color: "gray",
                  }}
                >
                  {item.description}
                </Typography>
              </Box>
              <Box
                sx={{
                  width: "20%",
                  [theme.breakpoints.down("xl")]: {
                    width: "10%",
                  },
                }}
              >
                {user?.mfa_type === item.type ? (
                  <img src={ActiveRadio} alt="ActiveRadio" />
                ) : (
                  <img src={InactiveRadio} alt="InactiveRadio" />
                )}
              </Box>
            </Stack>
          </Box>
        ))}
      </Box>
      {type === "" && error === true && (
        <p style={{ color: "#FF0000", fontSize: "12px", marginTop: "-1rem" }}>
          Please select an option
        </p>
      )}
      <Button
        variant="contained"
        endIcon={<EastIcon />}
        sx={{
          backgroundColor: "#008EE2",
          px: 7,
          py: 1.5,
          float: "right",
          "&:hover": {
            backgroundColor: "#008EE2",
          },
        }}
        disabled={loading || user?.mfa_type === type}
        onClick={() => {
          if (user?.mfa) {
            setChangeType(true);
          } else {
            SetupMfa();
          }
        }}
      >
        Next
      </Button>
      <ChangeMfaType
        isOpen={changeType}
        handleClose={handleChangeMfaClose}
        SetupMfa={SetupMfa}
        loading={loading}
        mfa_type={user?.mfa_type}
      />
    </Box>
  );
};

export default TwoFactorOptionPage;
