import { TableCell, TableRow } from '@mui/material';
import Loader from "../Loader";

const EmptyTable = () => {
    // const { description } = props;
    return (
        <TableRow>
            <TableCell colSpan={'100%' as any} sx={{ border: 0 }}>
                <Loader />
            </TableCell>
        </TableRow>
    );
};

export default EmptyTable;
