import React, { useEffect, useState } from "react";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import TrapFocus from "@mui/material/Unstable_TrapFocus";
import assets from "assets";
import {
  Box,
  Button,
  Fade,
  IconButton,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import SendIcon from "@mui/icons-material/Send";
import { useSelector } from "react-redux";
import Typewriter from "typewriter-effect";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import HourglassDisabledIcon from "@mui/icons-material/HourglassDisabled";

import "./Style.scss";

const Index = ({ open, handleClose }: any) => {
  const [loading, setLoading] = useState(false);
  const [userPrompt, setUserPrompts] = useState("");
  const [userInput, setUserInput] = useState("");
  const [conversation, setConversation] = useState<any>([]);
  const { user } = useSelector((state: any) => state.auth);
  const { employee, picture } = user || {};
  const theme = useTheme();

  const { company } = employee || {};

  const { id: companyId } = company || {};

  //handle Saving of Prompts
  const handlePromptSave = (message: any) => {
    setUserPrompts(message);
    setConversation([
      ...conversation,
      {
        id: conversation?.length + 1,
        text: message,
        type: "prompt",
      },
    ]);
    if (userInput) {
      setUserInput("");
    }
  };
  //handle input change
  const handleUserInput = (e: any) => {
    setUserInput(e.target.value);
  };

  const AiQuestionRequest = () => {
    setLoading(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.chatAi,
      data: {
        question: userPrompt,
        company: companyId,
      },
    })
      .then((resp) => {
        setUserPrompts("");
        setLoading(false);
        setConversation([
          ...conversation,
          {
            id: conversation?.length + 1,
            text: resp?.data?.data,
            type: "response",
          },
        ]);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    if (userPrompt) {
      AiQuestionRequest();
    }
  }, [userPrompt]);

  const handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      handlePromptSave(userInput);
    }
  };
  return (
    <TrapFocus open disableAutoFocus disableEnforceFocus>
      <Fade appear={true} in={open as any}>
        <Paper
          role="dialog"
          aria-modal="false"
          aria-label="Cookie banner"
          square
          variant="outlined"
          tabIndex={-1}
          sx={{
            position: "fixed",
            bottom: "7%",
            right: "3%",
            m: 0,
            pb: 1.5,
            width: "454px",
            maxHeight: "75vh",
            height: "100%",
            minHeight: "300px",
            borderRadius: "8px",
            backgroundColor: "#FFFFFF",
            [theme.breakpoints.down("md")]: {
              width: "350px",
              maxHeight: "90vh",
            },
          }}
        >
          <Box
            sx={{
              backgroundColor: "#F79256",
              py: 1.5,
              // pb: 3,
              px: 1.5,
              borderTopLeftRadius: "8px",
              borderTopRightRadius: "8px",
              position: "relative",
              zIndex: 100000000,
              [theme.breakpoints.down("md")]: {
                position: "relative",
                zIndex: 100000000,
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                {/* <img src={assets.icons.AiLogo} alt="aiLogo" width='25' height='25' /> */}

                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "40px",
                  }}
                >
                  <img
                    src={assets.icons.AiLogo}
                    alt="aiLogo"
                    width="50"
                    height="50"
                    style={{ borderRadius: "30%" }}
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{ fontSize: 14, fontWeight: 600, color: "#ffffff" }}
                  >
                    Ask Tee
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 13,
                      color: "#ffffff",
                      fontWeight: 500,
                    }}
                  >
                    {/* Hello, I am here to answer any of your question. */}
                    Hello! how can i help you?
                  </Typography>
                </Box>
              </Stack>

              <Box>
                <IconButton onClick={handleClose}>
                  <Close
                    sx={{
                      width: 25,
                      height: 25,
                      color: "#ffffff",
                    }}
                  />
                </IconButton>
              </Box>
            </Stack>
          </Box>
          <Box
            sx={{
              display: "grid",
              height: { md: "calc(100% - 170px)", xl: "calc(100% - 255px)" },
              px: 1.5,
              pt: 3,
              [theme.breakpoints.down("md")]: {
                height: "calc(100% - 180px)",
              },
            }}
          >
            <Box
              sx={{
                display: "grid",
                overflowY: "auto",
                [theme.breakpoints.down("md")]: {
                  overflowY: "scroll",
                },
                "&::-webkit-scrollbar": {
                  backgroundColor: "#f6f4f4",
                  width: "8px",
                  [theme.breakpoints.down("md")]: {
                    backgroundColor: "#f6f4f4",
                    width: "8px",
                  },
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                  [theme.breakpoints.down("md")]: {
                    backgroundColor: "transparent",
                  },
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#f6f4f4",
                  borderRadius: "10px",
                  [theme.breakpoints.down("md")]: {
                    backgroundColor: "#f6f4f4",
                    borderRadius: "10px",
                  },
                },
              }}
            >
              {conversation?.length > 0 ? (
                <>
                  {conversation?.map((convo: any, index: number) => (
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        justifySelf:
                          convo.type === "prompt" ? "flex-end" : "flex-start",
                        mb: 3,
                      }}
                    >
                      {convo.type === "response" && (
                        <img
                          src={assets.icons.AiLogo}
                          alt="aiLogo"
                          width="40"
                          height="40"
                          style={{ borderRadius: "30px" }}
                        />
                      )}
                      <Box
                        sx={{
                          backgroundColor:
                            convo.type === "prompt" ? "#ffffff" : "#002c61",
                          borderRadius:
                            convo.type === "prompt"
                              ? "12px 12px 0px 12px"
                              : "12px 12px 12px 0px",
                          width: "fit-content",
                          minHeight: "33px",
                          height: "fit-content",
                        }}
                      >
                        <Typography
                          sx={{
                            p: 1,
                            fontSize: 11,
                            fontWeight: 500,
                            color: convo.type === "prompt" ? "gray" : "#ffffff",
                          }}
                          key={index}
                        >
                          {convo.type !== "prompt" ? (
                            <Typewriter
                              onInit={(typewriter) => {
                                typewriter
                                  .typeString(convo?.text)
                                  .callFunction(() => {
                                    console.log("String typed out!");
                                  })
                                  .changeDelay(10)
                                  .start();
                              }}
                              options={{
                                loop: false,
                                delay: 10,
                                deleteSpeed: 0,
                              }}
                            />
                          ) : (
                            convo.text
                          )}
                        </Typography>
                      </Box>

                      {convo.type === "prompt" && (
                        <>
                          {picture ? (
                            <img
                              src={picture}
                              alt="picture"
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                                marginTop: "3rem",
                              }}
                            />
                          ) : (
                            <AccountCircleIcon />
                          )}
                        </>
                      )}
                    </Stack>
                  ))}
                </>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    [theme.breakpoints.down("md")]: {
                      mt: 20,
                    },
                  }}
                >
                  <HourglassDisabledIcon sx={{ width: 50, height: 50 }} />
                  <Typography>Start a conversation with Tee</Typography>
                </Box>
              )}
            </Box>
          </Box>
          <Stack
            direction="row"
            sx={{
              alignSelf: "end",
              mx: 1,
              pt: 1,
              borderTop: `1px solid #ede9e9`,
              position: "absolute",
              bottom: 5,
              width: "95%",
              [theme.breakpoints.down("md")]: {
                position: "absolute",
                bottom: 5,
              },
            }}
            // gap={2}
          >
            <input
              placeholder="Message Tee.."
              value={userInput}
              className="ai_input"
              onChange={handleUserInput}
              onKeyDown={handleKeyDown}
            />
            <Button
              variant="contained"
              sx={{
                minWidth: "unset",
                px: 1,
                basis: "10%",
                backgroundColor: "#F79256",
              }}
              onClick={() => handlePromptSave(userInput)}
              disabled={loading}
            >
              <SendIcon />
            </Button>
          </Stack>
        </Paper>
      </Fade>
    </TrapFocus>
  );
};

export default Index;
