import CompanySettings from "./CompanySettings/CompanySettings";
// import GithubSettings from "./GithubSettings/GithubSettings";
// import GitlabSettings from "./GitlabSettings/GitlabSettings";
import PasswordManagement from "./PasswordManagement/PasswordManagement";
import Integrations from "./Integrations/Integrations";
import UserSettings from "./UserSettings/UserSettings";
import Subscriptions from './Subscriptions/Index';
import TwoFA from './TwoFASettings/Index';
import Billings from './Billings/Index';

const SettingsRoutes = [
  {
    title: "User settings",
    component: <UserSettings />,
    route: "/settings/user/",
  },
  {
    title: "Company settings",
    component: <CompanySettings />,
    route: "/settings/company",
  },
  {
    title: "Password Management",
    component: <PasswordManagement />,
    route: "/settings/password",
  },
  {
    title: "Integrations",
    component: <Integrations />,
    route: "/settings/integrations",
  },
  {
    title: "Subscriptions",
    component: <Subscriptions />,
    route: "/settings/subscriptions",
  },
  {
    title: "2 Factor Authentication",
    component: <TwoFA />,
    route: "/settings/mfa",
  },
  {
    title: "Payments & billing",
    component: <Billings />,
    route: "/settings/billings",
  },
  // {
  //   title: "Github Installation",
  //   component: <GithubSettings />,
  //   route: "/settings/github",
  // },
  // {
  //   title: "Gitlab",
  //   component: <GitlabSettings />,
  //   route: "/settings/gitlab",
  // },
];

export default SettingsRoutes;
