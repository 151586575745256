import React from "react";
import AppViewModal from "./AppViewModal";
import { Box, Typography } from "@mui/material";

const ProjectGuideModal = (props: any) => {
  const { open, handleClose } = props;
  return (
    <AppViewModal open={open} handleClose={handleClose} title="Project Guide">
      <Box sx={{ mt: 3, mx: 3 }}>
        <Typography
          sx={{ color: "#64748B", fontSize: "15px", fontWeight: 400, mb: 1 }}
        >
          The Project Feature provides the flexibility to create as many
          projects as needed, assign employees and teams to tasks, and monitor
          their progress.
        </Typography>

        <Typography
          sx={{ color: "#64748B", fontSize: "15px", fontWeight: 400 }}
        >
          To create a project, begin by creating a team. This allows you to
          group your organization's employees into departments, ensuring that
          when you create a project, you can add the appropriate team to execute
          it effectively
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#3498db", fontSize: "14px", fontWeight: 700 }}
          >
            Project Milestone:
          </Typography>
          <Typography
            sx={{
              color: "#64748B",
              fontSize: "13px",
              fontWeight: 400,
              mt: 1,
            }}
          >
            This feature helps you break tasks into stages, making them easily
            achievable
          </Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#3498db", fontSize: "14px", fontWeight: 700 }}
          >
            Project Tasks:
          </Typography>
          <Typography
            sx={{ color: "#64748B", fontSize: "13px", fontWeight: 400, mt: 1 }}
          >
            This feature acts as a breakpoint for milestones. To create a task,
            you must first create a milestone.
          </Typography>
        </Box>
      </Box>
    </AppViewModal>
  );
};

export default ProjectGuideModal;
