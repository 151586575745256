import { Typography } from "@mui/material";
import AuthLayout from "../AuthLayout/AuthLayout";
// import AuthButtonActions from "../common/AuthButtonActions/AuthButtonActions";
import AuthFooter from "../common/AuthFooter/AuthFooter";
import AuthHeader from "../common/AuthHeader/AuthHeader";
import LoginForm from "./LoginForm/LoginForm";

const Login = () => {
  return (
    <AuthLayout>
      <AuthHeader
        title={
          <>
            <Typography
              sx={{
                fontSize: "30px",
                fontWeight: "700",
                textAlign: "center",
                color: "#008ee2",
              }}
            >
              Login
            </Typography>
          </>
        }
      />
      <LoginForm />
      <AuthFooter
        text={"Dont have an account?"}
        linkText={"Sign Up"}
        link={"/auth/signup"}
      />
    </AuthLayout>
  );
};

export default Login;
