import { useEffect, useState } from "react";
import { MetricDashboardPropsTypes } from "../../DashboardD.types";
import "./TasksManaged.scss";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import Loader from "components/ui/Loader";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import { useNavigate } from "react-router-dom";

const { IDLE, ERROR, NULLMODE, LOADING, DATAMODE } = dataQueryStatuses;

const TasksManaged = (props: MetricDashboardPropsTypes) => {
  const { id } = props;
  const [tasksList, setTasksList] = useState([]);

  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");

  const getTasksList = (httpsRequest: any) => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: `${apiUrls?.projectTasks}`,
      params: {
        team: id,
      },

      headers: {},
    })
      .then((resp) => {
        const { results } = resp?.data?.data || {};
        setStatus(DATAMODE);
        results?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
        setTasksList(results);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return <ErrorView handleRetry={getTasksList} message={errorMessage} />;
      case DATAMODE:
        return (
          <div className="tasks-managed__list">
            {tasksList
              ?.slice(0, 5)
              ?.map(
                ({
                  label,
                  task_name,
                  points,
                }: {
                  task_name: string;
                  label: string;
                  points: number;
                }) => (
                  <div className="tasks-managed__category">
                    <div className="tasks-managed__category__name-block">
                      <span className="picture">
                        {task_name?.substring?.(0, 1)}
                      </span>
                      <div>
                        <h5>{task_name}</h5>
                        <span className="email">{label}</span>
                      </div>
                    </div>
                    <span className="count">{points}</span>
                  </div>
                )
              )}
          </div>
        );
      default:
        return "";
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    let httpsRequest = new AbortController();
    getTasksList(httpsRequest);
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <section className="tasks-managed">
      <div className="tasks-managed__header">
        <h3>Task managed</h3>
        <span onClick={() => navigate("/project")}>See all</span>
      </div>
      {renderBasedOnStage()}
      {tasksList?.length > 4 && <p>Please scroll down</p>}
    </section>
  );
};

export default TasksManaged;
