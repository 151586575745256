import { USER_ROLES } from "components/Main/Settings/enums";
import { Button } from "components/ui";
import Input from "components/ui/Input";
import Select from "components/ui/Select";

const AddNewUserForm = (props: {
  register: Function;
  errors: any;
  showGitInput?: boolean;
  loading: boolean
}) => {
  const { register, errors, showGitInput, loading } = props;

  return (
    <div className="create-pc-modal-form">
      <div className="create-pc-modal-form__inputs">
        <div className="p-y-1">
          <Input
            label="First Name"
            placeholder="Enter First Name"
            name="first_name"
            register={register}
            hasError={errors?.first_name}
            required
          />
        </div>
        <div className="p-y-1">
          <Input
            label="Last Name"
            placeholder="Enter Last Name"
            name="last_name"
            register={register}
            hasError={errors?.last_name}
            required
          />
        </div>
      </div>
      <div className="create-pc-modal-form__inputs">
        <div className="p-y-1">
          <Input
            label="Email address"
            placeholder="adet@gmail.com..."
            name="email"
            register={register}
            hasError={errors?.email}
            type="email"
            required
          />
        </div>
        <div className="p-y-1">
          <Input
            label="Salary"
            placeholder="Enter Salary..."
            name="wages"
            register={register}
            hasError={errors?.wage || errors?.wages}
            minValue={1}
            required
            type="number"
          />
        </div>
      </div>

      <div className="create-pc-modal-form__inputs">
        <div className="p-y-1">
          <Input
            label="Start Date"
            name="start_date"
            register={register}
            hasError={errors?.start_date}
            type="date"
            required
          />
        </div>
      </div>
      <div className="create-pc-modal-form__inputs">
        <div className="p-y-1">
          <Select
            options={[{ label: "Select Role", value: "" }, ...USER_ROLES]}
            label="Role"
            placeholder="Text"
            name="role"
            register={register}
            hasError={errors?.role}
            required
          />
        </div>
        <div className="p-y-1">
          {showGitInput && (
            <Input
              label="Git Username"
              placeholder="Text"
              name="git_username"
              register={register}
              hasError={errors?.git_username}
            />
          )}
        </div>
      </div>
      <div className="admin-users_btn">
        <Button text="Submit" type="submit" className="submit" isLoading={loading} />
      </div>
    </div>
  );
};

export default AddNewUserForm;
