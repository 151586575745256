import { useAppContext } from "AppContext";
import AdminUsersModals from "components/Main/Admin/AdminUsers/AdminUsersModals/AdminUsersModals";
import { MODAL_ACTIONS } from "components/Main/enums";
import { AppTable } from "components/ui";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import Loader from "components/ui/Loader";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage, getFormatedDate } from "utils/helper";
import { Button } from "components/ui";
import { useNavigate } from "react-router-dom";
import assets from "assets";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ReactSVG } from "react-svg";
import BorderColorIcon from "@mui/icons-material/BorderColor";

const { IDLE, ERROR, NULLMODE, LOADING, DATAMODE } = dataQueryStatuses;

const { CREATE_ACTION, EDIT_ACTION, DELETE_ACTION } = MODAL_ACTIONS;

const TeamDetailsTable = () => {
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();

  const [dataList, setDataList] = useState([]);
  const { isModalOpen, closeModal, search } = useAppContext();
  const { teamSelected } = useSelector((state: any) => state.team);

  const [showModal, toggleModal] = useState(false);
  const [selectedUser, selectUser] = useState({});
  const [modalAction, setModalAction] = useState("");

  const NO_PER_PAGE = 5;
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);

  const getTeamMetricsData = (pageNo?: number) => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.teamDetails,
      params: { team: teamSelected?.id, page: pageNo, q: search },
      headers: {
        // signal: httpsRequest?.signal,
      },
    })
      .then((resp) => {
        const { results, count } = resp?.data?.data || {};
        results?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
        setDataList(results);
        setCount(count);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  const columns = [
    {
      title: "Name",
      render: (row: any) => (
        <Typography sx={{ fontSize: "12px", textTransform: "capitalize" }}>
          {row?.user?.name} {""}
        </Typography>
      ),
    },
    {
      title: "Email",
      key: "email",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.user?.email || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Git Username",
      key: "git_username",
      width: "400",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.git_username || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Issue Solved",
      key: "issues_resolved",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.issues_resolved || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Task Completed",
      key: "tasks_completed",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.tasks_completed || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Commits",
      key: "commit_count",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.commit_count || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Wages",
      key: "wages",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.wages || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Deployment",
      key: "deployment",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.deployment || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Role",
      key: "role",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.role || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Start Date",
      key: "start_date",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {getFormatedDate(row?.start_date) || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Action",
      render: (row: any) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <BorderColorIcon
            sx={{ width: 18, height: 18, marginRight: 1 }}
            onClick={() => {
              selectUser(row);
              setModalAction(EDIT_ACTION);
              toggleModal(true);
            }}
          />
          <ReactSVG
            style={{ marginTop: "0.5rem" }}
            src={assets.icons.thrash}
            onClick={() => {
              selectUser(row);
              setModalAction(DELETE_ACTION);
              toggleModal(true);
            }}
          />
        </Box>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;

      case ERROR:
        return (
          <ErrorView handleRetry={getTeamMetricsData} message={errorMessage} />
        );
      case DATAMODE:
        return (
          <>
            <div className="back_div">
              <Button
                text="Back"
                className="back__button"
                icon={assets.icons.arrowLeft}
                onClick={() => navigate("/admin/teams/")}
              />
              <h3>Team:  {teamSelected?.title}</h3>
            </div>
            <br />

            <AppTable
              columns={columns}
              data={dataList}
              sorter={(a: any, b: any) => b?.id - a?.id}
              dataLength={count}
              noPerPage={NO_PER_PAGE}
              page={page}
              onPageChange={handlePageChange}
            />
          </>
        );
      default:
        return "";
    }
  };

  const handleClose = () => {
    selectUser({});
    toggleModal(false);
    closeModal();
  };

  useEffect(() => {
    let httpsRequest = new AbortController();
    if (teamSelected?.id) {
      getTeamMetricsData();
    }
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, [teamSelected?.id, search, page]);

  return (
    <>
      {renderBasedOnStage()}
      <AdminUsersModals
        onClose={handleClose}
        modalAction={isModalOpen ? CREATE_ACTION : modalAction}
        isOpen={isModalOpen || showModal}
        selectedUser={selectedUser}
        handleSuccess={getTeamMetricsData}
        selectedTeam={teamSelected}
        hideTeamSelection
      />
    </>
  );
};

export default TeamDetailsTable;
