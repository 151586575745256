import { useEffect, useState } from "react";
import AuthLayout from "../AuthLayout/AuthLayout";
import { ONBAORDING_HEADER, ONBAORDING_STAGES } from "./enums";
import OnboardingTeamInfo from "./OnboardingTeamInfo/OnboardingTeamInfo";
import AuthHeader from "../common/AuthHeader/AuthHeader";
import GitSetup from "./GitSetup/GitSetup";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import { isTokenInActive } from "utils/helper";
import AuthFooter from "../common/AuthFooter/AuthFooter";
import './OnboardingStyles.scss'

const { TEAM_INFO, GIT_SETUP } = ONBAORDING_STAGES;

const Onboarding = () => {
  const { gitInstalled }: any = queryString.parse(window.location.search) || {};

  const [stage, setStage] = useState(gitInstalled ? GIT_SETUP : TEAM_INFO);

  const [selectedRole, selectRole] = useState("admin");

  const navigate = useNavigate();

  const tokenHasExpired = isTokenInActive();

  const handleContinue = () => {
    if (stage === TEAM_INFO && selectedRole === "admin") {
      return navigate("/dashboard");
    }

    if (stage === TEAM_INFO) {
      return setStage(GIT_SETUP);
    }

    if (stage === GIT_SETUP) {
      return navigate("/dashboard");
    }
  };

  const renderBasedOnStage = () => {
    switch (stage) {
      case TEAM_INFO:
        return (
          <OnboardingTeamInfo
            handleContinue={handleContinue}
            request={{ role: selectedRole }}
          />
        );
      case GIT_SETUP:
        return <GitSetup handleContinue={handleContinue} />;
      default:
        return "";
    }
  };

  useEffect(() => {
    if (tokenHasExpired) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [tokenHasExpired]);

  return (
    <AuthLayout>
      <AuthHeader
        title={ONBAORDING_HEADER?.[stage]?.title}
        subTitle={ONBAORDING_HEADER?.[stage]?.text}
      />
      {renderBasedOnStage()}
      <AuthFooter
        text={""}
        // bottomText={
        //   <div className="bottom_div">
        //     <span
        //       className={`${
        //         stage === TEAM_INFO || stage === GIT_SETUP
        //           ? "primary-color"
        //           : ""
        //       }`}
        //     >
        //       About your team
        //     </span>
        //     {"  "}/{"  "}
        //     <span className={`${stage === GIT_SETUP ? "primary-color" : ""}`}>
        //       Set-up
        //     </span>
        //   </div>
        // }
        hideLastText
        linkText={""}
      />
    </AuthLayout>
  );
};

export default Onboarding;
