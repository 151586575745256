import assets from "assets";
import Input from "components/ui/Input";
import Select from "components/ui/Select";
import Textarea from "components/ui/Textarea";
import { ReactSVG } from "react-svg";
import { getErrorMessage, getFormatedDate } from "utils/helper";
import { TASK_POINTS, TASK_PRIORITIES, TASK_STATUS } from "../enums";
import { useEffect, useState } from "react";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";

const ViewPOTaskForm = (props: {
  selectedTask: any;
  selectedProject: any;
  toggleEditDetails: Function;
  editDetails: boolean;
  toggleEditCriteria: Function;
  register: Function;
  editCriteria: boolean;
  loadingMembers: boolean;
  toggleEditStatus: Function;
  projectStatus: boolean;
  toggleEditPoints: Function;
  editPoints: boolean;
  setLoadingMembers: Function;
}) => {
  const {
    selectedTask,
    selectedProject,
    toggleEditDetails,
    editDetails,
    toggleEditCriteria,
    editCriteria,
    register,
    loadingMembers,
    toggleEditStatus,
    projectStatus,
    editPoints,
    toggleEditPoints,
    setLoadingMembers,
  } = props;

  const [teamMembers, setTeamMembers] = useState([]);

  const getTeamMembers = () => {
    setLoadingMembers(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.employee,
      params: {
        team: selectedProject?.team?.id,
        all: true,
      },
    })
      .then((resp) => {
        const { results } = resp.data?.data || {};
        setTeamMembers(results);
        setLoadingMembers(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoadingMembers(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    getTeamMembers();
    // eslint-disable-next-line
  }, [selectedProject, selectedTask]);

  return (
    <>
      <div className="view-po-task">
        <section className="view-po-task__heading">
          <div className="icon">
            <ReactSVG src={assets.icons.project} />
          </div>
          <div>
            <h4>{selectedTask?.label}</h4>
            <p>
              Team members: <b>{selectedProject?.team?.member_count}</b>
            </p>
            <p>
              Project Progress: <b>{selectedProject?.status}</b>
            </p>
          </div>
          <span>{selectedProject?.team?.title}</span>
        </section>
        <section>
          <h3>
            Details
            <ReactSVG
              src={assets.icons.edit}
              onClick={() => toggleEditDetails(true)}
            />
          </h3>
          {editDetails ? (
            <Input label="Update Details" name="details" register={register} />
          ) : (
            <p>{selectedTask?.details || "N/A"}</p>
          )}
        </section>

        <section>
          <h3>
            Acceptance Criteria
            <ReactSVG
              src={assets.icons.edit}
              onClick={() => toggleEditCriteria(true)}
            />
          </h3>
          {editCriteria ? (
            <Textarea
              label="Update Criteria"
              name="acceptance_criteria"
              register={register}
            />
          ) : (
            <p>{selectedTask?.acceptance_criteria || "N/A"}</p>
          )}
        </section>

        <section>
          <h3>Break Down</h3>
          <div className="view-po-task__breakdown">
            <div className="view-po-task__breakdown__header">
              <span>Details</span>
              <span>Info</span>
            </div>

            <div className="view-po-task__breakdown__row">
              <span>Priority</span>
              <span>
                <Select
                  label=""
                  options={TASK_PRIORITIES}
                  name="priority"
                  register={register}
                />
              </span>
            </div>

            <div className="view-po-task__breakdown__row">
              <span>Assigned</span>
              <span>
                <Select
                  label=""
                  isDisabled={loadingMembers}
                  value={selectedTask?.assigned}
                  options={[
                    // {
                    //   label: loadingMembers ? "Please wait" : "Choose Member",
                    //   value: undefined,
                    // },
                    ...teamMembers?.map(({ id, full_name }) => {
                      return {
                        label: full_name,
                        value: id,
                      };
                    }),
                  ]}
                  name="assigned"
                  register={register}
                />
              </span>
            </div>

            <div className="view-po-task__breakdown__row">
              <span>Created on</span>
              <span>{getFormatedDate(selectedTask?.date_created)}</span>
            </div>

            <div className="view-po-task__breakdown__row">
              <span>
                Story Points
                <ReactSVG
                  src={assets.icons.edit}
                  onClick={() => toggleEditPoints(!editPoints)}
                />
              </span>
              {editPoints ? (
                <Select
                  name="points"
                  label=""
                  register={register}
                  options={TASK_POINTS}
                />
              ) : (
                <span>{selectedTask?.points}</span>
              )}
            </div>

            <div className="view-po-task__breakdown__row">
              <span>
                Status
                <ReactSVG
                  src={assets.icons.edit}
                  onClick={() => toggleEditStatus(!projectStatus)}
                />
              </span>
              {!projectStatus ? (
                <span className="status-detail">{selectedTask?.state}</span>
              ) : (
                <Select
                  name="state"
                  register={register}
                  options={TASK_STATUS}
                />
              )}
            </div>

            <div className="view-po-task__breakdown__row">
              <span>Label(s)</span>
              <span className="label-detail">{selectedTask?.label}</span>
            </div>

            <div className="view-po-task__breakdown__row">
              <span>Start Date</span>
              <span>
                {getFormatedDate(selectedTask?.start_date, false, true)}
              </span>
            </div>

            <div className="view-po-task__breakdown__row">
              <span>Expected End Date</span>
              <span>
                {getFormatedDate(selectedTask?.expected_date, false, true)}
              </span>
            </div>

            <div className="view-po-task__breakdown__row">
              <span>Last Updated</span>
              <span>
                {getFormatedDate(selectedTask?.date_updated, false, true)}
              </span>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default ViewPOTaskForm;
