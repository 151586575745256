import DTeamOverview from "components/Main/Metric/DashboardD/DashboardCards/DTeamOverview/DTeamOverview";
import "./AdminVKpiModalCards.scss";

const AdminVKpiModalCards = (props: any) => {
  const { team, lead_details, member_count, title, task_breakdown } = props || {};
  const { name } = team?.lead_details || {};
  const leadName = lead_details?.name;

  return (
    <section className="admin-kpi-modal-cards">
      <DTeamOverview
        {...{
          lead_name: name || leadName,
          selectedFilter: team,
          member_count: member_count,
          title: title,
        }}
      />
    </section>
  );
};

export default AdminVKpiModalCards;
