import React from "react";
import AppViewModal from "./AppViewModal";
import { Box, Typography } from "@mui/material";

const DashboardGuideModal = (props: any) => {
  const { open, handleClose } = props;
  return (
    <AppViewModal open={open} handleClose={handleClose} title="Dashboard Guide">
      <Box sx={{ mt: 3, mx: 3 }}>
        <Typography
          sx={{ color: "#64748B", fontSize: "15px", fontWeight: 400 }}
        >
          Your Dashboard provides a comprehensive overview of key data and
          metrics for your organization:
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#3498db", fontSize: "14px", fontWeight: 700 }}
          >
            Code Repository Metrics:
          </Typography>
          <Typography
            sx={{ color: "#64748B", fontSize: "13px", fontWeight: 400, mt: 1 }}
          >
            This chart visualises the relationship between your organization's
            pull requests, commits, deployments, and the number of lines of
            code, all based on your selected project.
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#3498db", fontSize: "14px", fontWeight: 700 }}
          >
            Task Completed:
          </Typography>
          <Typography
            sx={{ color: "#64748B", fontSize: "13px", fontWeight: 400, mt: 1 }}
          >
            This chart displays the number of completed tasks over a period of
            up to 12 months, focusing on your selected project
          </Typography>
        </Box>

        <Box sx={{ mt: 2 }}>
          <Typography
            sx={{ color: "#3498db", fontSize: "14px", fontWeight: 700 }}
          >
            Infrastructure Cost:
          </Typography>
          <Typography
            sx={{ color: "#64748B", fontSize: "13px", fontWeight: 400, mt: 1 }}
          >
            This chart illustrates the cost of infrastructure over a period of
            up to 12 months, again centered around your selected project.
          </Typography>
        </Box>
      </Box>
    </AppViewModal>
  );
};

export default DashboardGuideModal;
