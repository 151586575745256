// import Select from "components/ui/Select";
import { MetricDashboardPropsTypes } from "../../DashboardD.types";
import "./DesignResolution.scss";

import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// const data = [
//   {
//     name: "Jan",
//     open: 150,
//     closed: 200,
//     merged: 250,
//   },
//   {
//     name: "Feb",
//     open: 30,
//     closed: 100,
//     merged: 150,
//   },
//   {
//     name: "Mar",
//     open: 15,
//     closed: 20,
//     merged: 150,
//   },
//   {
//     name: "Apr",
//     open: 50,
//     closed: 200,
//     merged: 50,
//   },
//   {
//     name: "May",
//     open: 100,
//     closed: 20,
//     merged: 50,
//   },
//   {
//     name: "Jun",
//     open: 150,
//     closed: 200,
//     merged: 250,
//   },
//   {
//     name: "Jul",
//     open: 150,
//     closed: 200,
//     merged: 250,
//   },
//   {
//     name: "Aug",
//     open: 150,
//     closed: 200,
//     merged: 250,
//   },
//   {
//     name: "Sept",
//     open: 150,
//     closed: 200,
//     merged: 250,
//   },
//   {
//     name: "Oct",
//     open: 100,
//     closed: 150,
//     merged: 250,
//   },
//   {
//     name: "Nov",
//     open: 50,
//     closed: 20,
//     merged: 150,
//   },
//   {
//     name: "Dec",
//     open: 80,
//     closed: 100,
//     merged: 50,
//   },
// ];

const DesignResolution = (props: MetricDashboardPropsTypes) => {
  const { effective_chart } = props;
  const chartData = effective_chart?.map?.((item: Array<any>) => {
    return {
      month: item[0],
      value: item[1],
    };
  });

  return (
    <section className="repository-metrics">
      <div className="repository-metrics__header">
        <h3>Effectives Chart</h3>
        {/* <Select
          value="2023"
          options={[
            {
              label: "2023",
              value: "2023",
            },
          ]}
        /> */}
      </div>
      <div className="repository-metrics__chart">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={chartData} width={100}>
            <CartesianGrid strokeDasharray="1 5" />
            <XAxis dataKey="month" />
            <YAxis />
            <Tooltip />
            <Legend
              verticalAlign="top"
              height={30}
              align="left"
              iconSize={14}
            />
            <Bar
              dataKey="value"
              fill="#6373E7"
              background={false}
              barSize={11}
              stackId={"a"}
            />
            {/* <Bar
              dataKey="closed"
              fill="#9BA6FA"
              background={false}
              barSize={11}
              stackId={"a"}
            />
            <Bar
              dataKey="merged"
              fill="#E4E4E4"
              background={false}
              barSize={11}
              stackId={"a"}
            /> */}
          </BarChart>
        </ResponsiveContainer>
      </div>
    </section>
  );
};

export default DesignResolution;
