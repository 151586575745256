import { Button as Btns, Search } from "components/ui";
import "./PageHeader.scss";
import assets from "assets";
import { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import FilterDropdown from "./FilterDropdown/FilterDropdown";
import { useSelector } from "react-redux";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Button, ButtonGroup, Divider, Menu, MenuItem } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import BulkUploadModal from "../Modals/BulkUploadModal";

const PageHeader = (props: {
  subRoutes: Array<{ title: string; route: string; hide?: boolean }>;
  canDownloadTable?: boolean;
  canCreate?: boolean;
  hasSearch?: boolean;
  hasFilter?: boolean;
  hasClockin?: boolean;
  createBtnText?: string;
  hideRoutes?: boolean;
  pageTitle?: string;
  toggleModal: Function;
  updateSearch: Function;
  isModalOpen: boolean;
  filterList?: Array<any>;
  selectFilter?: Function;
  toggleFilter: Function;
  preventSelectFirstItem?: boolean;
  filterName?: string;
  filterOption?: string;
  openModals?: Function;
}) => {
  const {
    subRoutes,
    // canDownloadTable,
    canCreate,
    hasSearch,
    hasFilter,
    hasClockin,
    createBtnText,
    hideRoutes,
    pageTitle,
    toggleModal,
    updateSearch,
    isModalOpen,
    filterList,
    selectFilter,
    toggleFilter,
    preventSelectFirstItem,
    filterName,
    filterOption,
    openModals,
  } = props;

  const [selectedItem, selectItem] = useState<{
    title?: string;
    route?: string;
    canDownloadTable?: boolean;
    canCreate?: boolean;
    hasSearch?: boolean;
    hasClockin?: boolean;
    hasFilter?: boolean;
    createBtnText?: string;
    pageTitle?: string;
    filterName?: string;
    filterOption?: string;
    openModals?: Function;
  }>({});

  const [modalOpen, setModalOpen] = useState(false);
  const [openBulkOpen, setOpenBulkOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);
  const { user } = useSelector((state: any) => state.auth);
  const menuRef = useRef(null);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleOpenBulkModal = () => {
    setOpenBulkOpen(true);
  };

  const handleCloseBulkModal = () => {
    setOpenBulkOpen(false);
  };

  const handleOpenMenu = (event: any) => {
    setMenuOpen(event?.currentTarget);
  };

  const handleOutsideClick = () => {
    if (menuRef.current) {
      setMenuOpen(null);
    }
  };

  const { pathname } = useLocation();

  const path = pathname.substring(1);

  const firstSubPath = path.split("/")[0].toString();

  // --- this for view individual kpi table page ---
  const hasKpiId = path.split("/")[2]?.toString();

  useEffect(() => {
    selectItem(subRoutes?.filter((x) => x.route === `/${path}`)[0]);

    if (hasKpiId) {
      selectItem({
        title: "Individual KPIs",
        route: path,
        canDownloadTable: false,
        canCreate: true,
        hasSearch: true,
        hasFilter: true,
        createBtnText: "Set new KPI",
        pageTitle: "Individal KPI",
        filterOption: "TEAM",
      });
    }
    return () => {
      selectItem({});
    };

    // eslint-disable-next-line
  }, [firstSubPath, hasKpiId]);

  useEffect(() => {
    toggleModal(false);
    updateSearch("");
    return () => {
      toggleModal(false);
      updateSearch("");
    };
    // eslint-disable-next-line
  }, [path]);

  useEffect(() => {
    openModal();
  }, []);
  const filtered_sub_route = subRoutes?.filter(
    (route: any) =>
      route?.title !== "Team Details" &&
      route?.title !== "Clock In" &&
      route?.title !== "Employees" &&
      route?.title !== "Teams" &&
      route?.title !== "KPIs" &&
      route?.title !== "Payments & billing" &&
      route?.title !== "Subscriptions" &&
      route?.title !== "Company settings" &&
      route?.title !== "Integrations" &&
      route?.title !== "Payroll"
  );
  const filtered_sub_route_team = subRoutes?.filter(
    (route: any) =>
      route?.title !== "KPIs" &&
      route?.title !== "Payments & billing" &&
      route?.title !== "Subscriptions" &&
      route?.title !== "Company settings" &&
      route?.title !== "Payroll"
  );

  const all_sub_routes =
    user?.employee?.role === "employee"
      ? filtered_sub_route
      : user?.employee?.role === "team_lead" ||
        user?.employee?.role === "project_manager"
      ? filtered_sub_route_team
      : subRoutes;

  return (
    <section className="page-header">
      <div className="page-header__top">
        <h3 className="page-title">{pageTitle || selectedItem?.pageTitle}</h3>
        <div className="page-header__top__actions">
          {(hideRoutes ? hasClockin : selectedItem?.hasSearch) && (
            <NavLink to={"/admin/clockin"}>
              <Button
                variant="contained"
                sx={{
                  textTransform: "inherit",
                  backgroundColor: "#008ee2",
                  "&:hover": {
                    backgroundColor: "#008ee2",
                  },
                }}
                endIcon={<AccessTimeIcon />}
              >
                Clock-Ins
              </Button>
            </NavLink>
          )}
          {(hideRoutes ? hasSearch : selectedItem?.hasSearch) && (
            <Search
              placeholder="Search"
              onChange={(e: any) => updateSearch(e)}
            />
          )}
          {/* {(hideRoutes ? canDownloadTable : selectedItem?.canDownloadTable) && (
            <Button
              text="Download table"
              className="filter__download"
              icon={assets.icons.download}
            />
          )} */}

          <div className="page-header__top__actions__buttons">
            {(hideRoutes ? hasFilter : selectedItem?.hasFilter) && (
              <FilterDropdown
                filterList={filterList}
                selectFilter={selectFilter}
                toggleFilter={toggleFilter}
                filterName={selectedItem?.filterName || filterName}
                preventSelectFirstItem={preventSelectFirstItem}
                filterOption={selectedItem?.filterOption || filterOption}
              />
            )}
            {(user?.employee?.role === "admin" ||
              user?.employee?.role === "project_manager") && (
              <>
                {(hideRoutes ? canCreate : selectedItem?.createBtnText) && (
                  <>
                    {selectedItem?.createBtnText !== "Add new employee" ? (
                      <Btns
                        text={
                          selectedItem?.createBtnText || createBtnText || ""
                        }
                        className="add__btn"
                        icon={assets.icons.add}
                        onClick={() => toggleModal(!isModalOpen)}
                      />
                    ) : (
                      <ButtonGroup onClick={handleOpenMenu} ref={menuRef}>
                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: "#008EE2",
                            textTransform: "inherit",
                            borderRadius: 2,
                            px: 3,
                            py: 1.4,
                            "&:hover": {
                              backgroundColor: "#008EE2",
                            },
                          }}
                        >
                          {selectedItem?.createBtnText}
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "#008EE2" }}
                        >
                          <KeyboardArrowDownIcon />
                        </Button>
                      </ButtonGroup>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      {/* {subRoutes?.length > 0 && (
        <div className="page-header__bottom">
          {(user?.employee?.role === "employee"
            ? filtered_sub_route
            : user?.employee?.role === "team_lead" ||
              user?.employee?.role === "project_manager"
            ? filtered_sub_route_team
            : subRoutes
          ) */}
      {all_sub_routes?.length > 0 && (
        <div className="page-header__bottom">
          {all_sub_routes
            ?.filter((x) => x?.hide !== true)
            .map(({ title, route, hide, ...rest }, key) => (
              <NavLink
                to={route}
                key={key}
                onClick={() => selectItem({ ...rest })}
              >
                {(user?.employee?.role === "employee" ||
                  user?.employee?.role === "team_lead") &&
                title === "Project Overview"
                  ? "Projects"
                  : title}
              </NavLink>
            ))}
        </div>
      )}

      <Menu
        open={Boolean(menuOpen)}
        anchorEl={menuOpen as any}
        onClose={handleOutsideClick}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          style: {
            width: 228,
            marginTop: -1,
          },
        }}
      >
        {" "}
        <MenuItem
          sx={{
            fontSize: 14,
            fontWeight: 500,
            mx: 2,
            my: 1,
            "&.Mui-focusVisible": {
              background: "none",
            },
          }}
          onClick={() => {
            toggleModal(!isModalOpen);
            handleOutsideClick();
          }}
        >
          Single Employee
        </MenuItem>
        <Divider />
        <MenuItem
          sx={{
            fontSize: 14,
            fontWeight: 500,
            mx: 2,
            my: 1,
            "&.Mui-focusVisible": {
              background: "none",
            },
          }}
          onClick={() => {
            handleOpenBulkModal();
            handleOutsideClick();
          }}
        >
          Bulk Employees
        </MenuItem>
      </Menu>
      <BulkUploadModal isOpen={openBulkOpen} onClose={handleCloseBulkModal} />
    </section>
  );
};

export default PageHeader;
