import { useAppContext } from "AppContext";
import { Table } from "components/ui";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import Loader from "components/ui/Loader";
import { useEffect, useState } from "react";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage, getFormatedDate } from "utils/helper";

const { IDLE, ERROR, NULLMODE, LOADING, DATAMODE } = dataQueryStatuses;

const RisksTable = () => {
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");

  const [dataList, setDataList] = useState([]);

  const { search } = useAppContext();

  const [pagination, setPagination] = useState({
    next: null,
    current: 1,
    previous: null,
  });

  const getTeamMetricsData = (page?: number) => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.integrationRisks,
      headers: {
        // signal: httpsRequest?.signal,
      },
      params: {
        page,
        q: search,
      },
    })
      .then((resp) => {
        const { results } = resp?.data?.data || {};
        results?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
        setDataList(results);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  const tableData = dataList?.map(
    ({
      author,
      title,
      body,
      closed_at,
      merged_at,
      comment,
      merged_by,
      commit,
      date_created,
    }: any) => {
      return {
        author,
        title,
        body,
        merged_by,
        date_created: getFormatedDate(date_created, false, true),
        merged_at: getFormatedDate(merged_at, false, true),
        closed_at: getFormatedDate(closed_at, false, true),
        comment,
        commit,
      };
    }
  );

  const handlePagination = (pageNo: number) => {
    setPagination({
      ...pagination,
      current: pageNo,
    });

    getTeamMetricsData(pageNo);
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView message="No Risks Data Available" />;

      case ERROR:
        return (
          <ErrorView handleRetry={getTeamMetricsData} message={errorMessage} />
        );
      case DATAMODE:
        return (
          <>
            <Table
              head={[
                "PRs Title",
                "Author",
                "Body",
                "Reviewer",
                "Open Date",
                "Merged Date",
                "Close Date",
                "Comment Count",
                "Commit",
              ]}
              {...pagination}
              handlePagination={handlePagination}
              body={tableData}
              showNA
            />
          </>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    let httpsRequest = new AbortController();
    getTeamMetricsData();
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, [search]);

  return <>{renderBasedOnStage()}</>;
};

export default RisksTable;
