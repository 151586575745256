import { useEffect, useState } from "react";
import POIssues from "./POIssues/POIssues";
import POTasks from "./POTasks/POTasks";
import "./ProjectOverview.scss";
import MilestoneTable from "./PODetails/MilestoneTable";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import { getErrorMessage } from "utils/helper";
import API from "utils/api/API";
import Loader from "components/ui/Loader";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import { Search } from "components/ui";
import { MODAL_ACTIONS } from "components/Main/enums";
import { useSelector } from "react-redux";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Fade, Tooltip } from "@mui/material";

const { IDLE, LOADING, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;
const { EDIT_ACTION, DELETE_ACTION, CREATE_OTHER_ACTION } = MODAL_ACTIONS;

const ProjectOverview = (props: {
  selectedProject: any;
  setModalAction: Function;
  toggleModal?: Function;
  setMilestone: Function;
}) => {
  const { selectedProject, setModalAction, toggleModal, setMilestone } = props;
  const { user } = useSelector((state: any) => state.auth);

  const [projectMilestones, setProjectMilestone] = useState([]);
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [search, setSearch] = useState("");

  const getProjectMilestone = () => {
    setStatus(LOADING);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.projectMilestone,
      params: {
        project: selectedProject?.id || undefined,
        q: search,
        page: page,
      },
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const { results, count, per_page } = resp?.data?.data;
        setProjectMilestone(results);
        setCount(count);
        setPerPage(per_page);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return (
          <ErrorView handleRetry={getProjectMilestone} message={errorMessage} />
        );
      case DATAMODE:
        return (
          <>
            <MilestoneTable
              setModalAction={setModalAction}
              toggleModal={toggleModal}
              setMilestone={setMilestone}
              data={projectMilestones}
              count={count}
              page={page}
              setPage={setPage}
              per_page={per_page}
            />
          </>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    if (user?.employee?.role !== "employee") {
      let httpsRequest = new AbortController();
      getProjectMilestone();
      return () => {
        httpsRequest?.abort();
      };
    }
    // eslint-disable-next-line
  }, [user?.employee?.role, selectedProject?.id, page, search]);

  return (
    <div className="project-overview">
      {(user?.employee?.role === "admin" ||
        user?.employee?.role === "project_manager") && (
        <div className="heading">
          <h2>{selectedProject?.project_name}</h2>
          <Box sx={{ display: "flex", alignItems: "center", mt: "-1rem" }}>
            <Tooltip
              title="Update Project"
              placement="top-end"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <Button
                variant="outlined"
                sx={{
                  color: "#008EE2",
                  mr: 2,
                  padding: "0.1rem, 0.5rem",
                }}
                onClick={() => {
                  toggleModal?.(true);
                  setModalAction(EDIT_ACTION);
                }}
                endIcon={
                  <BorderColorIcon
                    sx={{ width: 20, height: 20, padding: "-2rem" }}
                  />
                }
              >
                Edit
              </Button>
            </Tooltip>
            <Tooltip
              title="Delete Project"
              placement="top-start"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <Button
                variant="contained"
                sx={{
                  textTransform: "inherit",
                  padding: "0.5rem 0.5rem",
                  backgroundColor: "#008EE2",
                  "&:hover": {
                    backgroundColor: "#008EE2",
                  },
                }}
                onClick={() => {
                  toggleModal?.(true);
                  setModalAction(DELETE_ACTION);
                }}
                endIcon={<DeleteIcon />}
              >
                Delete
              </Button>
            </Tooltip>
          </Box>
        </div>
      )}
      {/* <POSummary selectedProject={selectedProject} /> */}
      {/* <PODetails
        selectedProject={selectedProject}
      /> */}
      <div className="project_body">
        {(user?.employee?.role === "admin" ||
          user?.employee?.role === "project_manager" ||
          user?.employee?.role === "team_lead") && (
          <div>
            {" "}
            <div className="po-details__header">
              <h3>Project Milestones</h3>
              <div className="po-details__actions">
                <Search
                  placeholder="Search table"
                  value={search}
                  handleSearch={(data: any) => setSearch(data)}
                />
                <Button
                  variant="contained"
                  sx={{
                    textTransform: "inherit",
                    padding: "0.2rem 0.5rem",
                    backgroundColor: "#008EE2",
                    "&:hover": {
                      backgroundColor: "#008EE2",
                    },
                  }}
                  onClick={() => {
                    toggleModal?.(true);
                    setModalAction(CREATE_OTHER_ACTION);
                  }}
                >
                  Create Milestone
                </Button>
              </div>
            </div>
            {renderBasedOnStage()}
          </div>
        )}
        <POTasks selectedProject={selectedProject} />
        <POIssues
          selectedProject={selectedProject}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      </div>
    </div>
  );
};

export default ProjectOverview;
