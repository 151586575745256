import { MODAL_ACTIONS } from "components/Main/enums";
import DeleteTeamModal from "./DeleteTeamModal/DeleteTeamModal";
import CreateTeamModal from "./CreateTeamModal/CreateTeamModal";

const { EDIT_ACTION, CREATE_ACTION, DELETE_ACTION } = MODAL_ACTIONS;

const AdminTeamsModals = (props: {
  modalAction: string;
  onClose: Function;
  handleSuccess?: Function;
  selectedTeam?: any;
  isOpen: boolean;
}) => {
  const { modalAction, onClose, handleSuccess, selectedTeam, isOpen } = props;

  const renderBasedOnAction = () => {
    switch (modalAction) {
      case EDIT_ACTION:
      case CREATE_ACTION:
        return (
          <CreateTeamModal
            onClose={onClose}
            handleSuccess={handleSuccess}
            selectedTeam={selectedTeam}
            isOpen={isOpen}
            isEdit={modalAction === EDIT_ACTION}
          />
        );

      case DELETE_ACTION:
        return (
          <DeleteTeamModal
            onClose={onClose}
            handleSuccess={handleSuccess}
            selectedTeam={selectedTeam}
            isOpen={isOpen}
          />
        );
      default:
        return (
          <CreateTeamModal
            onClose={onClose}
            handleSuccess={handleSuccess}
            selectedTeam={selectedTeam}
            isOpen={isOpen}
            isEdit={modalAction === EDIT_ACTION}
          />
        );
    }
  };

  return <>{renderBasedOnAction()}</>;
};

export default AdminTeamsModals;
