import { useEffect, useState } from "react";

import AuthLayout from "../AuthLayout/AuthLayout";
// import AuthButtonActions from "../common/AuthButtonActions/AuthButtonActions";
import AuthFooter from "../common/AuthFooter/AuthFooter";
import AuthHeader from "../common/AuthHeader/AuthHeader";
import SignupForm from "./SignupForm/SignupForm";
import SignupProfileForm from "./SignupProfileForm/SignupProfileForm";
import { useForm } from "react-hook-form";

import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import toastMessage from "utils/toast";
import { getErrorMessage, isTokenInActive } from "utils/helper";
import { authHeaders, auth_stages } from "./enums";
import { updateAuthInfo } from "store/auth/actions";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  const [stage, setStage] = useState<any>(auth_stages.EMAIL);
  const [file, setFile] = useState("");

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
    getValues,
  } = useForm();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const handleContinue = (data: any) => {
    const { email } = getValues();
    if (stage === auth_stages.EMAIL && email) {
      setStage(auth_stages.PROFILE_INFO);
    } else {
      setError("email", {
        type: "required",
        message: "The email field is required.",
      });
    }
  };

  const signup = (data: any) => {
    setLoading(true);

    const formdata = new FormData();
    formdata.append("company_name", data?.company_name);
    formdata.append("email", data?.email);
    formdata.append("first_name", data?.first_name);
    formdata.append("last_name", data?.last_name);
    formdata.append("password", data?.password);
    formdata.append("picture", file[0]);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.register,
      data: file !== "" ? formdata : data,
    })
      .then((resp) => {
        setLoading(false);
        reset();

        // toastMessage(resp?.data?.message);
        toastMessage('signup successful!');

        const { token, expiry, user } = resp.data.data;
        // setAuthToken(token);
        dispatch<any>(updateAuthInfo({ token, expiry, user }));
        navigate("/auth/verify-email");
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const onSubmit = (data: any) => {
    signup(data);
  };

  const renderBasedOnStage = () => {
    switch (stage) {
      case auth_stages.EMAIL:
        return (
          <>
            <SignupForm
              handleContinue={handleContinue}
              register={register}
              errors={errors}
              getValues={getValues}
            />
            {/* <AuthButtonActions /> */}
            <AuthFooter
              text={"Already have an account?"}
              linkText={"Login"}
              link={"/"}
            />
          </>
        );

      case auth_stages.PROFILE_INFO:
        return (
          <>
            <SignupProfileForm
              register={register}
              errors={errors}
              loading={loading}
              file={file}
              setFile={setFile}
            />
            <AuthFooter
              text={"Already have an account?"}
              linkText={"Login"}
              link={"/"}
            />
          </>
        );

      // case auth_stages.SUCCESS:
      //   return <SignupSuccess />;

      default:
        return "";
    }
  };

  const tokenHasExpired = isTokenInActive();

  useEffect(() => {
    if (!tokenHasExpired) {
      navigate("/dashboard");
    }
    // eslint-disable-next-line
  }, [tokenHasExpired]);

  return (
    <AuthLayout>
      <AuthHeader
        title={authHeaders?.[stage]?.title}
        subTitle={authHeaders?.[stage]?.text}
      />
      <form onSubmit={handleSubmit(onSubmit)}>{renderBasedOnStage()}</form>
    </AuthLayout>
  );
};

export default Signup;
