import { useState } from "react";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const HelpSupportItem = (props: { question: any; answer: any }) => {
  const { question, answer } = props;
  const [open, toggleOpen] = useState(false);
  return (
    <div className="help-support__question">
      <div onClick={() => toggleOpen(!open)}>
        <div className="d-flex">
          <span>{question}</span>
          <KeyboardArrowDownIcon />
        </div>
        {open && <p>{answer}</p>}
      </div>
    </div>
  );
};

export default HelpSupportItem;
