import { Button } from "@mui/material";
import Loader from "components/ui/Loader";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";
import { useEffect, useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import "./BlockerStyles.scss";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const AllBlocker = (props: {
  onClose: Function;
  selectedTask?: any;
  isOpen: boolean;
}) => {
  const { onClose, selectedTask, isOpen } = props;

  const [allBlockers, setAllBlockers] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose?.();
  };

  const getTaskBlockers = async () => {
    setLoading(true);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.taskBlockers,
      params: {
        task: selectedTask?.id,
      },
    })
      .then((resp) => {
        setAllBlockers(resp?.data?.data?.results);
        setLoading(false);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    getTaskBlockers();
  }, []);

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={handleClose}
      title={"Blocker Details"}
      sx={{ width: 500, height: 700 }}
    >
      {loading ? (
        <Loader loaderType="PROGRESS" />
      ) : (
        <div>
          <h2>{selectedTask?.task_name}</h2>
          <div className="blocker_container">
            {allBlockers?.map((blocker, index) => (
              <div key={index} className="blocker">
                <p>Blocker {index + 1}</p>
                <KeyboardArrowRightIcon sx={{ cursor: "pointer" }} />
              </div>
            ))}
          </div>
        </div>
      )}
      <Button
        variant="contained"
        onClick={() => onClose()}
        sx={{ width: "90%", position: "absolute", bottom: "8%" }}
      >
        Ok
      </Button>
    </DefaultModal>
  );
};

export default AllBlocker;
