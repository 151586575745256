import React from 'react';
import { TableHead, TableRow } from '@mui/material';

// core components
import AppTableCell from './AppTableCell';

const TableHeader = (props: any) => {
    const { columns } = props;
    return (
        <TableHead
            sx={{
                borderBottom: '1px solid #B8BCCC',
            }}
        >
            <TableRow>
                {columns
                    ? columns.map((column: any, idx: number) => {
                        return (
                            <AppTableCell
                                align={column.align}
                                sx={{
                                    fontWeight: 700,
                                    color: '#000000',
                                    fontSize: 14,
                                }}
                                key={column.title + 'Head' + idx}
                            >
                                {column.title}
                            </AppTableCell>
                        );
                    })
                    : null}
            </TableRow>
        </TableHead>
    );
};

export default TableHeader;
