export const KPI_STATUSES = [
  {
    label: "Active",
    value: true,
  },
  {
    label: "In active",
    value: false,
  },
];
