import { MetricDashboardPropsTypes } from "../DashboardD.types";
import "./DashboardLastRow.scss";
import DesignResolution from "./DesignResolution/DesignResolution";
import PagesTested from "./PagesTested/PagesTested";
import TasksManaged from "./TasksManaged/TasksManaged";

const DashboardLastRow = (props: MetricDashboardPropsTypes) => {
  return (
    <div className="dashboard-last-row">
      <DesignResolution {...props} />
      <TasksManaged {...props} />
      <PagesTested {...props} />
    </div>
  );
};

export default DashboardLastRow;
