import { ReactSVG } from "react-svg";
import "./AdminViewKpiTeamActivity.scss";
import assets from "assets";
import { useState } from "react";
import ContributionGraph from "components/ui/ContributionGraph/ContributionGraph";

// [
//   {
// month, day, year
//   },{

//   }
// ]
const AdminViewKpiTeamActivity = (props: any) => {
  const { activity } = props;

  const [show, toggleShow] = useState(false);

  return (
    <section className="admin-view-kpi-team-activity">
      <div className="admin-view-kpi-team-activity__header">
        <h3>Team Activity</h3>
        <ReactSVG
          src={show ? assets.icons.arrowDown : assets.icons.arrowUp}
          onClick={() => toggleShow(!show)}
        />
      </div>
      {show && (
        <div className="admin-view-kpi-team-activity__chart">
          <ContributionGraph data={activity} />
        </div>
      )}
    </section>
  );
};

export default AdminViewKpiTeamActivity;
