import { Button, Search, AppTable } from "components/ui";
import "./POTasks.scss";
import { useEffect, useState } from "react";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { copierHelper, getErrorMessage, getFormatedDate } from "utils/helper";
import Loader from "components/ui/Loader";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import { MODAL_ACTIONS } from "components/Main/enums";
import POTasksModals from "./POTasksModals/POTasksModals";
import { Box, Fade, Tooltip, Typography } from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import UpdateStatus from "./POTasksModals/UpdateTaskStatus/DeletePOTask";
import DeleteIcon from "@mui/icons-material/Delete";
import BlockIcon from "@mui/icons-material/Block";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import assets from "assets";

import { useSelector } from "react-redux";

const { LOADING, NULLMODE, DATAMODE, ERROR, IDLE } = dataQueryStatuses;
const {
  CREATE_ACTION,
  DELETE_ACTION,
  EDIT_ACTION,
  CREATE_BLOCKER,
  ALL_BLOCKERS,
  CONNECT_GIT,
} = MODAL_ACTIONS;

const POTasks = (props: { selectedProject: any }) => {
  const { selectedProject } = props;

  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const [dataList, setDataList] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, toggleModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [selectedTask, selectTask] = useState({});
  const [selectedBlocker, selectBlocker] = useState({});
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(0);
  const { user } = useSelector((state: any) => state.auth);

  const getProjectTasks = () => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.projectTasks,
      params: {
        project: selectedProject?.id,
        q: search,
        page: page,
      },
    })
      .then((resp) => {
        const { results, count, per_page } = resp?.data?.data || {};
        results?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
        setDataList(results);
        setCount(count);
        setPerPage(per_page);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };

  const handleModalAction = (actionType: any, task?: any) => {
    setModalAction(actionType);
    toggleModal(true);
    selectTask(task);
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const columns = [
    {
      title: "Task",
      render: (row: any) => (
        <Typography sx={{ fontSize: "12px", textTransform: "capitalize" }}>
          {row?.task_name} {""}
        </Typography>
      ),
    },
    {
      title: "Task ID",
      render: (row: any) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: "12px", textTransform: "capitalize" }}>
            {row?.task_connect_id} {""}
          </Typography>
          <ContentCopyIcon
            onClick={() => copierHelper(row?.task_connect_id)}
            sx={{
              color: "#008EE2",
              width: 15,
              height: 15,
              ml: 1.5,
              cursor: "pointer",
            }}
          />
        </Box>
      ),
    },
    {
      title: "State",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
          }}
        >
          {row?.state || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Label",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {row?.label || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Date Started",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {getFormatedDate(row?.start_date, false, true) || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (row: any) => (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            marginRight: 2,
          }}
        >
          {row?.git_url && (
            <a
              href={row?.git_url}
              target="_blank"
              rel="noopener noreferrer"
              className="git_url"
            >
              <img src={assets.icons.GitIcon} alt="giticon" />
            </a>
          )}
          {user?.employee?.role === "employee" && (
            <Tooltip
              title="Update Task Status"
              placement="top-end"
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
            >
              <BorderColorIcon
                sx={{
                  width: 18,
                  height: 18,
                  marginRight: "1rem",
                }}
                onClick={() => {
                  selectTask(row);
                  setStatusModal(true);
                }}
              />
            </Tooltip>
          )}
          <>
            {user?.employee?.role !== "employee" && (
              <>
                <Tooltip
                  title="Update Task"
                  placement="top-start"
                  TransitionComponent={Fade}
                  TransitionProps={{ timeout: 600 }}
                >
                  <BorderColorIcon
                    sx={{ width: 18, height: 18, marginRight: "1rem" }}
                    onClick={() => {
                      selectTask(row);
                      setModalAction(EDIT_ACTION);
                      toggleModal?.(true);
                    }}
                  />
                </Tooltip>
                <Tooltip title="Delete Task" placement="top-start">
                  <DeleteIcon
                    onClick={() => {
                      setModalAction(DELETE_ACTION);
                      toggleModal?.(true);
                      selectTask(row);
                    }}
                  />
                </Tooltip>
              </>
            )}
            {row?.is_blocked && (
              <Tooltip title="This task is blocked" placement="top-end">
                <BlockIcon
                  sx={{
                    width: 18,
                    height: 18,
                    marginLeft: "1rem",
                    color: "#FF0000",
                  }}
                  onClick={() => {
                    selectTask(row);
                    setModalAction(ALL_BLOCKERS);
                    toggleModal?.(true);
                  }}
                />
              </Tooltip>
            )}
          </>
        </Box>
      ),
    },
  ];
  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return (
          <ErrorView handleRetry={getProjectTasks} message={errorMessage} />
        );
      case DATAMODE:
        return (
          <div className="admin-user-table">
            {dataList?.length > 0 ? (
              <AppTable
                columns={columns}
                data={dataList}
                sorter={(a: any, b: any) => b?.id - a?.id}
                dataLength={count}
                noPerPage={per_page}
                page={page}
                onPageChange={handlePageChange}
              />
            ) : (
              <EmptyView message="No Task Found" />
            )}
          </div>
        );
      default:
        return "";
    }
  };

  useEffect(() => {
    let httpsRequest = new AbortController();
    getProjectTasks();
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, [search, selectedProject, page]);

  const handleSuccess = () => {
    getProjectTasks();
  };
  return (
    <>
      <section className="po-tasks">
        <div className="po-tasks__header">
          <h3>Project Tasks</h3>
          <div>
            <Tooltip
              title="Steps to connect PR(pull request) to task"
              placement="top-start"
            >
              <img
                src={assets.icons.GitBlackIcon}
                alt="giticon"
                className="git_icon"
                onClick={() => {
                  setModalAction(CONNECT_GIT);
                  toggleModal?.(true);
                }}
              />
            </Tooltip>
            {user?.employee?.role !== "admin" && dataList?.length > 0 && (
              <Button
                text="Create blocker"
                className="blocker_button"
                onClick={() => handleModalAction(CREATE_BLOCKER)}
              />
            )}
            <Search
              placeholder="Search table"
              handleSearch={(data: any) => setSearch(data)}
            />
            {user?.employee?.role !== "employee" && (
              <Button
                text="Add new task"
                disabled={selectedProject?.milestones?.length === 0}
                onClick={() => handleModalAction(CREATE_ACTION)}
              />
            )}
          </div>
        </div>
        {renderBasedOnStage()}
      </section>

      <POTasksModals
        {...{
          modalAction,
          handleSuccess,
          selectedProject,
          selectedTask,
          isOpen: showModal,
          dataList,
          setModalAction,
          toggleModal,
          selectedBlocker,
          selectBlocker,
        }}
        onClose={() => toggleModal(false)}
      />
      {statusModal && (
        <UpdateStatus
          handleSuccess={getProjectTasks}
          isOpen={statusModal}
          onClose={() => setStatusModal(false)}
          selectedTask={selectedTask}
          selectedProject={selectedProject}
        />
      )}
    </>
  );
};

export default POTasks;
