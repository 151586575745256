import Input from "components/ui/Input";
import "./IndicatorInput.scss";
import Select from "components/ui/Select";
import { KPI_STATUSES } from "./enums";
import { ReactSVG } from "react-svg";
import assets from "assets";
import { Switch } from "components/ui";

const IndicatorInput = (props: {
  isDate?: boolean;
  index: number;
  kpiData?: any;
  updateKPI?: Function;
  addNewKPI?: Function;
  canChangeStatus?: boolean;
  handleDeleteKPI: Function;
  handleNewKPI?: Function;
  isIndicatorAutomatic?: boolean;
}) => {
  const {
    isDate,
    index,
    kpiData,
    updateKPI,
    addNewKPI,
    canChangeStatus,
    handleDeleteKPI,
    handleNewKPI,
    isIndicatorAutomatic,
    ...rest
  } = props;
  const { indicator, is_met, score, id, status, trackingIndex } = kpiData || {};
  return (
    <div className={`indicator-input ${canChangeStatus ? "use-grid" : ""}`}>
      {canChangeStatus ? (
        <p>{indicator}</p>
      ) : (
        <Input
          placeholder="Label"
          {...rest}
          hideLabel
          hideError
          defaultValue={indicator}
          value={indicator}
          onChange={(value: any) => {
            handleNewKPI?.({ ...kpiData, indicator: value }, trackingIndex);
          }}
          required
        />
      )}
      <div>
        {isIndicatorAutomatic ? (
          <Input
            placeholder="Score"
            {...rest}
            hideLabel
            hideError
            defaultValue={score}
            value={score}
            onChange={(value: number) =>
              updateKPI?.(
                { ...kpiData, score: Number(value) },
                false,
                trackingIndex
              )
            }
            type="number"
            minValue={0}
            required
          />
        ) : (
          <Switch
            className={isIndicatorAutomatic ? "aut_switch" : "man_switch"}
            value={is_met}
            onChange={(value: boolean) => {
              updateKPI?.({ ...kpiData, is_met: value }, false, trackingIndex);
            }}
          />
        )}

        {/* <Checkbox value={is_met}  onChange={(value: boolean) =>
            updateKPI?.(
              { ...kpiData, is_met: value },
              false,
              id === undefined,
              trackingIndex
            )
          }/> */}
      </div>
      {!canChangeStatus && (
        <ReactSVG
          src={assets.icons.thrash}
          onClick={() => handleDeleteKPI(kpiData)}
        />
      )}
      {canChangeStatus && (
          <Select
            options={KPI_STATUSES}
            value={status?.value}
            selectClass={`${status ? "active" : "inactive"}`}
            onChange={(stat: any) => {
              updateKPI?.(
                { ...kpiData, status: stat?.value },
                false,
                trackingIndex
              );
            }}
          />
      )}
    </div>
  );
};

export default IndicatorInput;
