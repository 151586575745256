import Dashboard from "./Dashboard";

const DashboardRoutes = [
  {
    title: "Dashboard",
    component: <Dashboard />,
    route: "/dashboard",
  },
];

export default DashboardRoutes;
