import { ReactSVG } from "react-svg";

import "./AuthLayout.scss";
import assets from "assets";
import { ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const AuthLayout = (props: { children: ReactNode }) => {
  const { children } = props;
  const navigate = useNavigate();

  const { token } = useSelector((state: any) => state.auth);

  const handleLogoClick = () => {
    token ? navigate("/dashboard") : navigate("/");
  };

  return (
    <div className="auth-layout">
      <section className="auth-layout__left">
        <ReactSVG
          className="logo"
          src={assets.images.authLogo}
          onClick={handleLogoClick}
        />
        <div className="auth-layout__left--image">
          <ReactSVG src={assets.images.auth} />
        </div>
      </section>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="main">{children}</div>
      </Box>
    </div>
  );
};

export default AuthLayout;
