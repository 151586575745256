import SideModal from "components/ui/Modal/SideModal/SideModal";
import "./ViewPOTask.scss";
import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";
import { useForm } from "react-hook-form";
import { getErrorMessage } from "utils/helper";
import { useEffect, useState } from "react";
import { apiUrls, httpVerbs } from "utils/api";
import API from "utils/api/API";
import toastMessage from "utils/toast";
import ViewPOTaskForm from "./ViewPOTaskForm/ViewPOTaskForm";

const ViewPOTask = (props: {
  onClose: Function;
  handleSuccess?: Function;
  selectedProject: any;
  isEdit?: boolean;
  selectedTask?: any;
  isOpen: boolean;
}) => {
  const { isOpen, onClose, selectedTask, selectedProject, handleSuccess } =
    props;
  const [editDetails, toggleEditDetails] = useState(false);
  const [editCriteria, toggleEditCriteria] = useState(false);
  const [projectStatus, toggleEditStatus] = useState(false);
  const [editPoints, toggleEditPoints] = useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingMembers, setLoadingMembers] = useState(false);

  const { register, handleSubmit, setValue, reset } = useForm();

  const handleClose = () => {
    reset();
    onClose();
  };

  const updateTask = (data: any) => {
    setLoading(true);
    API({
      method: httpVerbs?.PATCH,
      url: `${apiUrls?.projectTasks}${data?.id}/`,
      data: {
        project: selectedProject?.id,
        ...data,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Task added successfully.");
        handleSuccess?.();
        handleClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const onSubmit = (data: any) => {
    updateTask({ ...selectedTask, ...data });
  };

  useEffect(() => {
    if (selectedTask) {
      Object.keys(selectedTask)?.forEach((x: any) => {
        setValue(x, selectedTask?.[x]);
      });
    }
    // eslint-disable-next-line
  }, [selectedTask]);

  return (
    <SideModal
      isOpen={isOpen}
      onClose={handleClose}
      title={"Onboarding"}
      useCustomHeader
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomModalHeader
          onClose={handleClose}
          title={"Task Details"}
          saveBtnIsLoading={loading || loadingMembers}
          noButton
          noBack
        />

        <ViewPOTaskForm
          {...{
            selectedTask,
            selectedProject,
            toggleEditDetails,
            editDetails,
            toggleEditCriteria,
            editCriteria,
            register,
            loadingMembers,
            toggleEditStatus,
            projectStatus,
            editPoints,
            toggleEditPoints,
            setLoadingMembers,
          }}
        />
      </form>
    </SideModal>
  );
};

export default ViewPOTask;
