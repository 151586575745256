import CompanySettings from "components/Main/Settings/CompanySettings/CompanySettings";

const OnboardingTeamInfo = (props: {
  request: Object;
  handleContinue?: Function;
}) => {
  const { handleContinue, request } = props;
  return (
    <div className="signup-form">
      <CompanySettings
        otherClassName="signup-form__inner"
        btnText="Continue"
        handleContinue={handleContinue}
        request={request}
        hideRole
        hideTitle
      />
    </div>
  );
};

export default OnboardingTeamInfo;
