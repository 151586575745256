import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";
import SideModal from "components/ui/Modal/SideModal/SideModal";
import AdminVKpiModalCards from "./AdminVKpiModalCards/AdminVKpiModalCards";
import AdminVKpiPE from "./AdminVKpiPE/AdminVKpiPE";
import AdminViewKpiBreakdown from "./AdminViewKpiBreakdown/AdminViewKpiBreakdown";
import AdminViewKpiTeamActivity from "./AdminViewKpiTeamActivity/AdminViewKpiTeamActivity";
import AdminViewKpiTable from "./AdminViewKpiTable/AdminViewKpiTable";
import { useEffect, useState } from "react";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage } from "utils/helper";
import Loader from "components/ui/Loader";
import ErrorView from "components/ui/ErrorView";
import EmptyView from "components/ui/EmptyView";

const { LOADING, IDLE, ERROR, NULLMODE, DATAMODE } = dataQueryStatuses;

const AdminViewTeamKPIModal = (props: {
  isOpen: boolean;
  selectedTeam: any;
  onClose: Function;
  modalAction?: String;
}) => {
  const { isOpen, selectedTeam, onClose } = props;
  const [results, setResults] = useState([]);
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");

  const getTeamKpi = () => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: `${apiUrls.teamKpiDashboard}${selectedTeam?.id}/`,
      params: {},
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const { data } = resp.data || {};
        setResults(data);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(ERROR);
        setErrorMessage(errMsg);
      });
  };

  useEffect(() => {
    if (selectedTeam?.id) {
      getTeamKpi();
    }
    // eslint-disable-next-line
  }, [selectedTeam]);

  const renderBasedOnStatus = () => {
    switch (status) {
      case LOADING:
        return <Loader />;
      case ERROR:
        return <ErrorView message={errorMessage} />;
      case NULLMODE:
        return <EmptyView message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            <AdminVKpiModalCards {...results} />
            <AdminVKpiPE {...results} selectedTeam={selectedTeam} />
            <AdminViewKpiBreakdown {...results} />
            {/* <AdminViewKpiTeamActivity {...results} /> */}
            {/* <AdminViewKpiTable {...results} selectedTeam={selectedTeam} /> */}
          </>
        );
      default:
        return "";
    }
  };

  return (
    <>
      <SideModal
        title="Team KPI"
        isOpen={isOpen}
        onClose={onClose}
        useCustomHeader
      >
        <CustomModalHeader onClose={onClose} title="Team KPI" />
        {renderBasedOnStatus()}
      </SideModal>
    </>
  );
};

export default AdminViewTeamKPIModal;
