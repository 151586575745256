import "./DevAnalysis.scss";

const DevAnalysis = (props: {
  title: string;
  data: Array<{
    label: string;
    value?: any;
    color: string;
  }>;
}) => {
  const { title, data } = props;
  return (
    <section className="dev-analysis">
      <div className="dev-analysis__header">
        <h3>{title}</h3>
        {/* <span>See alll</span> */}
      </div>
      {data?.map(({ label, value, color }: any, key) => (
        <div className="dev-analysis__category">
          <span className="category">{label}</span>
          <div className={`${color}`}>{value}</div>
        </div>
      ))}
    </section>
  );
};

export default DevAnalysis;
