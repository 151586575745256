import React from "react";
import Lottie from "react-lottie";

import AIBot from "../../../assets/general/aibot.json";

const options = {
  loop: true,
  autoplay: true,
  animationData: AIBot,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Bot = () => {
  return (
    <div>
      <Lottie options={options} speed={4} width={140} height={80} />
    </div>
  );
};

export default Bot;
