import StatsCardsList from "components/common/StatsCardsList/StatsCardsList";

const stats = [
  {
    title: "Total Issues",
    value: 77,
    report: "Last 30 days",
  },
  {
    title: "Total Commits",
    value: 1123,
    report: "Last 30 Days",
  },
  {
    title: "Task Completed",
    value: 1123,
    report: "Total Issue Resolved",
  },
];

const StatisticsCards = () => {
  return <StatsCardsList stats={stats} />;
};

export default StatisticsCards;
