import React, { useEffect, useState } from "react";
import TwoFactorOptionPage from "./TwoFactionOptionPage";
import ConnectionPage from "./ConnectionPage";
import API from "utils/api/API";
import { apiUrls, httpVerbs } from "utils/api";
import toastMessage from "utils/toast";
import { getErrorMessage } from "utils/helper";
import { useForm } from "react-hook-form";
import { updateAuthInfo } from "store/auth/actions";
import { useDispatch, useSelector } from "react-redux";

const Index = () => {
  const [type, setType] = useState("");
  const [connect, setConnect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setErrors] = useState(false);
  const [values, setValues] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (user?.mfa) {
      setType(user?.mfa_type);
    }
  }, []);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const goBack = () => {
    setConnect(false);
  };

  const SetupMfa = () => {
    if (type === "") {
      setErrors(true);
    } else {
      const body = {
        mfa_type: type,
      };
      setLoading(true);
      API({
        method: httpVerbs?.POST,
        url: apiUrls?.mfaSetup,
        data: body,
      })
        .then((resp) => {
          setValues(resp?.data?.data);
          setConnect(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          const errMsg = getErrorMessage(err);
          toastMessage(errMsg, true);
        });
    }
  };

  const FinalizeMfa = (values: any) => {
    const body = {
      mfa_type: type,
      code: values?.code,
    };
    setLoading(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.mfaCompleteSetup,
      data: body,
    })
      .then((resp) => {
        setConnect(true);
        toastMessage("MFA setup successful.");
        setConnect(false);
        setType("");
        reset({
          code: ''
        })
        setLoading(false);
        const updatedUser = { ...user };

        updatedUser.mfa = true;
        updatedUser.mfa_type = type;
        dispatch<any>(
          updateAuthInfo({
            user: updatedUser,
          })
        );
      })
      .catch((err) => {
        toastMessage(err.response?.data[0]);
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };
  return (
    <div>
      {connect ? (
        <form onSubmit={handleSubmit(FinalizeMfa)}>
          <ConnectionPage
            type={type}
            goBack={goBack}
            values={values}
            register={register}
            errors={errors}
            loading={loading}
          />
        </form>
      ) : (
        <TwoFactorOptionPage
          type={type}
          setType={setType}
          SetupMfa={SetupMfa}
          error={error}
          loading={loading}
        />
      )}
    </div>
  );
};

export default Index;
