import { AppTable } from "components/ui";
import { Typography } from "@mui/material";
import EmptyView from "components/ui/EmptyView";
import { MODAL_ACTIONS } from "components/Main/enums";

const { VIEW_ACTION } = MODAL_ACTIONS;

const AdminKPIsTable = (props: {
  data: any;
  toggleModal: Function;
  selectTeam: Function;
  setModalAction: Function;
  count: any;
  page: number;
  setPage: Function;
  per_page: number;
}) => {
  const {
    data,
    selectTeam,
    toggleModal,
    per_page,
    setPage,
    page,
    count,
    setModalAction,
  } = props;

  const columns = [
    {
      title: "Team",
      width: "200px",
      render: (row: any) => (
        <Typography
          sx={{
            fontSize: "12px",
            textTransform: "capitalize",
            cursor: "pointer",
          }}
          onClick={() => {
            selectTeam(row);
            setModalAction(VIEW_ACTION);
            toggleModal(true);
          }}
        >
          {row?.title}
        </Typography>
      ),
    },
    {
      title: "Lead Name",
      padding: "0 5px",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
            cursor: "pointer",
          }}
          onClick={() => {
            selectTeam(row);
            setModalAction(VIEW_ACTION);
            toggleModal(true);
          }}
        >
          {row?.lead?.name || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Lead Role",
      key: "role",
      render: (row: any) => (
        <Typography
          sx={{
            color: "#5E5E62",
            fontSize: "12px",
            textTransform: "capitalize",
            cursor: "pointer",
          }}
          onClick={() => {
            selectTeam(row);
            setModalAction(VIEW_ACTION);
            toggleModal(true);
          }}
        >
          {row?.lead?.role || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Kpi Score",
      key: "kpi_score",
      render: (row: any) => (
        <Typography
          sx={{ color: "#5E5E62", fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            selectTeam(row);
            setModalAction(VIEW_ACTION);
            toggleModal(true);
          }}
        >
          {row?.kpi_score}
        </Typography>
      ),
    },
    {
      title: "Member Count",
      key: "member_count",
      render: (row: any) => (
        <Typography
          sx={{ color: "#5E5E62", fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            selectTeam(row);
            setModalAction(VIEW_ACTION);
            toggleModal(true);
          }}
        >
          {row?.member_count}
        </Typography>
      ),
    },
    {
      title: "Project Count",
      key: "project_count",
      render: (row: any) => (
        <Typography
          sx={{ color: "#5E5E62", fontSize: "12px", cursor: "pointer" }}
          onClick={() => {
            selectTeam(row);
            setModalAction(VIEW_ACTION);
            toggleModal(true);
          }}
        >
          {row?.project_count}
        </Typography>
      ),
    },
  ];
  const handlePageChange = (page: number) => {
    setPage(page);
  };
  return (
    <div className="admin-user-table">
      {data?.length > 0 ? (
        <AppTable
          columns={columns}
          data={data}
          sorter={(a: any, b: any) => b?.id - a?.id}
          dataLength={count}
          noPerPage={per_page}
          page={page}
          onPageChange={handlePageChange}
        />
      ) : (
        <EmptyView message="No KPI Found" />
      )}
    </div>
  );
};

export default AdminKPIsTable;
