import Select from "components/ui/Select";
import "./AdminCreateKPIForm.scss";
import { useEffect, useState } from "react";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { generateRandomString, getErrorMessage } from "utils/helper";
import toastMessage from "utils/toast";
import Loader from "components/ui/Loader";
import ErrorView from "components/ui/ErrorView";
import EmptyView from "components/ui/EmptyView";
import AdminCKPIFormInputs from "./AdminCKPIFormInputs/AdminCKPIFormInputs";

export const indicators = {
  AUTOMATIC: "AUTOMATIC",
  MANUAL: "MANUAL",
};

const { AUTOMATIC, MANUAL } = indicators;

const { IDLE, ERROR, LOADING, NULLMODE, DATAMODE } = dataQueryStatuses;

const AdminCreateKPIForm = (props: {
  handleSuccess?: Function;
  onClose?: Function;
}) => {
  const { onClose, handleSuccess } = props;

  const [indicator, setIndicator] = useState(indicators?.AUTOMATIC);

  const [loading, setLoading] = useState(false);
  const [selectedTeam, selectTeam] = useState<any>(null);

  const [exitingKPIs, setExistingKPIs] = useState<any>([]);
  const [projects, setProjects] = useState<any>([]);
  const [newKPIs, setNewKPIs] = useState<any>([]);

  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(IDLE);
  const [formInvalid, setFormInvalid] = useState(false);

  const updateKPI = (kpiData: any, isRemoval?: boolean, kpiIndex?: number) => {
    const stateName = "setExistingKPIs";
    setFormInvalid(false);
    if (isRemoval) {
      /* eslint no-eval: 0 */
      return eval(stateName)((prevKPIs: Array<any>) =>
        prevKPIs?.filter(
          ({ id, trackingIndex }, index) =>
            (kpiData?.id ? id !== kpiData?.id : false) ||
            (trackingIndex ? trackingIndex !== kpiIndex : false)
        )
      );
    } else {
      return eval(stateName)((prevKPIs: Array<any>) =>
        prevKPIs?.map(({ id, trackingIndex, ...rest }: any, index: number) => {
          if (
            (kpiData?.id ? id === kpiData?.id : false) ||
            (trackingIndex ? trackingIndex === kpiIndex : false)
          ) {
            return { ...rest, ...kpiData, id, trackingIndex };
          } else {
            return { ...rest, id, trackingIndex };
          }
        })
      );
    }
  };

  const handleNewKPI = (kpiData: any, kpiIndex?: number) => {
    return eval("setNewKPIs")((prevKPIs: Array<any>) => {
      return prevKPIs?.map(
        ({ id, trackingIndex, ...rest }: any, index: number) => {
          if (
            (kpiData?.id ? id === kpiData?.id : false) ||
            (trackingIndex ? trackingIndex === kpiIndex : false)
          ) {
            return { ...rest, ...kpiData, id, trackingIndex };
          } else {
            return { ...rest, id, trackingIndex };
          }
        }
      );
    });
  };

  const addNewKPI = (kpi: any) => {
    const modified = [...newKPIs];
    modified.push({
      indicator: "",
      is_met: false,
      period: exitingKPIs?.[0]?.period,
      team: exitingKPIs?.[0]?.team,
      is_automatic: false,
      status: true,
      project: selectedTeam?.value,
      trackingIndex: generateRandomString(),
    });
    setNewKPIs(modified);
  };
  const removeNewKPI = (kpi: any) => {
    if (newKPIs?.length > 0) {
      setNewKPIs((prevKPIs: Array<any>) =>
        prevKPIs?.filter((x, index) => index !== prevKPIs?.length - 1)
      );
    } else {
      setExistingKPIs((prevKPIs: Array<any>) =>
        prevKPIs?.filter((x) => x?.id !== kpi?.id)
      );
    }
  };

  const getProjects = () => {
    setErrorMessage("");
    setStatus(LOADING);
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.projects,
    })
      .then((resp) => {
        setStatus(DATAMODE);
        const { data } = resp?.data || {};
        if (data?.length === 0) {
          setErrorMessage("You do not have any teams yet");
          setStatus(NULLMODE);
        }
        setProjects(data);
      })
      .catch((err) => {
        setStatus(ERROR);
        setErrorMessage(getErrorMessage(err));
      });
  };

  const retrieveKPIData = () => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: `${apiUrls?.teamKpi}`,
      params: {
        project: selectedTeam?.value,
        all: true,
      },
    })
      .then((resp) => {
        const { results } = resp?.data?.data;
        setStatus(DATAMODE);
        setExistingKPIs(results);
        setNewKPIs([]);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setStatus(
          errMsg?.includes?.("No TeamKPI matches the given query.")
            ? NULLMODE
            : ERROR
        );
        setErrorMessage(errMsg);
      });
  };

  const validateKPIs = () => {
    const invalidKPIS = [...exitingKPIs, ...newKPIs]?.filter(
      ({ indicator }) => indicator?.length === 0
    );
    return invalidKPIS?.length === 0 ? true : false;
  };

  const updateKPIs = () => {
    setLoading(true);
    API({
      method: httpVerbs?.POST,
      url: apiUrls?.teamManualKpi,
      data: {
        updated_kpis: exitingKPIs,
        new_kpis: newKPIs,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("KPIs updated successfully.");
        handleSuccess?.();
        onClose?.();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  const reset = () => {
    setNewKPIs([]);
    setExistingKPIs([]);
  };

  useEffect(() => {
    getProjects();
    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedTeam) {
      retrieveKPIData();
    }
    return () => {
      reset();
    };
    // eslint-disable-next-line
  }, [selectedTeam]);

  const handleSubmit = () => {
    if (validateKPIs()) {
      setFormInvalid(false);
      updateKPIs();
    } else {
      setFormInvalid(true);
    }
  };
  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader />;
      case ERROR:
        return <ErrorView handleRetry={getProjects} message={errorMessage} />;
      case NULLMODE:
        return <EmptyView message={errorMessage} />;
      case DATAMODE:
        return (
          <>
            {formInvalid && (
              <section className="admin-create-kpi-form__error">
                <p>One or more fields are empty</p>
              </section>
            )}
            <AdminCKPIFormInputs
              {...{
                loading,
                reset,
                onClose,
                indicator,
              }}
              updateKPI={updateKPI}
              exitingKPIs={exitingKPIs}
              newKPIs={newKPIs}
              addNewKPI={addNewKPI}
              removeNewKPI={removeNewKPI}
              handleNewKPI={handleNewKPI}
              handleSubmit={handleSubmit}
              selectedTeam={selectedTeam}
            />
          </>
        );
      default:
        return "";
    }
  };
  return (
    <div className="admin-create-kpi-form">
      <section className="admin-create-kpi-form__header">
        <Select
          label="Select Project"
          onChange={(d: any) => selectTeam(d)}
          name="project"
          isDisabled={status === LOADING}
          value={selectedTeam}
          isLoading={status === LOADING}
          options={[...projects?.map(({ project_name, id }: any) => ({
              value: id,
              label: project_name,
            })),
          ]}
        />
      </section>

      <section className="admin-create-kpi-form__select-indicator">
        <span
          className={`${indicator === AUTOMATIC ? "active" : ""}`}
          onClick={() => {
            setIndicator(AUTOMATIC);
          }}
        >
          Automatic Indicator
        </span>
        <span
          className={`${indicator === MANUAL ? "active" : ""}`}
          onClick={() => {
            setIndicator(MANUAL);
          }}
        >
          Manual Indicator
        </span>
      </section>

      {renderBasedOnStage()}
    </div>
  );
};

export default AdminCreateKPIForm;
