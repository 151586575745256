import * as types from "./types";

export const setTeamList = (data: any) => (dispatch: any) =>
  dispatch({ type: types.SET_TEAM_LIST, payload: data });

export const setSelectedTeam = (data: any) => (dispatch: any) => {
  dispatch({ type: types.SET_SELECTED_TEAM, payload: data });
}
export const setTeamSelected = (data: any) => (dispatch: any) => {
  dispatch({ type: types.SET_TEAM_SELECTED, payload: data });
};

export const setSelectedRole = (data: any) => (dispatch: any) =>
  dispatch({ type: types.SET_SELECTED_ROLE, payload: data });
