import "./GitSetup.scss";
import Select from "components/ui/Select";
import { GIT_SETUP_OPTIONS } from "../enums";
import { Button } from "components/ui";
import GithubSettings from "components/Main/Settings/GithubSettings/GithubSettings";
import { useState } from "react";
import GitlabSettings from "components/Main/Settings/GitlabSettings/GitlabSettings";

const { GITHUB, GITLAB } = GIT_SETUP_OPTIONS;

const GitSetup = (props: { handleContinue: Function }) => {
  const { handleContinue } = props;
  const [selectedGit, selectGit] = useState(GITHUB);

  return (
    <div className="signup-form git-setup">
      <Select
        label="Select the version control you use?"
        onChange={(value: any) => selectGit(value)}
        options={[
          { label: "Github", value: GITHUB },
          {
            label: "Gitlab",
            value: GITLAB,
          },
        ]}
      />

      {selectedGit === GITHUB ? (
        <GithubSettings
          redirectUrl="/onboarding?gitInstalled=success"
        />
      ) : (
        <GitlabSettings
          redirectUrl="/onboarding?gitInstalled=success"
        />
      )}

      <Button text="Finish" onClick={handleContinue} />
    </div>
  );
};

export default GitSetup;
