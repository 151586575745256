import { Tooltip, Typography } from "@mui/material";
import { AppTable, Search, Table } from "components/ui";
import EmptyView from "components/ui/EmptyView";
import ErrorView from "components/ui/ErrorView";
import Loader from "components/ui/Loader";
import { useEffect, useState } from "react";
import { apiUrls, dataQueryStatuses, httpVerbs } from "utils/api";
import API from "utils/api/API";
import { getErrorMessage, getFormatedDate } from "utils/helper";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import "../ProjectOverview.scss";
import SideModal from "components/ui/Modal/SideModal/SideModal";
import CustomModalHeader from "components/ui/Modal/SideModal/CustomModalHeader/CustomModalHeader";
import { useForm } from "react-hook-form";
import UpdateIssue from "../Modals/UpdatePoIssues";
import toastMessage from "utils/toast";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";

const { LOADING, NULLMODE, DATAMODE, ERROR, IDLE } = dataQueryStatuses;

const POIssues = (props: {
  selectedProject: any;
  showModal: any;
  setShowModal: any;
}) => {
  const { selectedProject, showModal, setShowModal } = props;
  const [status, setStatus] = useState(IDLE);
  const [errorMessage, setErrorMessage] = useState("");
  const [dataList, setDataList] = useState([]);
  const [search, setSearch] = useState("");
  const [count, setCount] = useState<number>();
  const [page, setPage] = useState(1);
  const [per_page, setPerPage] = useState(0);
  const [selectedIssues, setSelectedIssues] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    watch,
    reset,
  } = useForm();

  const getProjectIssues = async () => {
    setStatus(LOADING);
    setErrorMessage("");
    API({
      method: httpVerbs?.GET,
      url: apiUrls?.projectIssues,

      params: {
        q: search,
        project: selectedProject?.id,
        page: page,
      },

      headers: {
        // signal: httpsRequest?.signal,
      },
    })
      .then((resp) => {
        const { results, count, per_page } = resp?.data?.data || {};
        results?.length > 0 ? setStatus(DATAMODE) : setStatus(NULLMODE);
        setDataList(results);
        setCount(count);
        setPerPage(per_page);
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setErrorMessage(errMsg);
        setStatus(ERROR);
      });
  };
  const columns = [
    {
      title: "Title",
      render: (row: any) => (
        <Typography sx={{ fontSize: "12px", textTransform: "capitalize" }}>
          {row?.title} {""}
        </Typography>
      ),
    },
    {
      title: "Status",
      render: (row: any) => (
        <Typography sx={{ fontSize: "12px", textTransform: "capitalize" }}>
          {row?.state} {""}
        </Typography>
      ),
    },
    {
      title: "Date Created",
      key: "created_at",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {getFormatedDate(row?.created_at, false, true) || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Expected Date",
      key: "expected_date",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {getFormatedDate(row?.expected_date, false, true) || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Date Closed",
      key: "closed_at",
      render: (row: any) => (
        <Typography sx={{ color: "#5E5E62", fontSize: "12px" }}>
          {getFormatedDate(row?.closed_at, false, true) || "N/A"}
        </Typography>
      ),
    },
    {
      title: "Action",
      align: "center",
      render: (row: any) => (
        <Tooltip title='Update ticket issue' placement='top-start'>
        <BorderColorIcon
          sx={{ width: 18, height: 18, marginRight: "1rem", cursor: "pointer" }}
          onClick={() => {
            setSelectedIssues(row);
            setShowModal(true);
          }}
        />
        </Tooltip>
      ),
    },
  ];

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const renderBasedOnStage = () => {
    switch (status) {
      case IDLE:
      case LOADING:
        return <Loader loaderType="PROGRESS" />;
      case NULLMODE:
        return <EmptyView />;
      case ERROR:
        return (
          <ErrorView handleRetry={getProjectIssues} message={errorMessage} />
        );
      case DATAMODE:
        return (
          <>
            <AppTable
              columns={columns}
              data={dataList}
              sorter={(a: any, b: any) => b?.id - a?.id}
              dataLength={count}
              noPerPage={per_page}
              page={page}
              onPageChange={handlePageChange}
            />
          </>
        );
      default:
        return "";
    }
  };

  const onSubmit = (data: any) => {
    setLoading(true);
    API({
      method: httpVerbs?.PATCH,
      url: apiUrls?.updateProjectIssue(selectedIssues?.id),
      data: {
        ...data,
      },
    })
      .then((resp) => {
        setLoading(false);
        toastMessage("Ticket successfully updated.");
        getProjectIssues();
        handleClose();
      })
      .catch((err) => {
        const errMsg = getErrorMessage(err);
        setLoading(false);
        toastMessage(errMsg, true);
      });
  };

  useEffect(() => {
    let httpsRequest = new AbortController();
    getProjectIssues();
    return () => {
      httpsRequest?.abort();
    };
    // eslint-disable-next-line
  }, [search, selectedProject, page]);

  useEffect(() => {
    if (selectedIssues) {
      const { title, assignee, state, expected_date } = selectedIssues || {};
      setValue("title", title);
      setValue("assignee", assignee);
      setValue("state", state);
      setValue("expected_date", expected_date);
    }
    // eslint-disable-next-line
  }, [selectedIssues]);

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <section className="po-tasks">
        <div className="po-tasks__header">
          <h3>Issue Tickets: {dataList?.length}</h3>
          <div>
            <Search
              placeholder="Search table"
              handleSearch={(data: any) => setSearch(data)}
            />
          </div>
        </div>
        {renderBasedOnStage()}

        <DefaultModal
          isOpen={showModal}
          onClose={handleClose}
          title={"Update Ticket Issue"}
          // useCustomHeader
          sx={{ width: 500 }}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <CustomModalHeader
              onClose={handleClose}
              title={"Update Ticket Issue"}
              saveBtnIsLoading={loading}
            /> */}
            <UpdateIssue
              register={register}
              errors={errors}
              selectedIssues={selectedIssues}
              loading={loading}
            />
          </form>
        </DefaultModal>
      </section>
    </>
  );
};

export default POIssues;
