import { useState } from "react";
import "./privacy.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import DefaultModal from "components/ui/Modal/DefaultModal/DefaultModal";

const PrivacyPolicy = (props: { onClose: Function; isOpen: boolean }) => {
  const [expanded, setExpanded] = useState(true);
  const [expanded1, setExpanded1] = useState(true);
  const [expanded2, setExpanded2] = useState(true);
  const [expanded3, setExpanded3] = useState(true);
  const [expanded4, setExpanded4] = useState(true);
  const [expanded5, setExpanded5] = useState(true);
  const { onClose, isOpen } = props;
  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      // useCustomHeader
      title={"Policy Notice"}
      hideButton={true}
      sx={{ width: 1000 }}
    >
      <div className="privacy-container">
        <div className="privacy-header">
          {/* <CloseIcon
            sx={{ position: "absolute", right: "12%", width: 40, height: 40 }}
          /> */}
          <h4> Privacy Policy</h4>
          <p>
            Sprintstar is committed to protecting your privacy. This Privacy
            Policy explains how we collect, use, and safeguard your personal
            information when you visit our website{" "}
            <a href="www.sprintstar.io">www.sprintstar.io</a>
          </p>{" "}
        </div>
        <div className="privacy-body">
          <div className="privacy-item">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "0.4px solid black",
              }}
            >
              <h4>1. Information We Collect</h4>
              {expanded ? (
                <ExpandLessIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded(!expanded)}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded(!expanded)}
                />
              )}
            </Box>
            {expanded && (
              <ul>
                <li>
                  <strong>Personal Information</strong>: When you visit our
                  Website, we may collect certain personally identifiable
                  information, such as your name, email address, and phone
                  number(where necessary). We collect this information only if
                  you voluntarily submit it to us through contact forms,
                  registration forms, or other interactive features on our
                  Website.
                </li>
                <li>
                  <strong>Usage Data</strong>: We may also collect information
                  about how you access and use the website. This usage data may
                  include your IP address, browser type, operating system,
                  referral sources, page views, and other analytics data. We use
                  this information to analyze trends, administer the website,
                  track users' movements around the site, and gather demographic
                  information about our user base.
                </li>
              </ul>
            )}
          </div>
          <div className="privacy-item">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "0.4px solid black",
              }}
            >
              <Box>
                <h4>2. Use of Information</h4>

                <p>
                  We use the information we collect for various purposes,
                  including:
                </p>
              </Box>
              {expanded1 ? (
                <ExpandLessIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded1(!expanded1)}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded1(!expanded1)}
                />
              )}
            </Box>
            {expanded1 && (
              <ul>
                <li>To provide, maintain, and improve the website;</li>
                <li>
                  To communicate with you about our products, services, and
                  promotions;
                </li>
                <li>
                  To respond to your inquiries and provide customer support;
                </li>
                <li>To personalize your experience on the website;</li>
                <li>
                  To detect, prevent, and address technical issues and security
                  vulnerabilities.
                </li>
              </ul>
            )}
          </div>
          <div className="privacy-item">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "0.4px solid black",
              }}
            >
              <h4>3. Data Security</h4>
              {expanded2 ? (
                <ExpandLessIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded2(!expanded2)}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded2(!expanded2)}
                />
              )}
            </Box>
            {expanded2 && (
              <p>
                We are committed to protecting the security of your personal
                information. We use industry-standard security technologies and
                procedures to safeguard your data from unauthorized access, use,
                or disclosure.
              </p>
            )}
          </div>
          <div className="privacy-item">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "0.4px solid black",
              }}
            >
              <h4>4. Third-party links</h4>
              {expanded3 ? (
                <ExpandLessIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded3(!expanded3)}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded3(!expanded3)}
                />
              )}
            </Box>
            {expanded3 && (
              <p>
                Our website may contain links to third-party websites or
                services that are not owned or controlled by Sprintstar. We are
                not responsible for the privacy practices or content of these
                third-party sites. We encourage you to review the privacy
                policies of any third-party sites you visit.
              </p>
            )}
          </div>
          <div className="privacy-item">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "0.4px solid black",
              }}
            >
              <h4>5. Changes to the privacy policy</h4>
              {expanded4 ? (
                <ExpandLessIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded4(!expanded4)}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded4(!expanded4)}
                />
              )}
            </Box>
            {expanded4 && (
              <p>
                We may update this Privacy Policy from time to time to reflect
                changes in our practices or legal requirements. We will notify
                you of any material changes to this Policy by posting the
                updated Privacy Policy on the website. Your continued use of the
                website after such changes constitutes your acceptance of the
                revised Policy.
              </p>
            )}
          </div>
          <div className="privacy-item">
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: "0.4px solid black",
              }}
            >
              <h4>Contact Us</h4>
              {expanded4 ? (
                <ExpandLessIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded5(!expanded5)}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => setExpanded5(!expanded5)}
                />
              )}
            </Box>
            {expanded5 && (
              <p>
                If you have any questions or concerns about this Privacy Policy,
                please contact us at info@sprintstar.io.
              </p>
            )}
          </div>
        </div>
      </div>
    </DefaultModal>
  );
};

export default PrivacyPolicy;
