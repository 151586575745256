// import { ReactSVG } from "react-svg";
import "./StatsCard.scss";
// import assets from "assets";

const StatsCard = (props: {
  title: String;
  value: number;
  report: String;
  color?: string;
  background?: string;
}) => {
  const { title, value, report, color, background } = props;
  return (
    <div className="stats-card">
      <div>
        <div className="stats-card__top">
          <h3>{title}</h3>
          {/* <ReactSVG src={assets.icons.menu} /> */}
        </div>
        <div className="stats-card__bottom">
          {title === "Total Salaries" ||
          title === "Total Average Wages" ||
          title === "Total Team Cost" ||
          title === "Total Projects Cost" ? (
            <p>&#8358;</p>
          ) : (
            ""
          )}
          <b>{value}</b>
          {/* <span style={{ background, color }}>
            <ReactSVG src={assets.icons.graph} />
            {report}
          </span> */}
        </div>
      </div>
    </div>
  );
};

export default StatsCard;
